body {
    padding-bottom: 0px;
    background: #fff !important;
}

.menu .main-menu {
    width: 290px !important;
    height: 100%;
    border: 0px;
    border-radius: 0px !important;
}

.navbar {
    width: 100% !important;
    /* background-color: #FBFCFD; */
    background-color: #6766ff !important;
}

.navbar-dark .navbar-nav .nav-link {
    color: #fff !important;
    margin: 0 5px;
    padding-left: 20px;
    padding-right: 20px;
}

.navbar-dark .navbar-nav .nav-link.active {
    background-color: #7d7cff !important;
    border-radius: 50px;
}

main {
    /* margin-left: 290px !important; */
    margin-top: 20px !important;
    margin-bottom: 0px !important;
}

.chat-footer {
    margin-left: 290px !important;
}

#app-container.main-hidden .main-menu,
#app-container.menu-hidden .main-menu {
    transform: translateX(-290px);
}

#app-container.main-hidden footer.page-footer,
#app-container.main-hidden .navbar {
    width: 100% !important;
    margin-left: 0px !important;
}

.side-menu-shadow {
    box-shadow: 0px 8px 12px #00000029;
}

.menu .main-menu ul li {
    margin-bottom: 20px;
    margin-left: 20px;
}

.menu .main-menu ul li.active {
    background: #3182CECC;
    border-radius: 40px 0px 0px 40px;
}

.menu .main-menu ul li.active:after {
    content: " ";
    background: transparent;
    color: transparent;
    border-radius: 0;
    position: relative;
    width: 0;
    height: 0;
    top: 0;
    transform: translateY(0);
    left: 0;
}

.menu .main-menu ul li.active a span {
    letter-spacing: 0px;
    color: #FFFFFF;
    text-shadow: 0px 2px 4px #00000029;
    font-family: 'SegoeUISemiBold';
    font-size: calc(24px + (32 - 24) * ((100vw - 1024px) / (1920 - 1024)));
}

.menu .main-menu ul li.active a i,
.menu .main-menu ul li.active a svg {
    color: #FFFFFF;
    text-shadow: 0px 2px 4px #00000029;
}

.menu .main-menu ul li:first-child a img {
    height: 50px;
}

.menu .main-menu ul li a span {
    letter-spacing: 0px;
    color: #7F7F7F;
    text-shadow: 0px 2px 4px #00000029;
    font-family: 'SegoeUISemiBold';
    font-size: calc(24px + (32 - 24) * ((100vw - 1024px) / (1920 - 1024)));
}

.menu .main-menu ul li a i,
.menu .main-menu ul li a svg {
    letter-spacing: 0px;
    color: #7F7F7F;
    text-shadow: 0px 2px 4px #00000029;
}

.menu .main-menu ul li a {
    border-bottom: 0px;
    height: 80px;
}

.dashboard-card-shadow {
    box-shadow: 4px 8px 12px #00000029;
}

.green-card {
    background-color: #00A86BB2 !important;
}

.yellow-card {
    background-color: #F2BB65CC !important;
}

.blue-card {
    background-color: #519AA6 !important;
}

.news-card {
    background-color: #F0E6E4 !important;
}

.card-progress .progress {
    height: 20px;
    box-shadow: 2px 2px 8px #00000029;
    border-radius: 12px;
    background-color: #CFCFCF;
}

.card-progress .progress .progress-bar {
    background-color: #FFFFFF;
    border-radius: 12px;
}

.user-img .mini-user-img {
    height: 40px;
    width: 40px;
    border-radius: 100%;
}

.stats-input-border {
    border-bottom: 3px solid #E2E2E2 !important;
}

.preferences-input-border {
    border-bottom: 4px solid #B2B2B2 !important;
}

.preferences-textarea-border {
    border: 4px solid #B2B2B2 !important;
}

.preferences-input-border-radius {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.support-textarea-border {
    border: 3px solid #B2B2B2 !important;
    border-radius: 20px;
}

canvas {
    width: 100% !important;
    height: auto !important;
}

/* profile css */

.min-full-height {
    height: calc(100vh - 200px);
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}

.profile-nav {
    border-right: 6px solid #F0E6E4;
}

.profile-nav .nav-link {
    font-size: calc(24px + (32 - 24) * ((100vw - 1024px) / (1920 - 1024)));
    font-family: 'SegoeUIBold';
    background: transparent;
    color: #B2B2B2;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-left: 6px solid transparent;
    border-radius: 0px;
}

.profile-nav .nav-link.active {
    color: #3182CE;
    font-size: calc(24px + (32 - 24) * ((100vw - 1024px) / (1920 - 1024)));
    font-family: 'SegoeUIBold';
    background: transparent;
    border: 0px;
    border-left: 6px solid;
    border-radius: 0px;
}


.profile-img img.profile-img {
    height: 160px;
    width: 160px;
    border-radius: 100%;
}

/* custom checkbox */

.styled-checkbox {
    position: absolute;
    opacity: 0;
}

.styled-checkbox+label {
    position: relative;
    cursor: pointer;
    padding: 0;
    display: inline-block !important
}

.styled-checkbox+label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #707070;
    border-radius: 4px;
    /* padding: 10px; */
    height: 24px;
    width: 24px;
    display: inline-block;
    position: relative;
    vertical-align: text-top;
    cursor: pointer;
    margin-right: 5px;
}

.styled-checkbox:hover+label:before {
    border-color: #3182CE;
}

.styled-checkbox:focus+label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.styled-checkbox:checked+label:before {
    background: #3182CE;
    border-color: #3182CE !important;
}

.styled-checkbox:disabled+label {
    color: #b8b8b8;
    cursor: auto;
}

.styled-checkbox:disabled+label:before {
    box-shadow: none;
    background: #ddd;
}

.styled-checkbox:checked+label:after {
    font-family: "Font Awesome 5 Free";
    content: "\f00c";
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    font-weight: 900;
    position: absolute;
    top: 6px;
    left: 3px;
    color: #ffffff;
    font-size: calc(16px + (22 - 16) * ((100vw - 1024px) / (1920 - 1024)));
}

/* sample custom checkbox */
.sample_custom_checkbox .styled-checkbox:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

/* modal custom checkbox */
.modal_custom_checkbox .styled-checkbox:checked+label:after {
    font-family: "Font Awesome 5 Free";
    content: "\f00c";
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    font-weight: 900;
    position: absolute;
    top: 0px;
    left: 1px;
    color: #ffffff;
    font-size: calc(20px + (28 - 20) * ((100vw - 1024px) / (1920 - 1024)));
}

textarea#remarks {
    resize: none;
}

.product-table-border {
    border-bottom: 4px solid #f5f5f5;
}

.inquiry-kanban-view-type a.nav-link span,
.active-inquiry-completed a.nav-link span {
    color: #7F7F7F !important;
}

.inquiry-kanban-view-type a.nav-link,
.active-inquiry-completed a.nav-link {
    border-bottom: 5px solid transparent !important;
    border-radius: 0px !important;
}

.inquiry-kanban-view-type a.nav-link.active span,
.active-inquiry-completed a.nav-link.active span {
    color: #3182CE !important;
}

.inquiry-kanban-view-type a.nav-link.active,
.active-inquiry-completed a.nav-link.active {
    border-bottom: 5px solid #3182CE !important;
    border-radius: 0px !important;
}

.inquiry-kanban-input {
    background-color: #F5F5F5 !important;
    border-radius: 40px;
    color: #7F7F7F !important;
    font-size: calc(18px + (24 - 18) * ((100vw - 1024px) / (1920 - 1024)));
    padding: .5rem 1rem .5rem 3rem !important;
    width: 100%;
}

.inquiry-kanban-input::placeholder {
    color: #7F7F7F !important;
    font-size: calc(18px + (24 - 18) * ((100vw - 1024px) / (1920 - 1024)));
}

/* table css */

#active-inquiries {
    border-collapse: collapse !important;
}

#active-inquiries thead th {
    border: 0 !important;
    border-color: #7F7F7F;
    color: #7F7F7F;
    font-size: calc(18px + (24 - 18) * ((100vw - 1024px) / (1920 - 1024)));
    font-family: 'SegoeUISemiBold';
}

#active-inquiries tbody td {
    border: 0 !important;
    border-color: #7F7F7F;
    color: #7F7F7F;
    font-size: calc(18px + (24 - 18) * ((100vw - 1024px) / (1920 - 1024)));
}

.active-inquiries-table .table-search .dataTables_filter {
    text-align: left;
    width: 100%;
}

.active-inquiries-table .table-search .dataTables_filter label {
    margin: 0 !important;
    width: 100%;
}

.active-inquiries-table .table-search .dataTables_filter input {
    background-color: #F5F5F5 !important;
    border-radius: 40px;
    color: #7F7F7F !important;
    font-size: calc(18px + (24 - 18) * ((100vw - 1024px) / (1920 - 1024)));
    padding: .5rem 1rem !important;
    width: 100%;
}

.active-inquiries-table .table-information .dataTables_info {
    color: #7F7F7F;
    font-size: calc(18px + (24 - 18) * ((100vw - 1024px) / (1920 - 1024)));
    text-shadow: 0px 3px 6px #00000029;
    font-family: 'SegoeUISemiBold';
    padding-top: 0px !important;
}

.active-inquiries-table .table-pagination .dataTables_paginate,
.active-inquiries-table .table-pagination .dataTables_paginate .pagination {
    margin: 0 !important;
}

.active-inquiries-table .table-pagination .dataTables_paginate .pagination li {
    padding: 0 !important;
    margin: 0 !important;
}

.active-inquiries-table .table-pagination .dataTables_paginate ul li a {
    color: #7F7F7F;
    font-size: calc(18px + (24 - 18) * ((100vw - 1024px) / (1920 - 1024)));
    text-shadow: 0px 3px 6px #00000029;
    font-family: 'SegoeUISemiBold';
    border: 0;
    border-radius: 0;
    padding: 0;
}

.active-inquiries-table .table-pagination .dataTables_paginate ul li.active a {
    color: #3182CE !important;
    font-size: calc(18px + (24 - 18) * ((100vw - 1024px) / (1920 - 1024)));
    text-shadow: 0px 3px 6px #00000029;
    font-family: 'SegoeUISemiBold';
    border: 0;
    border-radius: 0;
    padding: 0;
}

/* custom radio button */
.custom-radio-divs input[type="checkbox"],
.custom-checkbox-divs input[type="checkbox"] {
    opacity: 0;
    position: fixed;
    width: 0;
}

.custom-radio-divs label {
    display: inline-block;
    background-color: #FAFAFA;
    border-radius: 8px;
    color: #7F7F7F;
    margin-bottom: 0;
    cursor: pointer;
}

.custom-checkbox-divs label {
    display: table;
    background-color: #B2B2B2;
    border-radius: 8px;
    color: #ffffff;
    margin-bottom: 0;
    cursor: pointer;
}

.custom-radio-divs label:hover,
.custom-checkbox-divs label:hover {
    background-color: #3182CE;
    color: #FFFFFF;
}

.custom-radio-divs input[type="checkbox"]:checked+label,
.custom-checkbox-divs input[type="checkbox"]:checked+label {
    background-color: #3182CE;
    color: #FFFFFF;
    cursor: default;
}

.history-nav #v-pills-tab {
    border-right: 1px solid #707070;
}

.history-nav .nav-link.active {
    background: #E7E7E7;
    border: 0;
    border-radius: 0;
}

.history-nav .nav-link {
    border-bottom: 1px solid #707070;
    border-radius: 0;
}

.history-options .active-option {
    background-color: #3182CE33;
    text-decoration: none;
}

.history-options .inactive-option {
    background-color: #B2B2B233;
    text-decoration: line-through;
}

.active-inquiry-slick .slick-slide>div,
.awaiting-inquiry-slick .slick-slide>div,
.rejected-inquiry-slick .slick-slide>div {
    height: 100%;
}

.active-inquiry-slick .slick-dots li.slick-active button,
.awaiting-inquiry-slick .slick-dots li.slick-active button,
.rejected-inquiry-slick .slick-dots li.slick-active button {
    background-color: transparent;
}

.active-inquiry-slick .slick-dots li.slick-active button:before,
.awaiting-inquiry-slick .slick-dots li.slick-active button:before,
.rejected-inquiry-slick .slick-dots li.slick-active button:before {
    border: 1px solid #00A86B;
    background-color: #00A86B;
    color: transparent;
    opacity: 1;
}

.active-inquiry-slick .slick-dots li button:before,
.awaiting-inquiry-slick .slick-dots li button:before,
.rejected-inquiry-slick .slick-dots li button:before {
    border: 1px solid #00A86B;
    border-radius: 50px;
    color: transparent;
    height: 15px;
    width: 15px;
    opacity: 1;
}

.table-heading-line {
    height: 12px;
    background-color: #F5F5F5;
    border-color: #F5F5F5;
}

.tracking-input-border {
    border-bottom: 4px solid #3182CE !important;
}

.chat-input-border-color {
    border: 4px solid #3182CE !important;
}

.traking-input input::placeholder {
    color: #3182CE;
    font-size: calc(20px + (28 - 20) * ((100vw - 1024px) / (1920 - 1024)));
}

.awaiting-internal-tabs ul.nav li.nav-item a.nav-link span.badge {
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.awaiting-internal-tabs ul.nav li.nav-item a.nav-link span.badge {
    background-color: #D1D1D1;
}

.awaiting-internal-tabs ul.nav li.nav-item a.nav-link.active span.badge {
    background-color: #3182CE;
}

.awaiting-internal-tabs ul.nav li.nav-item a.nav-link h2 {
    color: #D1D1D1;
}

.awaiting-internal-tabs ul.nav li.nav-item a.nav-link.active h2 {
    color: #3182CE;
    text-shadow: 0px 2px 8px #00000029;
}

.awaiting-internal-tabs ul.nav li.nav-item a.nav-link.active {
    background-color: transparent;
}

.awaiting-internal-tabs ul.nav li.nav-item a.nav-link.active h2 {
    border-bottom: 5px solid #3182CE;
}

.awaiting-internal-tabs ul.nav li.nav-item a.nav-link h2 {
    border-bottom: 5px solid transparent;
}

.awaiting-internal-tabs .seller-styled-checkbox .styled-checkbox+label:before {
    border: 2px solid #b2b2b2;
}

.awaiting-internal-tabs .seller-styled-checkbox .styled-checkbox+label:hover:before {
    border: 2px solid #3182CE;
}

.awaiting-internal-tabs .seller-styled-checkbox .styled-checkbox+label:hover {
    color: #3182CE !important;
}

.awaiting-internal-tabs .seller-styled-checkbox .styled-checkbox:checked+label {
    color: #3182CE !important;
}

/* chat bubbles */
.bubble {
    box-sizing: border-box;
    float: left;
    width: auto;
    max-width: 540px;
    position: relative;
    clear: both;
    background: #F1F1F1;
    border: solid 1px #F1F1F1;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    box-shadow: 0px 4px 4px #00000029;
    margin-bottom: 20px;
    padding: 15px 20px;
    word-wrap: break-word;
}

.bubble:before,
.bubble:after {
    border-radius: 20px / 5px;
    content: '';
    display: block;
    position: absolute;
}

.bubble:before {
    border: 10px solid transparent;
    border-bottom-color: #F1F1F1;
    bottom: 0px;
    left: -7px;
    z-index: -2;
}

.bubble:after {
    border: 8px solid transparent;
    border-bottom-color: #F1F1F1;
    /* arrow color */
    bottom: 5px;
    left: -8px;
}

.bubble-alt {
    float: right;
}

.bubble-alt:before {
    left: auto;
    right: -7px;
}

.bubble-alt:after {
    left: auto;
    right: -5px;
}

.send-input-group input {
    border-left: 0px !important;
    border-right: 0px !important;
    border-top-right-radius: 40px !important;
    border-bottom-right-radius: 40px !important;
}

.send-input-group select {
    border-right: 0px !important;
    border-top-left-radius: 40px !important;
    border-bottom-left-radius: 40px !important;
}

.send-input-group input.chat-comment-border-left {
    border-left: 4px solid #3182CE !important;
}

.slick-dots {
    position: initial !important;
}

.dropdown-shadow {
    box-shadow: 0px 0px 6px #00000029 !important;
}

.modal-header button.close {
    color: #adb5bd !important;
    opacity: 1 !important;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
}

.modal-header h5 {
    font-weight: bold !important;
    font-size: calc(20px + (28 - 20) * ((100vw - 1024px) / (1920 - 1024))) !important;
}

.modal .modal-header,
.modal .modal-body,
.modal .modal-footer {
    padding: 1rem;
}

.modal-content {
    border-radius: 10px !important;
}

.w-200px {
    max-width: 100px;
    object-fit: contain;
}

.users_table .contacts_tab .active_tab {
    background-color: #efefff !important;
}

.users_table .contacts_tab .inactive_tab {
    background-color: #fff !important;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}