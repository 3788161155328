/* colums for xxl screens */

.col-xxl-1 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xxl-2 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xxl-3 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xxl-4 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xxl-5 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xxl-6 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xxl-7 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xxl-8 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xxl-9 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xxl-10 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xxl-11 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xxl-12 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 1600px) {
    .col-xxl-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .col-xxl-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .col-xxl-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-xxl-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .col-xxl-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .col-xxl-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-xxl-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .col-xxl-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .col-xxl-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-xxl-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .col-xxl-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .col-xxl-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-cols-xxl-1>* {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-cols-xxl-2>* {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .row-cols-xxl-3>* {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .row-cols-xxl-4>* {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .row-cols-xxl-5>* {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }

    .row-cols-xxl-6>* {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    /* text-align */

    .text-xxl-left {
        text-align: left !important;
    }

    .text-xxl-right {
        text-align: right !important;
    }

    .text-xxl-center {
        text-align: center !important;
    }


    /* margin */

    .m-xxl-0 {
        margin: 0 !important;
    }

    .mt-xxl-0,
    .my-xxl-0 {
        margin-top: 0 !important;
    }

    .mr-xxl-0,
    .mx-xxl-0 {
        margin-right: 0 !important;
    }

    .mb-xxl-0,
    .my-xxl-0 {
        margin-bottom: 0 !important;
    }

    .ml-xxl-0,
    .mx-xxl-0 {
        margin-left: 0 !important;
    }

    .m-xxl-1 {
        margin: 0.25rem !important;
    }

    .mt-xxl-1,
    .my-xxl-1 {
        margin-top: 0.25rem !important;
    }

    .mr-xxl-1,
    .mx-xxl-1 {
        margin-right: 0.25rem !important;
    }

    .mb-xxl-1,
    .my-xxl-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-xxl-1,
    .mx-xxl-1 {
        margin-left: 0.25rem !important;
    }

    .m-xxl-2 {
        margin: 0.5rem !important;
    }

    .mt-xxl-2,
    .my-xxl-2 {
        margin-top: 0.5rem !important;
    }

    .mr-xxl-2,
    .mx-xxl-2 {
        margin-right: 0.5rem !important;
    }

    .mb-xxl-2,
    .my-xxl-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-xxl-2,
    .mx-xxl-2 {
        margin-left: 0.5rem !important;
    }

    .m-xxl-3 {
        margin: 1rem !important;
    }

    .mt-xxl-3,
    .my-xxl-3 {
        margin-top: 1rem !important;
    }

    .mr-xxl-3,
    .mx-xxl-3 {
        margin-right: 1rem !important;
    }

    .mb-xxl-3,
    .my-xxl-3 {
        margin-bottom: 1rem !important;
    }

    .ml-xxl-3,
    .mx-xxl-3 {
        margin-left: 1rem !important;
    }

    .m-xxl-4 {
        margin: 1.5rem !important;
    }

    .mt-xxl-4,
    .my-xxl-4 {
        margin-top: 1.5rem !important;
    }

    .mr-xxl-4,
    .mx-xxl-4 {
        margin-right: 1.5rem !important;
    }

    .mb-xxl-4,
    .my-xxl-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-xxl-4,
    .mx-xxl-4 {
        margin-left: 1.5rem !important;
    }

    .m-xxl-5 {
        margin: 3rem !important;
    }

    .mt-xxl-5,
    .my-xxl-5 {
        margin-top: 3rem !important;
    }

    .mr-xxl-5,
    .mx-xxl-5 {
        margin-right: 3rem !important;
    }

    .mb-xxl-5,
    .my-xxl-5 {
        margin-bottom: 3rem !important;
    }

    .ml-xxl-5,
    .mx-xxl-5 {
        margin-left: 3rem !important;
    }

    .p-xxl-0 {
        padding: 0 !important;
    }

    .pt-xxl-0,
    .py-xxl-0 {
        padding-top: 0 !important;
    }

    .pr-xxl-0,
    .px-xxl-0 {
        padding-right: 0 !important;
    }

    .pb-xxl-0,
    .py-xxl-0 {
        padding-bottom: 0 !important;
    }

    .pl-xxl-0,
    .px-xxl-0 {
        padding-left: 0 !important;
    }

    .p-xxl-1 {
        padding: 0.25rem !important;
    }

    .pt-xxl-1,
    .py-xxl-1 {
        padding-top: 0.25rem !important;
    }

    .pr-xxl-1,
    .px-xxl-1 {
        padding-right: 0.25rem !important;
    }

    .pb-xxl-1,
    .py-xxl-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-xxl-1,
    .px-xxl-1 {
        padding-left: 0.25rem !important;
    }

    .p-xxl-2 {
        padding: 0.5rem !important;
    }

    .pt-xxl-2,
    .py-xxl-2 {
        padding-top: 0.5rem !important;
    }

    .pr-xxl-2,
    .px-xxl-2 {
        padding-right: 0.5rem !important;
    }

    .pb-xxl-2,
    .py-xxl-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-xxl-2,
    .px-xxl-2 {
        padding-left: 0.5rem !important;
    }

    .p-xxl-3 {
        padding: 1rem !important;
    }

    .pt-xxl-3,
    .py-xxl-3 {
        padding-top: 1rem !important;
    }

    .pr-xxl-3,
    .px-xxl-3 {
        padding-right: 1rem !important;
    }

    .pb-xxl-3,
    .py-xxl-3 {
        padding-bottom: 1rem !important;
    }

    .pl-xxl-3,
    .px-xxl-3 {
        padding-left: 1rem !important;
    }

    .p-xxl-4 {
        padding: 1.5rem !important;
    }

    .pt-xxl-4,
    .py-xxl-4 {
        padding-top: 1.5rem !important;
    }

    .pr-xxl-4,
    .px-xxl-4 {
        padding-right: 1.5rem !important;
    }

    .pb-xxl-4,
    .py-xxl-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-xxl-4,
    .px-xxl-4 {
        padding-left: 1.5rem !important;
    }

    .p-xxl-5 {
        padding: 3rem !important;
    }

    .pt-xxl-5,
    .py-xxl-5 {
        padding-top: 3rem !important;
    }

    .pr-xxl-5,
    .px-xxl-5 {
        padding-right: 3rem !important;
    }

    .pb-xxl-5,
    .py-xxl-5 {
        padding-bottom: 3rem !important;
    }

    .pl-xxl-5,
    .px-xxl-5 {
        padding-left: 3rem !important;
    }

    .m-xxl-n1 {
        margin: -0.25rem !important;
    }

    .mt-xxl-n1,
    .my-xxl-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-xxl-n1,
    .mx-xxl-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-xxl-n1,
    .my-xxl-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-xxl-n1,
    .mx-xxl-n1 {
        margin-left: -0.25rem !important;
    }

    .m-xxl-n2 {
        margin: -0.5rem !important;
    }

    .mt-xxl-n2,
    .my-xxl-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-xxl-n2,
    .mx-xxl-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-xxl-n2,
    .my-xxl-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-xxl-n2,
    .mx-xxl-n2 {
        margin-left: -0.5rem !important;
    }

    .m-xxl-n3 {
        margin: -1rem !important;
    }

    .mt-xxl-n3,
    .my-xxl-n3 {
        margin-top: -1rem !important;
    }

    .mr-xxl-n3,
    .mx-xxl-n3 {
        margin-right: -1rem !important;
    }

    .mb-xxl-n3,
    .my-xxl-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-xxl-n3,
    .mx-xxl-n3 {
        margin-left: -1rem !important;
    }

    .m-xxl-n4 {
        margin: -1.5rem !important;
    }

    .mt-xxl-n4,
    .my-xxl-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-xxl-n4,
    .mx-xxl-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-xxl-n4,
    .my-xxl-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-xxl-n4,
    .mx-xxl-n4 {
        margin-left: -1.5rem !important;
    }

    .m-xxl-n5 {
        margin: -3rem !important;
    }

    .mt-xxl-n5,
    .my-xxl-n5 {
        margin-top: -3rem !important;
    }

    .mr-xxl-n5,
    .mx-xxl-n5 {
        margin-right: -3rem !important;
    }

    .mb-xxl-n5,
    .my-xxl-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-xxl-n5,
    .mx-xxl-n5 {
        margin-left: -3rem !important;
    }

    .m-xxl-auto {
        margin: auto !important;
    }

    .mt-xxl-auto,
    .my-xxl-auto {
        margin-top: auto !important;
    }

    .mr-xxl-auto,
    .mx-xxl-auto {
        margin-right: auto !important;
    }

    .mb-xxl-auto,
    .my-xxl-auto {
        margin-bottom: auto !important;
    }

    .ml-xxl-auto,
    .mx-xxl-auto {
        margin-left: auto !important;
    }

    /* align-* and justify-* */
    .justify-content-xxl-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }

    .justify-content-xxl-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }

    .justify-content-xxl-center {
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }

    .justify-content-xxl-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }

    .justify-content-xxl-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }

    .align-items-xxl-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }

    .align-items-xxl-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }

    .align-items-xxl-center {
        -ms-flex-align: center !important;
        align-items: center !important;
    }

    .align-items-xxl-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }

    .align-items-xxl-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }

    .align-content-xxl-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }

    .align-content-xxl-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }

    .align-content-xxl-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }

    .align-content-xxl-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }

    .align-content-xxl-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }

    .align-content-xxl-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }

    .align-self-xxl-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }

    .align-self-xxl-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }

    .align-self-xxl-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }

    .align-self-xxl-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }

    .align-self-xxl-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }

    .align-self-xxl-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }

    /* display properties */
    .d-xxl-none {
        display: none !important;
    }

    .d-xxl-inline {
        display: inline !important;
    }

    .d-xxl-inline-block {
        display: inline-block !important;
    }

    .d-xxl-block {
        display: block !important;
    }

    .d-xxl-table {
        display: table !important;
    }

    .d-xxl-table-row {
        display: table-row !important;
    }

    .d-xxl-table-cell {
        display: table-cell !important;
    }

    .d-xxl-flex {
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .d-xxl-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}