@media (min-width:992px) {
    .h-lg-100vh {
        height: 100vh;
    }
}

@media (max-width:1200px) {

    .image200by200,
    .image150by150 {
        height: 100px !important;
        width: 100px !important;
        max-width: 100px !important;
    }
}

@media (min-width:1201px) AND (max-width:1400px) {
    .image200by200 {
        height: 150px !important;
        width: 150px !important;
        max-width: 150px !important;
    }
}

@media (max-width:1400px) {
    .accountButtons span.authAccountButtonText {
        display: none !important;
    }

    .dashboardCards .min-width-300px {
        min-width: 250px !important;
    }
}

@media (min-width: 1367px) AND (max-width: 1440px) {

    /* .PricingPlans .right-10p {
        right: 5% !important;
    } */

    /* .PricingPlans h4 {
        right: -8% !important;
    } */

    .ExtendLimitPricing .right-10p {
        right: 5% !important;
    }

    .ExtendLimitPricing h4 {
        right: -5% !important;
    }

    .ExtendLimitPricing h4.top-5p {
        top: 8% !important;
    }

    .ExtendLimitPricing img {
        max-width: 38% !important;
    }
}

@media (min-width: 1281px) AND (max-width: 1366px) {

    /* .PricingPlans .right-10p {
        right: 5% !important;
    } */

    /* .PricingPlans h4 {
        right: -8% !important;
    } */

    .ExtendLimitPricing .right-10p {
        right: 5% !important;
    }

    .ExtendLimitPricing h4 {
        right: -7% !important;
    }

    .ExtendLimitPricing h4.top-5p {
        top: 8% !important;
    }

    /* .ExtendLimitPricing img,
    .PricingPlans img {
        max-width: 38% !important;
    } */
    .ExtendLimitPricing img {
        max-width: 38% !important;
    }

    iframe#iframeOne {
        width: 130% !important;
        height: 100% !important;
    }
}

@media (min-width: 1025px) AND (max-width: 1280px) {

    /* .PricingPlans .right-10p {
        right: 5% !important;
    } */

    /* .PricingPlans h4 {
        right: -12% !important;
        top: 1%;
    } */

    /* .PricingPlans h4.top-5p {
        top: 8% !important;
        right: -8% !important;
    } */

    .ExtendLimitPricing .right-10p {
        right: 5% !important;
    }

    .ExtendLimitPricing h4 {
        right: -9% !important;
    }

    .ExtendLimitPricing h4.top-5p {
        top: 8% !important;
    }

    /* .ExtendLimitPricing img,
    .PricingPlans img {
        max-width: 38% !important;
    } */
    .ExtendLimitPricing img {
        max-width: 38% !important;
    }

    iframe#iframeOne {
        width: 170% !important;
        height: 100% !important;
    }
}

@media (max-width: 1024px) {

    /* .PricingPlans .right-10p {
        right: 5% !important;
    } */

    /* .PricingPlans h4 {
        right: -12% !important;
        top: 1%;
    } */

    /* .PricingPlans h4.top-5p {
        top: 8% !important;
        right: -8% !important;
    } */

    .ExtendLimitPricing .right-10p {
        right: 5% !important;
    }

    .ExtendLimitPricing h4 {
        right: -2% !important;
    }

    .ExtendLimitPricing h4.top-5p {
        top: 8% !important;
    }

    /* .ExtendLimitPricing img,
    .PricingPlans img {
        max-width: 38% !important;
    } */
    .ExtendLimitPricing img {
        max-width: 38% !important;
    }

    iframe#iframeOne {
        width: 36% !important;
        height: 100% !important;
    }
}

@media (max-width:1600px) {

    .plan01,
    .plan02,
    .plan03,
    .plan04 {
        max-width: 35% !important;
        top: -1.5vh !important;
    }
}