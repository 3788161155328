body,
p,
a,
span {
    font-family: InterRegular !important;
}

h1 {
    font-family: InterBold !important;
}

.bgDivider {
    background-image: url(../../assets/img/newAuth/auth-divider.png);
    background-size: 70% 100%;
    background-position: right;
    background-repeat: no-repeat;
}

.authInput input::placeholder {
    color: rgba(23, 23, 23, .5);
}

.authInput input:focus {
    color: #171717;
    border-color: #00A5FF;
}

.authInput .btn {
    padding: 0.7rem 1.8rem 0.7rem 1.8rem;
}

.accountButtons a {
    border: 1px solid #C6E0EF;
    border-radius: 10px;
    /* padding: 0.8rem 1.8rem 0.8rem 1.8rem; */
    padding: 0.8rem 1.5rem 0.8rem 1.5rem;
    /* width: 70px; */
    height: 50px !important;
    white-space: nowrap;
    overflow: hidden;
    transition: width 1s;
}

.accountButtons a:hover,
.accountButtons .othersButton,
.accountButtons .emailContactButton {
    /* width: 255px; */
    width: auto !important;
}

.accountButtons a.activeButton span,
.accountButtons .emailContactButton span {
    display: inline-block;
}

.accountButtons a span {
    display: none;
}

.accountButtons a:hover span {
    display: inline-block;
    /* transition: display 0.5s ease; */
}

.tip-graphics {
    background-image: url('../../assets/img/newAuth/tip-graphics.png');
    background-repeat: no-repeat;
    background-size: 18%;
    background-position: 98% 100%;
}

.tip-graphics-center {
    background-size: 15% !important;
    background-position: 98% 50% !important;
}

.tipBackground {
    background: linear-gradient(270deg, #FFC34A 0%, #CE911D 100%);
}

.textDarkYellow {
    color: #E4AD41 !important;
}

/* ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #E9F3F9;
}

::-webkit-scrollbar {
    width: 8px;
    background-color: #E9F3F9;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #00A5FF;
} */