/* Dore Main Style 

Table of Contents

00.Variables and Imports
01.Base
02.Buttons
03.Widths
04.Borders
05.Badges
06.Breadcrumb
07.Tabs
08.Timer
09.Slick Slider
10.Navbar
11.Menu
12.Apps
13.Chat App
14.Survey App
15.Todo App
16.Main
17.Cards
18.Dashboard
19.Calendar
20.Datatable
21.Alerts
22.Forms
23.Form Wizard
24.Slider(Range)
25.Navs
26.Tooltip and popover
27.Progress
28.Rating
29.Sortable
30.Spinner
31.Croppper
32.Modal
33.Authorization
34.Html Editors
35.Icons
36.Loading
37.Media Library
38.Context Menu
39.Videojs
40.Prices
41.Tables
42.Invoice Print
43.Profile Page
44.Glide
46.Blog
47.Rounded
48.Rtl
49.Landingpage
*/
/* 00.Variables and Imports */
@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,400i,600,700");
/* 01.Base */
html {
  width: 100%;
  height: 100%;
  background: #f8f8f8; }

:root {
  --theme-color-1: #00365a;
  --theme-color-2: #184f90;
  --theme-color-3: #8a9fb4;
  --theme-color-4: #2c4d6e;
  --theme-color-5: #245794;
  --theme-color-6: #6a7b8d;
  --theme-color-1-10: rgba(0, 54, 90, 0.1);
  --theme-color-2-10: rgba(24, 79, 144, 0.1);
  --theme-color-3-10: rgba(138, 159, 180, 0.1);
  --theme-color-4-10: rgba(44, 77, 110, 0.1);
  --theme-color-5-10: rgba(36, 87, 148, 0.1);
  --theme-color-6-10: rgba(106, 123, 141, 0.1);
  --primary-color: #3a3a3a;
  --foreground-color: white;
  --separator-color: #d7d7d7; }

body {
  font-family: "Nunito", sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
  color: #3a3a3a;
  background: #f8f8f8; }
  body.background {
    height: 100%; }
    body.background main {
      margin: 0 !important;
      height: 100%; }
      body.background main .container {
        height: 100%; }

.rtl * {
  text-align: right; }

.fixed-background {
  /* background: url("../img/login/balloon-lg.jpg") no-repeat center center fixed; */
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.background .mx-auto {
  margin-left: auto !important;
  margin-right: auto !important; }

.disable-text-selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.scroll {
  margin-right: -15px;
  padding-right: 15px;
  position: relative;
  height: 100%; }

::selection {
  background: #c8d2dc; }

::-moz-selection {
  background: #c8d2dc; }

.ps__thumb-y {
  background-color: #d7d7d7; }

.logo-single {
  width: 110px;
  height: 35px;
  /* background: url("../logos/black.svg") no-repeat; */
  background-position: center center;
  display: inline-block;
  margin-bottom: 60px; }

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  line-height: 1.3; }

h1 {
  font-size: 1.75rem;
  padding-bottom: 10px;
  display: inline-block; }
  @media (max-width: 767px) {
    h1 {
      font-size: 1.3rem;
      padding-top: 0.25rem;
      margin-bottom: 0; } }

h2 {
  font-size: 1.4rem; }
  @media (max-width: 767px) {
    h2 {
      font-size: 1.1rem; } }

h3 {
  font-size: 1.3rem; }
  @media (max-width: 767px) {
    h3 {
      font-size: 1rem; } }

h3 {
  font-size: 1.2rem; }
  @media (max-width: 767px) {
    h3 {
      font-size: 1rem; } }

h4 {
  font-size: 1.15rem; }
  @media (max-width: 767px) {
    h4 {
      font-size: 0.9rem; } }

h5 {
  font-size: 1.1rem; }
  @media (max-width: 767px) {
    h5 {
      font-size: 0.9rem; } }

h6 {
  font-size: 1rem; }
  @media (max-width: 767px) {
    h6 {
      font-size: 0.85rem; } }

.depth-1 {
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04); }

.depth-2 {
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1); }

.r-0 {
  right: 0; }

.l-0 {
  left: 0; }

.list-item-heading {
  font-size: 1rem; }

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.min-width-zero {
  min-width: 0; }

.no-transition {
  transition: none !important; }

button {
  color: #3a3a3a;
  outline: initial !important; }

@media (max-width: 767px) {
  .display-1 {
    font-size: 2.8rem; } }

@media (max-width: 767px) {
  .display-2 {
    font-size: 2.6rem; } }

@media (max-width: 767px) {
  .display-3 {
    font-size: 2.4rem; } }

@media (max-width: 767px) {
  .display-4 {
    font-size: 2.2rem; } }

.lead {
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 2rem; }
  @media (max-width: 767px) {
    .lead {
      font-size: 1.1rem;
      line-height: 1.6rem; } }

.mb-5,
.my-5 {
  margin-bottom: 2rem !important; }

@media (max-width: 991px) {
  .separator.mb-5,
  .separator-tabs.mb-5 {
    margin-bottom: 1rem !important; } }

@media (max-width: 575px) {
  .float-none-xs {
    float: initial !important; } }

.main-heading {
  border-bottom: 1px solid #d7d7d7; }

.separator {
  border-bottom: 1px solid #d7d7d7; }

.alert-dismissible .close {
  padding: 0.5rem 1.25rem; }

ul li {
  margin-bottom: 5px; }
  ul li:last-of-type {
    margin-bottom: 0; }

.rounded {
  border-radius: 50px !important; }

.video-play-icon {
  width: 100%;
  height: 100%;
  position: absolute; }
  .video-play-icon span {
    position: absolute;
    background: rgba(255, 255, 255, 0.7);
    height: 2.5rem;
    border-radius: 2rem;
    line-height: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.8em;
    width: 3em;
    text-align: center;
    padding-left: 5px; }
    .video-play-icon span:before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 0 8px 12px;
      font-size: 0;
      border-color: transparent transparent transparent #00365a; }
  .video-play-icon:hover span, .video-play-icon:active span {
    background: rgba(255, 255, 255, 0.85); }

.opacity-25 {
  opacity: 0.25; }

.opacity-50 {
  opacity: 0.5; }

.opacity-75 {
  opacity: 0.75; }

a {
  color: #3a3a3a;
  transition: color 200ms;
  outline: initial !important; }
  a:hover, a:active {
    text-decoration: initial;
    color: #00365a; }

.white {
  color: #fff !important; }

p {
  font-size: 0.85rem;
  line-height: 1.3rem;
  font-family: "Nunito", sans-serif; }

.text-large {
  font-size: 1.9rem !important; }

.text-one {
  font-size: 1rem !important; }

.text-xlarge {
  font-size: 2.7rem !important; }

.text-small {
  font-size: 0.76rem !important;
  line-height: 0.9rem !important; }

.text-white {
  color: #fff !important; }

.text-zero {
  font-size: 0 !important; }

.text-extra-small {
  font-size: 0.7rem !important; }

.text-default {
  color: #3a3a3a !important; }

.text-muted {
  color: #909090 !important; }

.text-semi-muted {
  color: #8f8f8f !important; }

.font-weight-medium {
  font-weight: 500; }

.font-weight-semibold {
  font-weight: 600; }

.color-theme-1 {
  color: #00365a; }

.color-theme-2 {
  color: #184f90; }

.ltr .btn-group.dropleft .dropdown-toggle-split {
  border-top-right-radius: 0.1rem;
  border-bottom-right-radius: 0.1rem; }

#displayOptions a {
  cursor: pointer; }

#displayOptions a.active i {
  color: #00365a; }

#displayOptions button {
  border-color: #8f8f8f;
  color: #8f8f8f; }
  #displayOptions button:hover {
    background-color: #00365a;
    border-color: #00365a;
    color: #fff; }

#displayOptions .btn-outline-dark:not(:disabled):not(.disabled):active,
#displayOptions .btn-outline-dark:not(:disabled):not(.disabled).active,
#displayOptions .show > .btn-outline-dark.dropdown-toggle {
  background-color: #00365a;
  border-color: #00365a;
  color: #fff; }

#displayOptions > span {
  padding-top: 0.1rem; }

#displayOptions .view-icon svg {
  width: 19px; }

#displayOptions .view-icon .view-icon-svg {
  fill: #8f8f8f; }

#displayOptions .view-icon:hover .view-icon-svg, #displayOptions .view-icon.active .view-icon-svg {
  fill: #00365a; }

@media (min-width: 767px) {
  .collapse.dont-collapse-sm {
    display: block;
    height: auto !important;
    visibility: visible; } }

.clearfix::after {
  content: "";
  clear: both;
  display: table; }

.accordion-content {
  padding-top: 0 !important; }

.feedback-container a {
  font-size: 1.3em;
  color: #8f8f8f;
  margin: 0.5rem; }
  .feedback-container a:hover, .feedback-container a:active {
    color: #00365a; }

.gallery img {
  max-height: 280px; }

#baguetteBox-overlay .full-image img {
  box-shadow: initial; }

.baguetteBox-button#next-button,
.baguetteBox-button#previous-button,
.baguetteBox-button#close-button {
  height: 40px;
  width: 40px;
  border-radius: 40px; }

.baguetteBox-button {
  font-family: "simple-line-icons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased; }
  .baguetteBox-button#next-button svg,
  .baguetteBox-button#close-button svg,
  .baguetteBox-button#previous-button svg {
    display: none; }
  .baguetteBox-button#close-button:before {
    content: "\e082"; }
  .baguetteBox-button#next-button:before {
    content: "\e079"; }
  .baguetteBox-button#previous-button:before {
    content: "\e07a"; }

.border-radius {
  border-radius: 0.1rem; }

/* 02.Buttons */
button {
  color: #3a3a3a;
  outline: initial !important; }

.btn-arrow {
  display: inline-block;
  text-align: center;
  border-radius: 30px !important;
  width: 42px;
  height: 42px;
  line-height: 24px; }

.btn-arrow i {
  font-size: 15px;
  display: inline-block;
  text-align: center; }

.btn-sm.btn-arrow {
  width: 34px;
  height: 34px;
  line-height: 17px; }

.btn-sm.btn-arrow i {
  font-size: 13px;
  line-height: 10px; }

.btn {
  border-radius: 50px;
  outline: initial !important;
  box-shadow: none !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  padding: 0.5rem 1.25rem 0.5rem 1.25rem;
  transition: background-color box-shadow 0.1s linear; }

.btn-shadow {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 1px 3px 1px rgba(0, 0, 0, 0.15) !important;
  transition: background-color box-shadow 0.1s linear; }
  .btn-shadow:hover, .btn-shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 2px rgba(0, 0, 0, 0.15) !important; }

.btn-empty {
  background: transparent !important; }

.btn-lg,
.btn-group-lg > .btn,
.btn-group-sm > .btn {
  border-radius: 50px; }

.btn.default {
  border-radius: 0.1rem; }

.btn-primary {
  background-color: #00365a;
  border-color: #00365a;
  color: #fff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #001e31;
    border-color: #001e31; }

.check-button {
  cursor: default !important;
  padding-left: 3rem !important;
  min-height: unset !important;
  line-height: unset !important;
  padding-top: 0.5rem !important; }
  .check-button label.custom-control {
    padding-left: initial;
    min-height: initial;
    vertical-align: top;
    margin-top: 0; }
  .check-button span {
    line-height: unset; }

.check-button.btn-primary {
  background-color: #00365a !important;
  border-color: #00365a !important;
  opacity: 1; }

.check-button .custom-control {
  min-height: 1.1rem;
  margin-top: -7px; }

.dropdown-menu {
  font-size: 0.8rem;
  border-radius: 0.1rem;
  background: white;
  border-color: rgba(58, 58, 58, 0.15); }

.dropdown-item {
  padding: 0.5rem 1.25rem;
  color: #3a3a3a; }

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: #f8f8f8;
  color: #3a3a3a; }

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #00365a; }

.dropdown-divider {
  border-color: #d7d7d7; }

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: #00111d;
  border-color: #00111d;
  color: #fff; }

.btn-secondary {
  background-color: #184f90;
  border-color: #184f90;
  color: #fff; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #123c6d;
    border-color: #123c6d; }

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: #0f325c;
  border-color: #0f325c;
  color: #fff; }

.btn-primary.btn-primary-gradient {
  background: linear-gradient(to right, #00365a, #00080e); }
  .btn-primary.btn-primary-gradient:hover {
    background: linear-gradient(to right, #00365a, #001e31); }

.btn-primary-gradient:not(:disabled):not(.disabled):active,
.btn-primary-gradient:not(:disabled):not(.disabled).active,
.show > .btn-primary-gradient.dropdown-toggle {
  background: linear-gradient(to right, #00365a, #00080e); }

.btn-secondary-gradient {
  background: linear-gradient(to right, #184f90, #0d2b4e); }
  .btn-secondary-gradient:hover {
    background: linear-gradient(to right, #184f90, #123c6d); }

.btn-secondary-gradient:not(:disabled):not(.disabled):active,
.btn-secondary-gradient:not(:disabled):not(.disabled).active,
.show > .btn-secondary-gradient.dropdown-toggle {
  background: linear-gradient(to right, #184f90, #0d2b4e); }

.btn-warning {
  background-color: #b69329;
  border-color: #b69329; }

.btn-success,
.btn-info,
.btn-danger,
.btn-warning {
  color: #fff; }
  .btn-success:hover,
  .btn-info:hover,
  .btn-danger:hover,
  .btn-warning:hover {
    color: #fff; }

.btn-outline-success:hover,
.btn-outline-info:hover,
.btn-outline-danger:hover,
.btn-outline-warning:hover {
  color: white; }

.btn-light {
  color: #131113;
  background-color: #ececec;
  border-color: #ececec; }
  .btn-light:hover {
    color: #131113;
    background-color: #d8d8d8;
    border-color: #d8d8d8; }

.btn-dark {
  color: #ececec;
  background-color: #131113;
  border-color: #131113; }
  .btn-dark:hover {
    color: #ececec;
    background-color: #060506;
    border-color: #060506; }

.btn-outline-dark {
  color: #131113;
  border-color: #131113; }
  .btn-outline-dark:hover {
    color: white;
    background-color: #131113;
    border-color: #131113; }

.btn-outline-white {
  color: #fff;
  border-color: #fff;
  background-color: initial; }
  .btn-outline-white:hover {
    color: #00365a;
    background-color: #fff; }

.btn-outline-light {
  color: #ececec;
  border-color: #ececec; }
  .btn-outline-light:hover {
    color: white;
    background-color: #ececec;
    border-color: #ececec; }

.btn-outline-primary {
  color: #00365a;
  border-color: #00365a; }
  .btn-outline-primary:hover {
    color: white;
    background-color: #00365a;
    border-color: #00365a; }

.btn-outline-theme-3 {
  background: unset;
  color: #8a9fb4;
  border-color: #8a9fb4; }
  .btn-outline-theme-3:hover {
    background-color: #8a9fb4;
    border-color: #8a9fb4;
    color: white; }

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: #00365a;
  border-color: #00365a;
  color: white; }

.btn-outline-secondary {
  color: #184f90;
  border-color: #184f90; }
  .btn-outline-secondary:hover {
    background-color: #184f90;
    border-color: #184f90;
    color: white; }

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  background-color: #184f90;
  border-color: #184f90;
  color: white; }

.btn-header-light {
  color: #d7d7d7;
  border-color: transparent;
  background: transparent; }
  .btn-header-light:hover {
    background-color: transparent;
    border-color: #d7d7d7; }

.btn-header-primary {
  color: #00365a;
  border-color: transparent;
  background: transparent; }
  .btn-header-primary:hover {
    background-color: transparent;
    border-color: #00365a; }

.btn-header-secondary {
  color: #184f90;
  border-color: transparent;
  background: transparent; }
  .btn-header-secondary:hover {
    background-color: transparent;
    border-color: #184f90; }

.btn-header-primary-light {
  color: #8a9fb4;
  border-color: transparent;
  background: transparent; }
  .btn-header-primary-light:hover {
    background-color: transparent;
    border-color: #8a9fb4; }

.btn-xl,
.btn-group-xl > .btn {
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 0.05rem;
  padding: 1rem 3.5rem 0.9rem; }

.btn-lg,
.btn-group-lg > .btn {
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 0.05rem;
  padding: 0.75rem 2.6rem 0.6rem 2.6rem; }

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-size: 0.76rem;
  line-height: 1.5; }

.btn-xs,
.btn-group-xs > .btn {
  padding: 0.25rem 1.25rem;
  font-size: 0.76rem;
  line-height: 1.3; }

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #00365a;
  border-color: #00365a;
  color: white; }

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: #184f90;
  border-color: #184f90;
  color: white; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.btn-link {
  color: #00365a;
  text-decoration: initial !important; }

.white-underline-link {
  color: #fff;
  text-decoration: underline; }
  .white-underline-link:hover, .white-underline-link:active {
    color: #fff;
    text-decoration: initial; }

.btn-link:hover {
  color: rgba(0, 54, 90, 0.7); }

.btn-multiple-state {
  position: relative;
  transition: opacity 500ms; }
  .btn-multiple-state .spinner,
  .btn-multiple-state .icon {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: 0;
    visibility: hidden;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 500ms;
    color: #fff;
    pointer-events: none; }
  .btn-multiple-state .icon i {
    vertical-align: text-bottom;
    font-size: 18px; }
  .btn-multiple-state .label {
    transition: opacity 500ms; }
  .btn-multiple-state.show-spinner .label {
    opacity: 0; }
  .btn-multiple-state.show-spinner .spinner {
    opacity: 1;
    visibility: visible; }
  .btn-multiple-state.show-success .label {
    opacity: 0; }
  .btn-multiple-state.show-success .icon.success {
    opacity: 1;
    visibility: visible; }
  .btn-multiple-state.show-fail .label {
    opacity: 0; }
  .btn-multiple-state.show-fail .icon.fail {
    opacity: 1;
    visibility: visible; }
  .btn-multiple-state.btn-primary:disabled {
    opacity: 1;
    background: #001727;
    border-color: #001727; }
  .btn-multiple-state.btn-secondary:disabled {
    opacity: 1;
    border-color: #113764; }

.icon-button {
  padding: 0;
  font-size: 14px;
  width: 34px;
  height: 34px;
  line-height: 34px; }
  .icon-button.large {
    width: 44px;
    height: 44px;
    font-size: 18px;
    line-height: 2.5; }
  .icon-button.small-icon {
    font-size: 12px;
    line-height: 32px; }

.top-right-button-single {
  width: unset; }
  @media (max-width: 991px) {
    .top-right-button-single {
      width: calc(100%); } }

.top-right-button-container {
  float: right;
  position: relative; }
  @media (max-width: 575px) {
    .top-right-button-container {
      float: initial;
      display: flex;
      justify-content: space-between; } }
  @media (max-width: 575px) {
    .top-right-button-container .top-right-button {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: center; } }

/* 03.Widths */
.w-10 {
  width: 10% !important; }

.w-90 {
  width: 90% !important; }

.w-12 {
  width: 12% !important; }

.w-88 {
  width: 88% !important; }

.w-15 {
  width: 15% !important; }

.w-85 {
  width: 85% !important; }

.w-20 {
  width: 20% !important; }

.w-80 {
  width: 80% !important; }

.w-30 {
  width: 30% !important; }

.w-70 {
  width: 70% !important; }

.w-40 {
  width: 40% !important; }

.w-60 {
  width: 60% !important; }

@media (max-width: 767px) {
  .w-xs-100 {
    width: 100% !important; } }

@media (max-width: 991px) {
  .w-sm-100 {
    width: 100% !important; } }

/* 04.Borders */
.border {
  border: 1px solid #f3f3f3 !important; }

.border-right {
  border-right: 1px solid #f3f3f3 !important; }

.border-left {
  border-left: 1px solid #f3f3f3 !important; }

.border-top {
  border-top: 1px solid #f3f3f3 !important; }

.border-bottom {
  border-bottom: 1px solid #f3f3f3 !important; }

.border-primary,
.border-theme-1 {
  border-color: #00365a !important; }

.border-theme-2 {
  border-color: #184f90 !important; }

.border-theme-3 {
  border-color: #8a9fb4 !important; }

.border-secondary {
  border-color: #3a3a3a !important; }

/* Colors */
.text-theme-2 {
  color: #184f90 !important; }

.text-theme-3 {
  color: #8a9fb4 !important; }

.text-primary,
.text-theme-1 {
  color: #00365a !important; }

.text-secondary {
  color: #3a3a3a !important; }

/* 05.Badges */
.bg-theme-2,
.badge-theme-2 {
  background-color: #184f90 !important;
  color: #fff; }

.bg-theme-3,
.badge-theme-3 {
  background-color: #8a9fb4 !important;
  color: #fff; }

.bg-primary,
.bg-theme-1,
.badge-primary,
.badge-theme-1 {
  background-color: #00365a !important;
  color: #fff; }

.bg-secondary,
.badge-secondary {
  background-color: #184f90 !important;
  color: #fff; }

.badge-warning {
  background-color: #b69329; }

.badge-success {
  background-color: #3e884f; }

.badge-info {
  background-color: #3195a5; }

.badge-danger {
  background-color: #c43d4b; }

.badge-success,
.badge-danger,
.badge-warning,
.badge-info {
  color: #fff; }

.badge {
  padding: 0.6em 0.75em;
  font-size: 74%; }
  .badge.badge-pill {
    padding-right: 1.25em;
    padding-left: 1.25em; }
  .badge.badge-top-left {
    top: 10px;
    left: -7px; }
  .badge.badge-top-left-2 {
    top: 40px;
    left: -7px; }
  .badge.badge-top-right {
    top: 8px;
    right: -7px; }
  .badge.badge-top-right-2 {
    top: 40px;
    right: -7px; }

.badge-light {
  background-color: #ececec;
  color: #131113; }

.badge-dark {
  background-color: #131113;
  color: #ececec; }

.badge-outline-primary,
.badge-outline-theme-1 {
  background: unset;
  border: 1px solid #00365a;
  color: #00365a; }

.badge-outline-secondary,
.badge-outline-theme-2 {
  background: unset;
  border: 1px solid #184f90;
  color: #184f90; }

.badge-outline-theme-3 {
  background: unset;
  border: 1px solid #8a9fb4;
  color: #8a9fb4; }

.badge-outline-success {
  background: unset;
  border: 1px solid #3e884f;
  color: #3e884f; }

.badge-outline-danger {
  background: unset;
  border: 1px solid #c43d4b;
  color: #c43d4b; }

.badge-outline-warning {
  background: unset;
  border: 1px solid #b69329;
  color: #b69329; }

.badge-outline-info {
  background: unset;
  border: 1px solid #3195a5;
  color: #3195a5; }

.badge-outline-light {
  background: unset;
  border: 1px solid #ececec;
  color: #ececec; }

.badge-outline-dark {
  background: unset;
  border: 1px solid #131113;
  color: #131113; }

/* 06.Breadcrumb */
@media (max-width: 991px) {
  .breadcrumb-container .breadcrumb {
    padding: 0; } }

.breadcrumb {
  background-color: transparent;
  margin-bottom: 0.5rem; }

.breadcrumb-item + .breadcrumb-item::before {
  content: "|"; }

/* 07.Tabs */
.nav-tabs.separator-tabs {
  border-bottom: 1px solid #d7d7d7; }

.nav-tabs .nav-link {
  border: initial;
  padding-top: 1rem; }

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background: initial; }

.nav-tabs .nav-link.active::before,
.nav-tabs .nav-item.show .nav-link::before {
  content: " ";
  background: #00365a;
  color: white;
  border-radius: 10px;
  position: absolute;
  width: calc(100% - 1rem);
  height: 5px;
  top: 0;
  left: 50%;
  transform: translateX(-50%); }

.nav-tabs.separator-tabs .nav-link.active::before,
.nav-tabs.separator-tabs .nav-item.show .nav-link::before {
  content: " ";
  background: #00365a;
  border-radius: 10px;
  position: absolute;
  width: 100%;
  height: 2px;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  top: unset; }

.nav-tabs.separator-tabs .nav-link {
  border: initial;
  padding-top: 1rem;
  background: initial;
  padding-left: 0;
  padding-top: 0.5rem;
  padding-right: 0;
  margin-right: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #8f8f8f; }

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border: initial;
  position: relative;
  color: #00365a; }

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border: initial;
  color: #00365a; }

/* 08.Timer */
.timer-column {
  width: 80px;
  display: inline-block; }
  .timer-column .lead {
    color: #00365a;
    font-size: 2.4rem;
    margin-bottom: 0.5rem; }

.newsletter-input-container {
  margin-top: 0.5rem; }
  .newsletter-input-container .input-group {
    border-radius: 50px; }
  .newsletter-input-container input {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    padding: 0.5rem 1.25rem;
    height: 42px; }
  .newsletter-input-container button {
    line-height: 0.5; }
    @media (max-width: 767px) {
      .newsletter-input-container button {
        padding: 1rem 1.5rem 0.9rem; } }

/* 09.Slick Slider */
.slick .slick-slide {
  margin-left: 15px;
  margin-right: 15px;
  outline: initial !important;
  height: unset; }
  .slick .slick-slide.card {
    display: flex;
    flex-grow: 1; }
    .slick .slick-slide.card .w-50 {
      display: flex; }
    .slick .slick-slide.card .card-body {
      flex-direction: column;
      display: flex;
      justify-content: space-between; }

.slick-list {
  display: flex; }

.slick-track {
  padding-bottom: 20px;
  display: flex; }

@media (max-width: 991px) {
  .carousel-center-col {
    padding-left: 0;
    padding-right: 0; } }

.slick-dots {
  list-style: none;
  padding: 0; }
  .slick-dots li {
    display: inline-block; }

.slider-nav .slider-dot-container {
  display: inline-block; }

.slick-dot {
  width: 6px;
  height: 6px;
  border-radius: 10px;
  background: #d7d7d7;
  outline: initial !important;
  border: initial;
  margin: 0 5px;
  padding: 0; }

.slick-dots li.slick-active button {
  background: #00365a; }

/* 10.Navbar*/
.navbar {
  background: white;
  height: 100px;
  padding: 1.5rem 0 1.5rem 0;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04); }
  .navbar .navbar-contents {
    display: flex;
    flex-grow: 1;
    justify-content: space-between; }
  .navbar #notificationDropdown {
    width: 260px;
    padding: 1.5rem;
    height: 280px;
    right: 15px; }
  .navbar #iconMenuDropdown {
    width: 240px;
    padding: 1.5rem;
    height: 290px; }
  .navbar .icon-menu-item {
    width: 90px;
    display: inline-block;
    text-align: center;
    margin-bottom: 1.7rem;
    color: #6d6d6d; }
    .navbar .icon-menu-item i {
      font-size: 32px;
      line-height: 42px; }
    .navbar .icon-menu-item span {
      text-align: center;
      padding: 0 10px;
      line-height: 14px; }
    .navbar .icon-menu-item:hover, .navbar .icon-menu-item:focus {
      color: #00365a; }
  .navbar .menu-button-mobile {
    color: #8f8f8f;
    text-align: center;
    margin-left: 15px; }
    .navbar .menu-button-mobile svg {
      height: 12px;
      fill: #3a3a3a; }
    @media (max-width: 767px) {
      .navbar .menu-button-mobile {
        width: 20px; } }
  .navbar .menu-button {
    color: #8f8f8f;
    width: 120px;
    text-align: center; }
    .navbar .menu-button svg {
      height: 12px; }
    .navbar .menu-button .main {
      fill: #3a3a3a;
      transition: fill 300ms; }
    .navbar .menu-button .sub {
      fill: #3a3a3a;
      transition: fill 300ms; }
    .navbar .menu-button:hover {
      color: #00365a; }
    @media (max-width: 1439px) {
      .navbar .menu-button {
        width: 110px; } }
    @media (max-width: 1199px) {
      .navbar .menu-button {
        width: 100px; } }
    @media (max-width: 767px) {
      .navbar .menu-button {
        width: 60px; } }
  .navbar .navbar-left {
    flex-basis: 40%; }
  .navbar .navbar-right {
    flex-basis: 40%;
    text-align: right; }
    .navbar .navbar-right .user {
      margin-right: 60px; }
      @media (max-width: 1439px) {
        .navbar .navbar-right .user {
          margin-right: 50px; } }
      @media (max-width: 1199px) {
        .navbar .navbar-right .user {
          margin-right: 40px; } }
      @media (max-width: 767px) {
        .navbar .navbar-right .user {
          margin-right: 15px; } }
  .navbar .search {
    position: relative;
    width: 230px;
    border-radius: 20px;
    background: #f8f8f8; }
    .navbar .search input {
      border: initial;
      background: transparent;
      outline: initial !important;
      font-size: 0.8rem;
      width: 93%;
      color: #3a3a3a;
      padding: 0.4rem 1rem;
      line-height: 2; }
    .navbar .search .search-icon {
      font-size: 17px;
      border-radius: 10px;
      color: #d7d7d7;
      position: absolute;
      width: 40px;
      height: 40px;
      bottom: -10px;
      right: 3px;
      text-align: center;
      cursor: pointer; }
      .navbar .search .search-icon:hover {
        color: #00365a; }
    @media (max-width: 991px) {
      .navbar .search {
        width: 115px; }
        .navbar .search input {
          width: 85%; } }
    @media (max-width: 767px) {
      .navbar .search {
        width: 30px;
        height: 30px;
        background: initial;
        margin-left: 0.6rem;
        color: #878787; }
        .navbar .search input {
          display: none; }
        .navbar .search .search-icon {
          font-size: 17px;
          width: 30px;
          height: 30px;
          bottom: -3px;
          right: 0;
          color: inherit; }
        .navbar .search.mobile-view {
          display: block;
          width: 100%;
          position: fixed;
          z-index: 2;
          background: white;
          left: 0;
          top: 0;
          height: 70px;
          margin-left: 15px; }
          .navbar .search.mobile-view input {
            display: block;
            width: 100%;
            height: 70px;
            padding-left: 0; }
          .navbar .search.mobile-view span {
            top: 50%;
            transform: translateY(-50%);
            right: 25px; } }
  .navbar .header-icons {
    margin-right: 0.5rem; }
    @media (max-width: 575px) {
      .navbar .header-icons {
        margin-right: 0; } }
  .navbar .header-icon {
    font-size: 16px;
    color: #8f8f8f;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    vertical-align: initial; }
    @media (max-width: 575px) {
      .navbar .header-icon {
        padding-left: 0.3rem;
        padding-right: 0.3rem; } }
    .navbar .header-icon:hover {
      color: #00365a; }
    .navbar .header-icon#fullScreenButton i:last-of-type {
      display: none; }
    .navbar .header-icon#notificationButton .count {
      font-size: 9px;
      color: #00365a;
      border: 1px solid #00365a;
      border-radius: 10px;
      position: absolute;
      width: 18px;
      height: 15px;
      text-align: center;
      font-weight: 700;
      top: -4px;
      right: 2px;
      line-height: 14px; }
      @media (max-width: 575px) {
        .navbar .header-icon#notificationButton .count {
          right: -1px; } }
  .navbar .user {
    color: #878787;
    position: relative; }
    .navbar .user img {
      margin-left: 10px;
      border-radius: 30px;
      width: 40px; }
    .navbar .user .name {
      color: #878787; }
    @media (max-width: 991px) {
      .navbar .user .name {
        display: none; } }
    @media (max-width: 767px) {
      .navbar .user {
        margin-left: initial; }
        .navbar .user img {
          width: 30px; }
        .navbar .user:after {
          font-size: 11px;
          width: 14px;
          height: 14px;
          bottom: -3px;
          right: -3px; } }
  @media (max-width: 1439px) {
    .navbar {
      height: 90px; } }
  @media (max-width: 1199px) {
    .navbar {
      height: 80px;
      padding: 12px 0; } }
  @media (max-width: 767px) {
    .navbar {
      height: 70px; } }

.logo {
  width: 110px;
  height: 35px;
  /* background: url("../logos/black.svg") no-repeat; */
  background-position: center center;
  margin: 0 auto; }
  @media (max-width: 767px) {
    .logo {
      width: 80px; } }

.logo-mobile {
  width: 20px;
  height: 35px;
  /* background: url("../logos/mobile.svg") no-repeat; */
  background-position: center center; }

#app-container.sub-hidden .menu-button .sub,
#app-container.menu-sub-hidden .menu-button .sub {
  fill: #8f8f8f; }

#app-container.main-hidden .menu-button .main,
#app-container.main-hidden .menu-button .sub,
#app-container.menu-hidden .menu-button .main,
#app-container.menu-hidden .menu-button .sub {
  fill: #8f8f8f; }

/* 11.Menu*/
.menu {
  position: fixed;
  top: 0;
  bottom: 0;
  padding-top: 100px;
  z-index: 5;
  height: calc(100% - 100px);
  transition: border-radius 300ms; }
  .menu .main-menu {
    width: 120px;
    height: calc(100% - 100px);
    background: white;
    z-index: 3;
    position: fixed;
    padding-top: 10px;
    padding-bottom: 10px;
    left: 0; }
    .menu .main-menu .scroll {
      padding-right: unset;
      margin-right: unset;
      height: 100%; }
      .menu .main-menu .scroll .ps__thumb-y {
        right: 0; }
    .menu .main-menu.default-transition {
      transition: transform 300ms, border-radius 300ms; }
    .menu .main-menu ul {
      margin-bottom: initial; }
      .menu .main-menu ul li {
        position: relative; }
        .menu .main-menu ul li span {
          text-align: center;
          padding: 0 10px;
          line-height: 14px; }
        .menu .main-menu ul li a {
          height: 110px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          border-bottom: 1px solid #f3f3f3;
          color: #3a3a3a;
          transition: color 300ms;
          transition: background 300ms; }
          .menu .main-menu ul li a:hover, .menu .main-menu ul li a:focus {
            color: #00365a; }
        .menu .main-menu ul li i {
          font-size: 32px;
          line-height: 42px; }
        .menu .main-menu ul li.active a {
          color: #00365a; }
        .menu .main-menu ul li.active:after {
          content: " ";
          background: #00365a;
          color: white;
          border-radius: 10px;
          position: absolute;
          width: 6px;
          height: 90px;
          top: 50%;
          transform: translateY(-50%);
          left: 0; }
        .menu .main-menu ul li:last-of-type a {
          border-bottom: initial; }
    @media (max-width: 1439px) {
      .menu .main-menu {
        width: 110px;
        height: calc(100% - 90px); } }
    @media (max-width: 1199px) {
      .menu .main-menu {
        width: 100px;
        height: calc(100% - 80px); } }
    @media (max-width: 767px) {
      .menu .main-menu {
        width: 90px;
        height: calc(100% - 70px); }
        .menu .main-menu ul li i {
          font-size: 26px;
          line-height: 30px; }
        .menu .main-menu ul li a {
          height: 90px; }
        .menu .main-menu ul li.active:after {
          width: 3px;
          height: 60px; } }
  .menu .sub-menu {
    width: 230px;
    background: white;
    z-index: 2;
    position: fixed;
    left: 120px;
    border-left: 1px solid #f3f3f3;
    height: calc(100% - 100px);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 0;
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1); }
    .menu .sub-menu.default-transition {
      transition: transform 300ms; }
    .menu .sub-menu .scroll {
      margin-right: unset;
      padding-right: unset;
      height: 100%;
      padding-top: 15px;
      padding-bottom: 15px; }
      .menu .sub-menu .scroll .ps__thumb-y {
        right: 0; }
    .menu .sub-menu ul {
      display: none; }
      .menu .sub-menu ul li {
        margin-left: 30px;
        margin-bottom: 0;
        position: relative; }
        .menu .sub-menu ul li a {
          font-size: 13px;
          display: block;
          padding: 8px 0;
          margin-bottom: 10px; }
          .menu .sub-menu ul li a span {
            vertical-align: middle;
            height: 16px; }
        .menu .sub-menu ul li i {
          font-size: 1.3em;
          margin-right: 10px;
          color: #8f8f8f;
          vertical-align: middle;
          display: inline-block; }
        .menu .sub-menu ul li.active i,
        .menu .sub-menu ul li.active a {
          color: #00365a; }
        .menu .sub-menu ul li.active:after {
          content: " ";
          background: #00365a;
          border-radius: 10px;
          position: absolute;
          width: 4px;
          height: 4px;
          top: 50%;
          transform: translateY(-50%);
          left: -16px; }
    .menu .sub-menu ul.inner-level-menu {
      display: block !important; }
      .menu .sub-menu ul.inner-level-menu li a,
      .menu .sub-menu ul.inner-level-menu li i {
        color: #3a3a3a; }
      .menu .sub-menu ul.inner-level-menu li a:hover,
      .menu .sub-menu ul.inner-level-menu li.active i,
      .menu .sub-menu ul.inner-level-menu li.active a {
        color: #00365a; }
      .menu .sub-menu ul.inner-level-menu li.active:after {
        left: -22px; }
    .menu .sub-menu .rotate-arrow-icon i {
      transition: transform 200ms;
      transform: rotate(0); }
    .menu .sub-menu .rotate-arrow-icon.collapsed i {
      transform: rotate(-90deg); }
    @media (max-width: 1439px) {
      .menu .sub-menu {
        left: 110px;
        width: 230px;
        height: calc(100% - 90px); } }
    @media (max-width: 1199px) {
      .menu .sub-menu {
        left: 100px;
        width: 230px;
        height: calc(100% - 80px); } }
    @media (max-width: 767px) {
      .menu .sub-menu {
        left: 90px;
        width: 230px;
        height: calc(100% - 70px); } }
  @media (max-width: 1439px) {
    .menu {
      padding-top: 90px;
      height: calc(100% - 90px); } }
  @media (max-width: 1199px) {
    .menu {
      padding-top: 80px;
      height: calc(100% - 80px); } }
  @media (max-width: 767px) {
    .menu {
      padding-top: 70px;
      height: calc(100% - 70px);
      box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04); } }

#app-container.sub-hidden .sub-menu,
#app-container.menu-sub-hidden .sub-menu,
#app-container.menu-hidden .sub-menu {
  transform: translateX(-230px); }
  @media (max-width: 1439px) {
    #app-container.sub-hidden .sub-menu,
    #app-container.menu-sub-hidden .sub-menu,
    #app-container.menu-hidden .sub-menu {
      transform: translateX(-230px); } }
  @media (max-width: 1199px) {
    #app-container.sub-hidden .sub-menu,
    #app-container.menu-sub-hidden .sub-menu,
    #app-container.menu-hidden .sub-menu {
      transform: translateX(-230px); } }
  @media (max-width: 767px) {
    #app-container.sub-hidden .sub-menu,
    #app-container.menu-sub-hidden .sub-menu,
    #app-container.menu-hidden .sub-menu {
      transform: translateX(-230px); } }

#app-container.main-hidden .main-menu,
#app-container.menu-hidden .main-menu {
  transform: translateX(-120px); }
  @media (max-width: 1439px) {
    #app-container.main-hidden .main-menu,
    #app-container.menu-hidden .main-menu {
      transform: translateX(-110px); } }
  @media (max-width: 1199px) {
    #app-container.main-hidden .main-menu,
    #app-container.menu-hidden .main-menu {
      transform: translateX(-100px); } }
  @media (max-width: 767px) {
    #app-container.main-hidden .main-menu,
    #app-container.menu-hidden .main-menu {
      transform: translateX(-90px); } }

#app-container.main-hidden.sub-hidden .sub-menu,
#app-container.menu-hidden .sub-menu {
  transform: translateX(-350px); }
  @media (max-width: 1439px) {
    #app-container.main-hidden.sub-hidden .sub-menu,
    #app-container.menu-hidden .sub-menu {
      transform: translateX(-340px); } }
  @media (max-width: 1199px) {
    #app-container.main-hidden.sub-hidden .sub-menu,
    #app-container.menu-hidden .sub-menu {
      transform: translateX(-330px); } }
  @media (max-width: 767px) {
    #app-container.main-hidden.sub-hidden .sub-menu,
    #app-container.menu-hidden .sub-menu {
      transform: translateX(-320px); } }

#app-container.menu-main-hidden .main-menu {
  width: 0;
  display: none; }

#app-container.menu-main-hidden .sub-menu {
  left: 0; }

#app-container.menu-mobile .main-menu {
  transform: translateX(-90px); }

#app-container.menu-mobile .sub-menu {
  transform: translateX(-370px); }

#app-container.main-show-temporary .main-menu {
  transform: translateX(0); }

#app-container.main-show-temporary .sub-menu {
  transform: translateX(-230px); }

@media (max-width: 1439px) {
  #app-container.main-show-temporary .main-menu {
    transform: translateX(0); }
  #app-container.main-show-temporary .sub-menu {
    transform: translateX(-230px); } }

@media (max-width: 1199px) {
  #app-container.main-show-temporary .main-menu {
    transform: translateX(0); }
  #app-container.main-show-temporary .sub-menu {
    transform: translateX(-230px); } }

@media (max-width: 767px) {
  #app-container.main-show-temporary .sub-menu {
    transform: translateX(-230px); } }

#app-container.sub-show-temporary .sub-menu, #app-container.menu-mobile.sub-show-temporary .sub-menu, #app-container.menu-main-hidden.menu-mobile.main-show-temporary .sub-menu {
  transform: translateX(0); }

/* 12.Apps*/
.app-menu {
  z-index: 1;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  width: 280px;
  height: 100%;
  float: right;
  background: white;
  transition: transform 300ms;
  position: fixed;
  right: 0;
  top: 100px;
  height: calc(100% - 100px); }
  @media (max-width: 1439px) {
    .app-menu {
      top: 90px;
      height: calc(100% - 90px); } }
  @media (max-width: 1199px) {
    .app-menu {
      top: 80px;
      transform: translateX(280px);
      height: calc(100% - 80px); } }
  @media (max-width: 767px) {
    .app-menu {
      top: 70px;
      height: calc(100% - 70px); } }
  .app-menu .scroll {
    height: 100%; }
    .app-menu .scroll .ps__thumb-y {
      right: 0; }
  .app-menu.shown {
    transform: translateX(0); }
  .app-menu .app-menu-button {
    position: absolute;
    left: -28px;
    background: white;
    top: 45px;
    padding: 12px 8px 12px 5px;
    border-radius: 0.2rem;
    color: #8f8f8f;
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.04);
    font-size: 15px;
    line-height: 0; }
    @media (max-width: 1439px) {
      .app-menu .app-menu-button {
        top: 36px; } }
    @media (max-width: 1199px) {
      .app-menu .app-menu-button {
        top: 27px; } }
    @media (max-width: 767px) {
      .app-menu .app-menu-button {
        top: 13px; } }
  .app-menu ul li {
    margin-bottom: 5px; }
    .app-menu ul li a {
      font-size: 13px;
      display: block;
      padding: 3px 0; }
      .app-menu ul li a:hover i {
        color: #00365a; }
    .app-menu ul li i {
      font-size: 1.2em;
      margin-right: 10px;
      color: #8f8f8f;
      vertical-align: unset; }
      @media (max-width: 767px) {
        .app-menu ul li i {
          font-size: 20px; } }
    .app-menu ul li.active i,
    .app-menu ul li.active a {
      color: #00365a; }

.app-row {
  padding-right: 280px; }
  @media (max-width: 1199px) {
    .app-row {
      padding-right: 0; } }

/* 13.Chat App*/
.chat-app .scroll {
  padding-left: 10px;
  margin-left: -10px;
  padding-right: 10px;
  margin-right: -10px; }
  .chat-app .scroll .ps__thumb-y {
    right: 10px; }
  .chat-app .scroll > div > div.card:last-of-type {
    margin-bottom: 5px !important; }
  @media (max-width: 767px) {
    .chat-app .scroll > div > div.card {
      margin-right: initial !important; } }
  @media (max-width: 991px) {
    .chat-app .scroll > div > div.card {
      float: initial !important;
      clear: both !important; } }

@media (max-width: 767px) {
  .chat-app .list-item-heading {
    font-size: 0.9rem; } }

@media (max-width: 767px) {
  .chat-app .card .card-body {
    padding: 0.75rem; } }

.chat-app .chat-text-left {
  padding-left: 64px; }

.chat-app .chat-text-right {
  padding-right: 64px; }

@media (max-width: 767px) {
  .chat-heading .card-body {
    padding: 0.5rem !important; } }

.chat-input-container {
  width: 100%;
  height: 90px;
  background: white;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  transition: padding 300ms;
  padding-left: 410px;
  padding-right: 340px; }
  @media (max-width: 1439px) {
    .chat-input-container {
      padding-left: 160px;
      padding-right: 330px; } }
  @media (max-width: 1199px) {
    .chat-input-container {
      padding-left: 140px;
      padding-right: 40px; } }
  @media (max-width: 767px) {
    .chat-input-container {
      padding-right: 15px;
      padding-left: 15px; } }
  .chat-input-container input {
    border: initial;
    width: unset;
    height: 90px;
    padding-left: 0;
    display: inline-block; }
  @media (max-width: 991px) {
    .chat-input-container .send-button {
      padding-left: 0.7rem;
      padding-right: 0.7rem; } }
  @media (max-width: 991px) {
    .chat-input-container {
      height: 80px; }
      .chat-input-container input {
        height: 80px; }
      .chat-input-container .icon-button.large {
        width: 36px;
        height: 36px;
        font-size: 15px; } }
  @media (max-width: 767px) {
    .chat-input-container {
      height: 60px; }
      .chat-input-container input {
        height: 60px; }
      .chat-input-container .icon-button.large {
        width: 32px;
        height: 32px;
        font-size: 13px; } }

.menu-sub-hidden .chat-input-container,
.sub-hidden .chat-input-container {
  padding-left: 180px; }
  @media (max-width: 1439px) {
    .menu-sub-hidden .chat-input-container,
    .sub-hidden .chat-input-container {
      padding-left: 160px; } }
  @media (max-width: 1199px) {
    .menu-sub-hidden .chat-input-container,
    .sub-hidden .chat-input-container {
      padding-left: 140px; } }
  @media (max-width: 767px) {
    .menu-sub-hidden .chat-input-container,
    .sub-hidden .chat-input-container {
      padding-right: 15px;
      padding-left: 15px; } }

.menu-main-hidden .chat-input-container,
.menu-hidden .chat-input-container,
.main-hidden .chat-input-container {
  padding-left: 60px; }
  @media (max-width: 1439px) {
    .menu-main-hidden .chat-input-container,
    .menu-hidden .chat-input-container,
    .main-hidden .chat-input-container {
      padding-left: 50px; } }
  @media (max-width: 1199px) {
    .menu-main-hidden .chat-input-container,
    .menu-hidden .chat-input-container,
    .main-hidden .chat-input-container {
      padding-left: 40px; } }
  @media (max-width: 767px) {
    .menu-main-hidden .chat-input-container,
    .menu-hidden .chat-input-container,
    .main-hidden .chat-input-container {
      padding-right: 15px;
      padding-left: 15px; } }

/* 14.Survey App*/
.survey-app .answers .badge {
  color: #8f8f8f; }

.survey-app .answers input {
  padding-right: 70px; }

.survey-app .question.edit-quesiton .edit-mode {
  display: block; }

.survey-app .question.edit-quesiton .view-mode {
  display: none; }

.survey-app .question.edit-quesiton .view-button {
  display: inline-block; }

.survey-app .question.edit-quesiton .edit-button {
  display: none; }

.survey-app .question.view-quesiton .edit-mode {
  display: none; }

.survey-app .question.view-quesiton .view-mode {
  display: block; }

.survey-app .question.view-quesiton .view-button {
  display: none; }

.survey-app .question.view-quesiton .edit-button {
  display: inline-block; }

.survey-app .survey-question-types .btn-group-icon {
  font-size: 21px;
  line-height: 28px; }

.survey-app .survey-question-types .btn {
  padding-bottom: 0.3rem; }

.input-icons {
  position: absolute;
  right: 0;
  top: 9px; }

/* 15.Todo App*/
.heading-icon {
  color: #00365a;
  font-size: 20px;
  line-height: inherit;
  margin-right: 5px;
  vertical-align: middle; }

.heading-number {
  border: 1px solid #00365a;
  padding: 4px;
  vertical-align: middle;
  margin-right: 10px;
  border-radius: 20px;
  width: 34px;
  height: 34px;
  text-align: center;
  color: #00365a; }

/* 16.Main*/
main {
  margin-left: 410px;
  margin-top: 145px;
  margin-right: 60px;
  margin-bottom: 40px; }
  main.default-transition {
    transition: margin-left 300ms; }
  main .container-fluid {
    padding-left: 0;
    padding-right: 0; }
  @media (max-width: 1439px) {
    main {
      margin-left: 390px;
      margin-right: 50px;
      margin-top: 130px;
      margin-bottom: 30px; } }
  @media (max-width: 1199px) {
    main {
      margin-left: 370px;
      margin-right: 40px;
      margin-top: 110px;
      margin-bottom: 20px; } }
  @media (max-width: 767px) {
    main {
      margin-left: 15px !important;
      margin-right: 15px !important;
      margin-top: 80px !important;
      margin-bottom: 0; } }
  @media (max-width: 575px) {
    main {
      margin-bottom: 0; } }

#app-container.sub-hidden main,
#app-container.menu-sub-hidden main,
#app-container.menu-hidden main {
  margin-left: 180px; }

#app-container.main-hidden main,
#app-container.menu-hidden main {
  margin-left: 60px; }

#app-container.menu-main-hidden main {
  margin-left: 290px; }

#app-container.menu-main-hidden.menu-hidden main {
  margin-left: 60px; }

@media (max-width: 1439px) {
  #app-container.sub-hidden main,
  #app-container.menu-sub-hidden main,
  #app-container.menu-hidden main {
    margin-left: 160px; }
  #app-container.main-hidden main,
  #app-container.menu-hidden main {
    margin-left: 50px; }
  #app-container.menu-main-hidden main {
    margin-left: 280px; }
  #app-container.menu-main-hidden.menu-hidden main {
    margin-left: 50px; } }

@media (max-width: 1199px) {
  #app-container.sub-hidden main,
  #app-container.menu-sub-hidden main,
  #app-container.menu-hidden main {
    margin-left: 140px; }
  #app-container.main-hidden main,
  #app-container.menu-hidden main {
    margin-left: 40px; }
  #app-container.menu-main-hidden main {
    margin-left: 270px; }
  #app-container.menu-main-hidden.menu-hidden main {
    margin-left: 40px; } }

/* 17.Cards*/
.card {
  border: initial;
  background: white;
  border-radius: 0.1rem;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04); }
  .card .card-header .card-icon {
    right: 5px;
    top: 5px; }
    .card .card-header .card-icon i {
      font-size: 12px;
      color: #d7d7d7; }
  .card .card-header .handle {
    cursor: default; }
  .card .card-body {
    padding: 1.75rem; }
    @media (max-width: 575px) {
      .card .card-body {
        padding: 1.5rem; } }
    .card .card-body.sm {
      padding: 1.25rem 1.75rem; }
  .card .card-title {
    margin-bottom: 2rem; }

.card-img {
  border-radius: calc(0.25rem - 1px);
  height: 100%;
  object-fit: cover;
  max-height: 200px;
  width: unset; }

.card-img-fluid {
  border-radius: calc(0.25rem - 1px);
  object-fit: cover; }

.card-img-bottom {
  width: 100%;
  border-bottom-left-radius: 0.1rem;
  border-bottom-right-radius: 0.1rem; }

.card-img-top {
  width: 100%;
  border-top-left-radius: 0.1rem;
  border-top-right-radius: 0.1rem; }

.card-img-left {
  width: 100%;
  border-top-left-radius: 0.1rem;
  border-bottom-left-radius: 0.1rem;
  height: 100%;
  object-fit: cover; }

.card-img-right {
  width: 100%;
  border-top-right-radius: 0.1rem;
  border-bottom-right-radius: 0.1rem;
  height: 100%;
  object-fit: cover; }

.card-img-overlay {
  background: rgba(0, 0, 0, 0.5);
  padding: 1.75rem; }
  @media (max-width: 575px) {
    .card-img-overlay {
      padding: 1rem; } }

.card-top-buttons {
  padding: 1.3rem;
  right: 0;
  top: 0; }
  @media (max-width: 575px) {
    .card-top-buttons {
      padding: 0.35rem; } }

.card-header {
  border: initial;
  background: initial;
  padding-top: 0; }

.img-thumbnail {
  border-radius: 0.1rem;
  padding: 0;
  border: initial; }

.list-thumbnail {
  border-radius: 0.1rem;
  padding: 0;
  border: initial;
  height: auto;
  max-width: unset;
  height: 85px;
  object-fit: cover;
  width: unset; }
  @media (max-width: 991px) {
    .list-thumbnail {
      height: 80px; } }
  @media (max-width: 575px) {
    .list-thumbnail {
      height: 70px; } }
  @media (max-width: 991px) {
    .list-thumbnail.responsive {
      width: unset;
      height: 135px; } }
  @media (max-width: 575px) {
    .list-thumbnail.responsive {
      width: 110px !important;
      height: 100%; } }
  @media (max-width: 419px) {
    .list-thumbnail.responsive {
      width: 90px !important;
      height: 100%; } }
  .list-thumbnail.small {
    height: 60px;
    font-size: 1rem; }
    @media (max-width: 991px) {
      .list-thumbnail.small {
        height: 55px; } }
    @media (max-width: 575px) {
      .list-thumbnail.small {
        height: 50px; } }
  .list-thumbnail.xsmall {
    height: 40px;
    font-size: 1rem; }
    @media (max-width: 991px) {
      .list-thumbnail.xsmall {
        height: 40px; } }
    @media (max-width: 575px) {
      .list-thumbnail.xsmall {
        height: 40px; } }

.list-thumbnail-letters {
  width: 85px;
  height: 85px;
  background: #00365a;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 1.25rem;
  color: #fff; }
  @media (max-width: 991px) {
    .list-thumbnail-letters {
      width: 80px;
      height: 80px; } }
  @media (max-width: 575px) {
    .list-thumbnail-letters {
      width: 70px;
      height: 70px; } }
  .list-thumbnail-letters.small {
    width: 60px;
    height: 60px;
    font-size: 1rem; }
    @media (max-width: 991px) {
      .list-thumbnail-letters.small {
        width: 55px;
        height: 55px; } }
    @media (max-width: 575px) {
      .list-thumbnail-letters.small {
        width: 50px;
        height: 50px; } }

/* 18.Dashboard*/
.dashboard-line-chart {
  height: 283px; }

.dashboard-quick-post {
  min-height: 283px; }

.dashboard-list-with-thumbs {
  height: 500px; }

.dashboard-logs {
  height: 270px; }

.dashboard-list-with-user {
  height: 270px; }

.dashboard-donut-chart {
  height: 270px; }

.dashboard-small-chart {
  height: 150px; }
  .dashboard-small-chart .chart {
    height: 60px; }
  .dashboard-small-chart .lead {
    font-size: 1.4rem; }

.dashboard-small-chart-analytics {
  height: 180px; }
  .dashboard-small-chart-analytics .chart {
    height: 80px; }
  .dashboard-small-chart-analytics .lead {
    font-size: 1.4rem; }

.dashboard-filled-line-chart {
  height: 340px; }
  .dashboard-filled-line-chart .chart {
    height: 200px; }

.dashboard-numbers .owl-nav,
.dashboard-numbers .owl-dots {
  display: none; }

.dashboard-sq-banner {
  background-image: linear-gradient(to right top, #00365a, #00538a, #015994);
  background-size: cover;
  height: 385px;
  transition: 0.5s;
  background-size: 350% auto;
  cursor: pointer; }
  .dashboard-sq-banner .card-body {
    width: 270px; }
  .dashboard-sq-banner .lead {
    line-height: 2.3rem; }
  .dashboard-sq-banner:hover {
    background-position: right top; }

.dashboard-link-list {
  height: 385px; }

.dashboard-progress {
  height: 385px; }

.dashboard-top-rated {
  height: 300px; }

.dashboard-top-rated {
  height: 300px; }
  @media (max-width: 991px) {
    .dashboard-top-rated {
      height: unset; } }
  .dashboard-top-rated .glide img {
    height: 120px;
    display: flex;
    object-fit: cover;
    width: 100%; }
  .dashboard-top-rated .glide .react-rater {
    display: inline-block !important; }
  .dashboard-top-rated .glide__slides .glide__slide {
    padding: initial;
    display: initial; }

.dashboard-search {
  height: 650px;
  /* background: url("../img/login/plane.jpg"); */
  background-size: contain;
  background-repeat: no-repeat;
  background-color: white; }
  .dashboard-search .card-body {
    padding: 120px 50px 30px 50px; }
    @media (max-width: 1439px) {
      .dashboard-search .card-body {
        padding: 80px 30px 30px 30px; } }
  .dashboard-search .form-container {
    height: 400px;
    border-radius: 0.1rem;
    box-shadow: 0px -10px 15px 0px rgba(0, 0, 0, 0.04);
    padding: 2rem;
    background-color: white; }

.icon-cards-row {
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -10px; }
  .icon-cards-row .glide__track {
    margin-left: -3px;
    margin-right: -3px; }
  .icon-cards-row .glide__slides {
    padding-top: 10px;
    padding-left: 3px;
    padding-right: 3px; }
  .icon-cards-row .glide__slides .glide__slide {
    padding-left: 5px;
    padding-right: 5px; }
  .icon-cards-row .card-body {
    padding: 2rem 0.5rem; }
  .icon-cards-row .card-text {
    color: #8f8f8f;
    height: 30px;
    line-height: 26px; }
  .icon-cards-row .lead {
    color: #00365a;
    margin-bottom: 0; }
  .icon-cards-row i {
    font-size: 38px;
    line-height: 66px;
    color: #00365a; }
  .icon-cards-row .card {
    transition: box-shadow 1000ms;
    cursor: pointer; }
  @media (max-width: 1439px) {
    .icon-cards-row .card-text {
      height: 48px;
      line-height: 18px; }
    .icon-cards-row .lead {
      font-size: 1.6rem; }
    .icon-cards-row i {
      font-size: 32px;
      line-height: 47px; } }

.log-indicator {
  width: 13px;
  height: 13px;
  border: 2px solid #00365a;
  border-radius: 14px;
  display: inline-block; }

/* 19.Calendar */
.calendar .alert-info {
  color: #00365a;
  background-color: initial; }

.calendar h2 {
  font-size: 14px;
  margin-top: 10px;
  display: inline-block; }

.fc-basic-view .fc-body .fc-row {
  min-height: 6em; }
  @media (max-width: 575px) {
    .fc-basic-view .fc-body .fc-row {
      min-height: 3em; } }

.fc-toolbar.fc-header-toolbar .btn-group .fc-next-button,
.fc-toolbar.fc-header-toolbar .btn-group .fc-prev-button {
  outline: initial !important;
  box-shadow: initial !important;
  border-radius: 40px !important;
  text-align: center;
  min-width: 30px;
  height: 30px;
  padding: 0.55rem 0;
  background: #00365a;
  color: #fff;
  border: 1px solid #00365a;
  line-height: 0.9 !important;
  font-size: 0.76rem;
  font-weight: normal !important; }
  .fc-toolbar.fc-header-toolbar .btn-group .fc-next-button span,
  .fc-toolbar.fc-header-toolbar .btn-group .fc-prev-button span {
    line-height: 0.9 !important;
    font-size: 0.76rem;
    font-weight: normal !important; }
  .fc-toolbar.fc-header-toolbar .btn-group .fc-next-button:hover,
  .fc-toolbar.fc-header-toolbar .btn-group .fc-prev-button:hover {
    background-color: transparent;
    border-color: rgba(0, 54, 90, 0.9);
    color: #00365a; }

.fc-toolbar.fc-header-toolbar .fc-prev-button {
  margin-right: 5px; }

.fc-today-button {
  padding: 0.4em 1.3em !important;
  height: unset !important; }

.fc-bootstrap4 .fc-day-top .fc-day-number {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  text-align: center;
  vertical-align: middle;
  padding: 5px; }

.fc-bootstrap4 td.fc-today {
  background: initial; }
  .fc-bootstrap4 td.fc-today .fc-day-number {
    background: #00365a;
    color: #fff; }

.fc-day-grid-event .fc-content {
  color: #fff; }

.fc-day-grid-container {
  height: 100%;
  overflow: visible; }

.fc-row .fc-content-skeleton td,
.fc-row .fc-helper-skeleton td {
  padding: 5px; }

.fc-view,
.fc-view > table {
  border-top: initial; }

.fc table {
  border: initial; }

.fc .table-bordered thead th,
.fc .table-bordered thead td {
  border-top: initial;
  border-bottom: initial;
  border-left: initial; }

.fc td:first-of-type,
.fc th:first-of-type {
  border-left: initial;
  border-bottom: initial; }

.fc td:last-of-type,
.fc th:last-of-type {
  border-right: initial;
  border-bottom: initial; }

.fc-body .fc-row:last-of-type td {
  border-bottom: initial; }

.fc tbody > tr > td.fc-widget-content:first-of-type {
  border-left: initial;
  border-right: initial;
  border-bottom: initial; }

.fc table,
.fc .table-bordered th,
.fc .table-bordered td {
  border-color: #f3f3f3 !important; }

.fc-day-header {
  color: #00365a; }
  .fc-day-header span {
    padding: 10px;
    display: inline-block; }

.fc-event {
  border: initial; }

.fc-event,
.fc-event-dot {
  background-color: #184f90;
  color: white !important;
  padding: 1px 6px;
  border-radius: 10px;
  cursor: pointer;
  text-align: center; }

/* 20.Datatable */
.dataTables_wrapper {
  overflow: hidden; }

table.dataTable td {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #f3f3f3;
  outline: initial !important; }

table.dataTable tr:last-of-type td {
  border-bottom: initial; }

table.dataTable {
  width: 100% !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

table p,
table h6 {
  margin-bottom: initial; }

table.dataTable thead > tr > th.sorting_asc,
table.dataTable thead > tr > th.sorting_desc,
table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting {
  padding-top: 10px;
  padding-bottom: 10px; }

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  right: 1.5em; }

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  right: 2em; }

.dataTables_wrapper .paginate_button.previous {
  margin-right: 15px; }

.dataTables_wrapper .paginate_button.next {
  margin-left: 15px; }

div.dataTables_wrapper div.dataTables_paginate {
  margin-top: 25px; }

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  justify-content: center; }

.dataTables_wrapper .paginate_button.page-item {
  padding-left: 10px;
  padding-right: 10px; }

table.dataTable.dtr-inline.collapsed
> tbody
> tr[role="row"]
> td:first-child:before,
table.dataTable.dtr-inline.collapsed
> tbody
> tr[role="row"]
> th:first-child:before {
  top: unset;
  box-shadow: initial;
  background-color: #00365a;
  font-size: 12px; }

.data-table-rows .dataTables_wrapper {
  overflow: initial; }

.data-table-rows table {
  border-spacing: 0px 1rem; }
  .data-table-rows table tbody tr {
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04); }
  .data-table-rows table tbody tr.selected {
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1); }
  .data-table-rows table tbody tr.child td {
    padding: 0.75rem 2rem; }
    .data-table-rows table tbody tr.child td li {
      padding: 0 !important; }
  .data-table-rows table td,
  .data-table-rows table th {
    padding: 1.5rem;
    border: initial; }
  .data-table-rows table td {
    background: white; }
  .data-table-rows table th.empty:before, .data-table-rows table th.empty:after {
    content: ""; }
  .data-table-rows table .data-table-rows-check {
    text-align: right;
    pointer-events: none; }

.data-table-rows table.dataTable thead .sorting:before,
.data-table-rows table.dataTable thead .sorting_asc:before,
.data-table-rows table.dataTable thead .sorting_asc_disabled:before,
.data-table-rows table.dataTable thead .sorting_desc:before,
.data-table-rows table.dataTable thead .sorting_desc_disabled:before {
  right: initial;
  left: 0.5em; }

.data-table-rows table.dataTable thead .sorting:after,
.data-table-rows table.dataTable thead .sorting_asc:after,
.data-table-rows table.dataTable thead .sorting_asc_disabled:after,
.data-table-rows table.dataTable thead .sorting_desc:after,
.data-table-rows table.dataTable thead .sorting_desc_disabled:after {
  right: initial;
  left: 0; }

.data-tables-hide-filter .view-filter {
  display: none; }

/* 21.Alerts */
.alert {
  border-radius: 0; }

.alert-primary {
  color: #00365a;
  background-color: #c6d1d8;
  border-color: rgba(0, 54, 90, 0.1); }

.alert-secondary {
  color: #184f90;
  background-color: #cbd6e3;
  border-color: rgba(24, 79, 144, 0.1); }

.alert-success {
  color: #3e884f;
  background-color: #d3e2d6;
  border-color: rgba(62, 136, 79, 0.1); }

.alert-info {
  color: #3195a5;
  background-color: #d0e4e7;
  border-color: rgba(49, 149, 165, 0.1); }

.alert-warning {
  color: #b69329;
  background-color: #ebe4cf;
  border-color: rgba(182, 147, 41, 0.1); }

.alert-danger {
  color: #c43d4b;
  background-color: #eed3d5;
  border-color: rgba(196, 61, 75, 0.1); }

.alert-light {
  color: #ececec;
  background-color: #f6f6f6;
  border-color: rgba(236, 236, 236, 0.1); }

.alert-dark {
  color: #131113;
  background-color: #cacaca;
  border-color: rgba(19, 17, 19, 0.1); }

.alert-dismissible .close,
button.close {
  text-shadow: initial; }

.alert *[data-notify="title"] {
  display: block;
  font-size: 0.9rem; }

div[data-notify="container"] {
  padding: 18px; }

/* 22.Forms */
.form-check-label,
.custom-control-label {
  line-height: 1.5rem;
  padding-top: 1px; }

.form-control,
.bootstrap-tagsinput {
  border-radius: 0.1rem;
  outline: initial !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  padding: 0.5rem 0.75rem;
  line-height: 1;
  border: 1px solid #d7d7d7;
  background: white;
  color: #3a3a3a;
  border-color: #d7d7d7;
  height: calc(2em + 0.8rem); }

.form-control-sm {
  padding: 0.5rem;
  font-size: 0.75rem;
  height: auto !important; }

.form-control-lg {
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  height: auto !important; }

select {
  outline: initial !important;
  box-shadow: initial !important; }

textarea.form-control {
  line-height: 1.5; }

@keyframes autofill {
  to {
    color: #3a3a3a;
    background: transparent; } }

input:-webkit-autofill {
  animation-name: autofill;
  animation-fill-mode: both; }

input:-webkit-autofill {
  -webkit-text-fill-color: #3a3a3a !important; }

.bootstrap-tagsinput input {
  color: #3a3a3a; }

.has-float-label label::after,
.has-float-label > span::after,
.has-float-label > span::after {
  background: white; }

.has-float-label > div.error::after {
  background: initial; }

.form-control:focus {
  background: white;
  color: #3a3a3a; }

.bootstrap-tagsinput {
  width: 100%; }

.bootstrap-tagsinput input {
  padding: 0; }

.bootstrap-tagsinput .tag [data-role="remove"] {
  margin-top: -1px;
  display: inline-block;
  vertical-align: top; }

.bootstrap-tagsinput .tag [data-role="remove"]:hover {
  box-shadow: initial; }

.bootstrap-tagsinput .tag {
  background: #00365a;
  border-radius: 15px;
  padding: 3px 8px;
  margin-bottom: 0px;
  display: inline-block;
  font-size: 12px; }

.form-control:focus,
.bootstrap-tagsinput.focus {
  border-color: rgba(0, 54, 90, 0.6); }

.bootstrap-tagsinput {
  min-height: calc(2em + 0.6rem);
  height: auto; }

.bootstrap-tagsinput .tag {
  margin-bottom: 0.1rem;
  margin-top: 0.1rem; }

.bootstrap-tagsinput input {
  margin-bottom: 0.1rem;
  margin-top: 0.1rem; }

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: rgba(58, 58, 58, 0.25); }

.custom-control-input:active ~ .custom-control-label::before {
  background-color: transparent; }

.custom-checkbox .custom-control-label.indeterminate::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23FFFFFF' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23FFFFFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23FFFFFF'/%3E%3C/svg%3E"); }

.custom-control-label,
.custom-control-input {
  outline: initial !important;
  box-shadow: initial !important; }

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
  background-image: initial; }

.custom-control-input {
  left: 1px;
  top: 3px;
  opacity: 0;
  z-index: 1;
  pointer-events: none; }

.custom-control-label::before {
  border: 1px solid #909090 !important;
  background: initial !important; }

.custom-checkbox
.custom-control-input:indeterminate
~ .custom-control-label::before {
  background-color: #00365a !important; }

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #00365a !important;
  box-shadow: initial !important;
  border: 1px solid #00365a !important; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #00365a !important;
  box-shadow: initial !important; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #00365a !important;
  box-shadow: initial !important; }

.custom-control-label::after,
.custom-control-label::before {
  box-shadow: initial !important; }

.custom-control-label::before {
  top: 0.25rem; }

.custom-control-label::after {
  top: 0.25rem; }

.btn.rotate-icon-click i {
  transition: transform 0.5s; }

.btn.rotate i {
  transform: rotate(180deg); }

.btn .custom-control-label::before {
  border: 1px solid #fff !important;
  background: transparent !important; }

.btn .custom-control-input:checked ~ .custom-control-label::before {
  border: 1px solid #fff !important;
  background: transparent !important; }

.btn-group-icon {
  line-height: 22px; }

.invalid-tooltip,
.valid-tooltip,
div.error {
  border-radius: 0.1rem;
  padding: 0.5rem 1rem;
  font-size: 0.76rem;
  color: #3a3a3a;
  background: white;
  border: 1px solid #00365a;
  text-align: center;
  width: unset !important;
  position: absolute;
  z-index: 4;
  margin-top: -0.5rem;
  left: 50%;
  transform: translateX(-50%);
  line-height: 1.5;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04); }
  .invalid-tooltip::before,
  .valid-tooltip::before,
  div.error::before {
    content: "";
    position: absolute;
    top: -5px;
    left: -2.5px;
    margin-left: 50%;
    width: 10px;
    height: 5px;
    border-bottom: solid 5px #00365a;
    border-left: solid 5px transparent;
    border-right: solid 5px transparent; }
  .invalid-tooltip::after,
  .valid-tooltip::after,
  div.error::after {
    content: "";
    position: absolute;
    top: -4px;
    left: -2.5px;
    margin-left: 50%;
    width: 10px;
    height: 5px;
    border-bottom: solid 5px white;
    border-left: solid 5px transparent;
    border-right: solid 5px transparent; }

.tooltip-right-bottom .invalid-tooltip,
.tooltip-right-bottom .valid-tooltip,
.tooltip-right-bottom div.error {
  left: initial;
  right: 0;
  transform: translateX(0);
  top: 80%; }
  .tooltip-right-bottom .invalid-tooltip::before, .tooltip-right-bottom .invalid-tooltip::after,
  .tooltip-right-bottom .valid-tooltip::before,
  .tooltip-right-bottom .valid-tooltip::after,
  .tooltip-right-bottom div.error::before,
  .tooltip-right-bottom div.error::after {
    left: initial;
    right: 25px;
    margin-left: 0; }

.tooltip-center-bottom .invalid-tooltip,
.tooltip-center-bottom .valid-tooltip,
.tooltip-center-bottom div.error {
  left: 50%;
  right: initial;
  transform: translateX(-50%) translateY(50%);
  top: 80%; }

.tooltip-left-bottom .invalid-tooltip,
.tooltip-left-bottom .valid-tooltip,
.tooltip-left-bottom div.error {
  left: 0;
  right: initial;
  transform: translateX(0);
  top: 80%; }
  .tooltip-left-bottom .invalid-tooltip::before, .tooltip-left-bottom .invalid-tooltip::after,
  .tooltip-left-bottom .valid-tooltip::before,
  .tooltip-left-bottom .valid-tooltip::after,
  .tooltip-left-bottom div.error::before,
  .tooltip-left-bottom div.error::after {
    left: 25px;
    right: initial;
    margin-left: 0; }

.tooltip-center-top .invalid-tooltip,
.tooltip-center-top .valid-tooltip,
.tooltip-center-top div.error {
  bottom: 80%;
  transform: translateX(-50%) translateY(50%);
  top: initial; }
  .tooltip-center-top .invalid-tooltip::before,
  .tooltip-center-top .valid-tooltip::before,
  .tooltip-center-top div.error::before {
    content: "";
    position: absolute;
    top: initial;
    bottom: -5px;
    border-top: solid 5px #00365a;
    border-bottom: initial; }
  .tooltip-center-top .invalid-tooltip::after,
  .tooltip-center-top .valid-tooltip::after,
  .tooltip-center-top div.error::after {
    content: "";
    position: absolute;
    top: initial;
    bottom: -4px;
    border-top: solid 5px white;
    border-bottom: initial; }

.tooltip-right-top .invalid-tooltip,
.tooltip-right-top .valid-tooltip,
.tooltip-right-top div.error {
  bottom: 80%;
  transform: translateX(0) translateY(50%);
  top: initial;
  left: initial;
  right: 0; }
  .tooltip-right-top .invalid-tooltip::before,
  .tooltip-right-top .valid-tooltip::before,
  .tooltip-right-top div.error::before {
    content: "";
    position: absolute;
    top: initial;
    bottom: -5px;
    border-top: solid 5px #00365a;
    border-bottom: initial;
    left: initial;
    right: 25px;
    margin-left: 0; }
  .tooltip-right-top .invalid-tooltip::after,
  .tooltip-right-top .valid-tooltip::after,
  .tooltip-right-top div.error::after {
    content: "";
    position: absolute;
    top: initial;
    bottom: -4px;
    border-top: solid 5px white;
    border-bottom: initial;
    left: initial;
    right: 25px;
    margin-left: 0; }

.tooltip-left-top .invalid-tooltip,
.tooltip-left-top .valid-tooltip,
.tooltip-left-top div.error {
  bottom: 80%;
  transform: translateX(0) translateY(50%);
  top: initial;
  left: 0;
  right: initial; }
  .tooltip-left-top .invalid-tooltip::before,
  .tooltip-left-top .valid-tooltip::before,
  .tooltip-left-top div.error::before {
    content: "";
    position: absolute;
    top: initial;
    bottom: -5px;
    border-top: solid 5px #00365a;
    border-bottom: initial;
    left: 25px;
    right: initial;
    margin-left: 0; }
  .tooltip-left-top .invalid-tooltip::after,
  .tooltip-left-top .valid-tooltip::after,
  .tooltip-left-top div.error::after {
    content: "";
    position: absolute;
    top: initial;
    bottom: -4px;
    border-top: solid 5px white;
    border-bottom: initial;
    left: 25px;
    right: initial;
    margin-left: 0; }

.tooltip-label-right .invalid-tooltip,
.tooltip-label-right .valid-tooltip,
.tooltip-label-right div.error {
  transform: translateX(0) translateY(-50%);
  top: 16px;
  left: 50px; }
  .tooltip-label-right .invalid-tooltip::before,
  .tooltip-label-right .valid-tooltip::before,
  .tooltip-label-right div.error::before {
    content: "";
    position: absolute;
    left: -5px;
    right: initial;
    margin-left: 0;
    border: initial;
    border-top: solid 5px transparent;
    border-bottom: solid 5px transparent;
    border-right: solid 5px #00365a;
    bottom: initial;
    top: 12px;
    width: 5px; }
  .tooltip-label-right .invalid-tooltip::after,
  .tooltip-label-right .valid-tooltip::after,
  .tooltip-label-right div.error::after {
    content: "";
    position: absolute;
    top: initial;
    left: -4px;
    right: initial;
    margin-left: 0;
    width: 5px;
    border: initial;
    border-top: solid 5px transparent;
    border-bottom: solid 5px transparent;
    border-right: solid 5px white;
    bottom: initial;
    top: 12px;
    width: 5px; }

.tooltip-label-right .error-l-0 .invalid-tooltip,
.tooltip-label-right .error-l-0 .valid-tooltip,
.tooltip-label-right .error-l-0 div.error {
  left: 0px; }

.tooltip-label-right .error-l-25 .invalid-tooltip,
.tooltip-label-right .error-l-25 .valid-tooltip,
.tooltip-label-right .error-l-25 div.error {
  left: 25px; }

.tooltip-label-right .error-l-50 .invalid-tooltip,
.tooltip-label-right .error-l-50 .valid-tooltip,
.tooltip-label-right .error-l-50 div.error {
  left: 50px; }

.tooltip-label-right .error-l-75 .invalid-tooltip,
.tooltip-label-right .error-l-75 .valid-tooltip,
.tooltip-label-right .error-l-75 div.error {
  left: 75px; }

.tooltip-label-right .error-l-100 .invalid-tooltip,
.tooltip-label-right .error-l-100 .valid-tooltip,
.tooltip-label-right .error-l-100 div.error {
  left: 100px; }

.tooltip-label-right .error-l-125 .invalid-tooltip,
.tooltip-label-right .error-l-125 .valid-tooltip,
.tooltip-label-right .error-l-125 div.error {
  left: 125px; }

.tooltip-label-right .error-l-150 .invalid-tooltip,
.tooltip-label-right .error-l-150 .valid-tooltip,
.tooltip-label-right .error-l-150 div.error {
  left: 150px; }

.tooltip-label-right .error-l-175 .invalid-tooltip,
.tooltip-label-right .error-l-175 .valid-tooltip,
.tooltip-label-right .error-l-175 div.error {
  left: 175px; }

.tooltip-label-right .error-l-200 .invalid-tooltip,
.tooltip-label-right .error-l-200 .valid-tooltip,
.tooltip-label-right .error-l-200 div.error {
  left: 200px; }

.tooltip-label-right .error-l-225 .invalid-tooltip,
.tooltip-label-right .error-l-225 .valid-tooltip,
.tooltip-label-right .error-l-225 div.error {
  left: 225px; }

.tooltip-label-right .error-l-250 .invalid-tooltip,
.tooltip-label-right .error-l-250 .valid-tooltip,
.tooltip-label-right .error-l-250 div.error {
  left: 250px; }

.tooltip-label-right .error-l-275 .invalid-tooltip,
.tooltip-label-right .error-l-275 .valid-tooltip,
.tooltip-label-right .error-l-275 div.error {
  left: 275px; }

.tooltip-label-right .error-t-negative .invalid-tooltip,
.tooltip-label-right .error-t-negative .valid-tooltip,
.tooltip-label-right .error-t-negative div.error {
  top: -10px; }

.form-group.is-invalid .invalid-tooltip {
  display: block; }

.valid-icon {
  position: absolute;
  bottom: 8px;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0;
  font-size: 0.76rem;
  line-height: 1;
  color: white;
  border-radius: 0.2rem;
  right: 4px;
  color: #3e884f; }

.invalid-icon {
  position: absolute;
  bottom: 2px;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0;
  font-size: 0.875rem;
  line-height: 1;
  color: white;
  border-radius: 0.2rem;
  right: 4px;
  color: #c43d4b; }

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #d7d7d7 !important; }

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #d7d7d7 !important; }

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: rgba(0, 54, 90, 0.6); }

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: rgba(0, 54, 90, 0.6); }

.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  background: initial !important; }

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #3a3a3a; }

.was-validated
.custom-control-input:valid:checked
~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #00365a !important; }

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #3a3a3a; }

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  background: initial !important; }

.select2-container--bootstrap .select2-selection--single {
  border-radius: 0.1rem !important;
  font-size: 0.8rem;
  padding: 0.5rem 0.75rem;
  line-height: 1;
  height: calc(2em + 0.8rem); }

.select2-dropdown,
.select2-container--bootstrap .select2-selection.form-control {
  background: white; }

.select2-container--bootstrap .select2-results__option[aria-selected="true"] {
  background: white;
  color: #3a3a3a; }

.select2-container--bootstrap
.select2-selection--single
.select2-selection__rendered {
  color: #3a3a3a;
  padding-top: 5px; }

.input-group-text {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: #d7d7d7;
  color: #3a3a3a;
  font-size: 0.8rem;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  line-height: 1 !important; }

.select2-container--bootstrap .select2-selection {
  border: 1px solid #d7d7d7; }

.select2-container--bootstrap .select2-selection--multiple {
  height: calc(2em + 0.8rem) !important;
  font-size: 0.8rem;
  padding: 0.5rem 75rem;
  min-height: initial !important;
  padding: 0.5rem 0.25rem; }

.select2-container--bootstrap
.select2-selection--multiple
.select2-search--inline
.select2-search__field {
  min-height: initial !important;
  height: initial; }

.select2-container--bootstrap.select2-container--focus .select2-selection,
.select2-container--bootstrap.select2-container--open .select2-selection {
  border-color: rgba(0, 54, 90, 0.7); }

.select2-container--bootstrap .select2-dropdown {
  border-color: rgba(0, 54, 90, 0.7); }

.select2-container--bootstrap
.select2-results__option--highlighted[aria-selected] {
  background-color: #00365a;
  color: #fff; }

.select2-dropdown,
.select2-container--bootstrap .select2-selection.form-control {
  border-radius: 0.1rem !important; }

.select2-container--bootstrap .select2-search--dropdown .select2-search__field {
  border-radius: 0.1rem !important;
  border: 1px solid #8f8f8f;
  outline: initial;
  background: white;
  color: #3a3a3a; }

.select2-container--bootstrap
.select2-selection--multiple
.select2-search--inline
.select2-search__field {
  outline: initial;
  background: white;
  color: #3a3a3a; }

.select2-container--bootstrap
.select2-selection--multiple
.select2-selection__choice {
  border-radius: 0.1rem;
  background: white;
  color: #3a3a3a;
  border-color: #d7d7d7;
  margin: 0 0 0 8px; }

.select2-container--bootstrap
.select2-selection--multiple
.select2-selection__choice__remove:hover {
  outline: initial;
  text-shadow: initial;
  color: #00365a; }

.typeahead.dropdown-menu {
  width: 100%;
  border-radius: 0.1rem !important; }
  .typeahead.dropdown-menu .dropdown-item {
    padding: 0.5rem 0.75rem !important; }
  .typeahead.dropdown-menu .active a {
    background-color: #00365a;
    color: #fff; }

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 0;
  padding-right: 10px;
  height: calc(2em); }

.select2-container--bootstrap
.select2-selection--multiple
.select2-search--inline
.select2-search__field {
  padding-top: 3px;
  padding-left: 8px; }

.select2-container--bootstrap
.select2-selection--multiple
.select2-selection__choice {
  margin: 2px 0 2px 5px; }

.select2-container--bootstrap .select2-selection--multiple {
  height: unset !important;
  padding: 0.4rem 0.25rem; }

.datepicker-dropdown {
  transform: translateY(-150px); }
  @media (max-width: 1439px) {
    .datepicker-dropdown {
      transform: translateY(-130px); } }
  @media (max-width: 1199px) {
    .datepicker-dropdown {
      transform: translateY(-110px); } }
  @media (max-width: 767px) {
    .datepicker-dropdown {
      transform: translateY(-85px); } }

.datepicker-dropdown:before {
  border-bottom-color: rgba(58, 58, 58, 0.2); }

.datepicker table tr td.range {
  color: #fff;
  background-color: #00365a;
  border-color: #00365a; }

.datepicker table tr td.selected,
.datepicker table tr td.selected.highlighted {
  color: #fff;
  background-color: #00365a;
  border-color: #00365a; }

.datepicker-dropdown.datepicker-orient-top:before {
  border-top: 7px solid rgba(58, 58, 58, 0.2); }

.datepicker table tr td,
.datepicker table tr th {
  color: #3a3a3a; }

.datepicker-dropdown.datepicker-orient-top:after {
  border-top-color: white; }

.datepicker-dropdown.datepicker-orient-bottom:after {
  border-bottom-color: white; }

.datepicker table tr td.day:hover,
.datepicker table tr td.focused {
  background: #d7d7d7; }

.datepicker table tr td,
.datepicker table tr th {
  width: 35px;
  height: 35px;
  border-radius: 0.1rem; }

.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active,
.datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active:active {
  color: white;
  background-color: #00365a;
  border-color: #00365a; }

.datepicker table tr td.active.active.focus,
.datepicker table tr td.active.active:focus,
.datepicker table tr td.active.active:hover,
.datepicker table tr td.active.highlighted.active.focus,
.datepicker table tr td.active.highlighted.active:focus,
.datepicker table tr td.active.highlighted.active:hover,
.datepicker table tr td.active.highlighted:active.focus,
.datepicker table tr td.active.highlighted:active:focus,
.datepicker table tr td.active.highlighted:active:hover,
.datepicker table tr td.active:active.focus,
.datepicker table tr td.active:active:focus,
.datepicker table tr td.active:active:hover {
  color: white;
  background-color: #001727;
  border-color: #001727; }

.datepicker table tr td span.active.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active:hover:active {
  background-color: #001727;
  border-color: #001727; }

.datepicker.datepicker-inline,
.datepicker.datepicker-inline table {
  width: 100%; }

.datepicker table tr td span.focused,
.datepicker table tr td span:hover {
  background: #d7d7d7; }

.datepicker .datepicker-switch:hover,
.datepicker .next:hover,
.datepicker .prev:hover,
.datepicker tfoot tr th:hover {
  background: #d7d7d7; }

.dropzone {
  min-height: 115px !important;
  border: 1px solid #d7d7d7 !important;
  background: white !important;
  padding: 10px 10px !important;
  border-radius: 0.1rem !important;
  color: #3a3a3a !important;
  height: auto; }
  .dropzone .img-thumbnail {
    height: 58px !important;
    width: 100% !important;
    object-fit: cover !important; }

.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message * {
  position: relative;
  transform: translateY(-50%);
  top: 50% !important;
  margin: 0; }

.dropzone.dz-clickable .dz-message span {
  top: 50px !important; }

.dropzone .dz-preview.dz-image-preview,
.dropzone .dz-preview.dz-file-preview {
  width: 260px;
  height: 60px;
  min-height: unset;
  border: 1px solid #d7d7d7 !important;
  border-radius: 0.1rem !important;
  background: white !important;
  color: #3a3a3a !important; }
  .dropzone .dz-preview.dz-image-preview .preview-container,
  .dropzone .dz-preview.dz-file-preview .preview-container {
    transition: initial !important;
    animation: initial !important;
    margin-left: 0;
    margin-top: 0;
    position: relative;
    width: 100%;
    height: 100%; }
    .dropzone .dz-preview.dz-image-preview .preview-container i,
    .dropzone .dz-preview.dz-file-preview .preview-container i {
      color: #00365a;
      font-size: 20px;
      position: absolute;
      left: 50%;
      top: 29px;
      transform: translateX(-50%) translateY(-50%) !important;
      height: 22px; }
  .dropzone .dz-preview.dz-image-preview strong,
  .dropzone .dz-preview.dz-file-preview strong {
    font-weight: normal; }
  .dropzone .dz-preview.dz-image-preview .remove,
  .dropzone .dz-preview.dz-file-preview .remove {
    position: absolute;
    right: 5px;
    top: 5px;
    color: #00365a !important; }
  .dropzone .dz-preview.dz-image-preview .dz-details,
  .dropzone .dz-preview.dz-file-preview .dz-details {
    position: static;
    display: block;
    opacity: 1;
    text-align: left;
    min-width: unset;
    z-index: initial;
    color: #3a3a3a !important; }
  .dropzone .dz-preview.dz-image-preview .dz-error-mark,
  .dropzone .dz-preview.dz-file-preview .dz-error-mark {
    color: #fff !important;
    top: 15px;
    left: 25px;
    margin-left: 0;
    margin-top: 0; }
    .dropzone .dz-preview.dz-image-preview .dz-error-mark span,
    .dropzone .dz-preview.dz-file-preview .dz-error-mark span {
      display: inline-block;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28 28'%3E%3Cpath style='fill:%2300365A;' d='M4.1,23.9A13.51,13.51,0,0,1,0,14,13.52,13.52,0,0,1,4.1,4.1,13.52,13.52,0,0,1,14,0a13.52,13.52,0,0,1,9.9,4.1A13.52,13.52,0,0,1,28,14a13.51,13.51,0,0,1-4.1,9.9A13.52,13.52,0,0,1,14,28,13.52,13.52,0,0,1,4.1,23.9Z'/%3E%3Cpath style='fill:%23FFFFFF;' d='M13.13,19.35V6.17a.88.88,0,1,1,1.75,0V19.35Z'/%3E%3Crect style='fill:%23FFFFFF;' x='13.13' y='21.07' width='1.75' height='1.64'/%3E%3C/svg%3E");
      width: 28px;
      height: 28px; }
  .dropzone .dz-preview.dz-image-preview .dz-success-mark,
  .dropzone .dz-preview.dz-file-preview .dz-success-mark {
    color: #fff;
    top: 15px;
    left: 25px;
    margin-left: 0;
    margin-top: 0; }
    .dropzone .dz-preview.dz-image-preview .dz-success-mark span,
    .dropzone .dz-preview.dz-file-preview .dz-success-mark span {
      display: inline-block;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28 28'%3E%3Cpath style='fill:%2300365A;' d='M4.1,23.9A13.51,13.51,0,0,1,0,14,13.52,13.52,0,0,1,4.1,4.1,13.52,13.52,0,0,1,14,0a13.52,13.52,0,0,1,9.9,4.1A13.52,13.52,0,0,1,28,14a13.51,13.51,0,0,1-4.1,9.9A13.52,13.52,0,0,1,14,28,13.52,13.52,0,0,1,4.1,23.9Z'/%3E%3Cpath style='fill:%23FFFFFF;' d='M20.14,8.81A.77.77,0,0,1,21.2,9a.81.81,0,0,1,.25.61.83.83,0,0,1-.25.62L12.48,19l-.11.1a.82.82,0,0,1-1.23,0L6.79,14.74l-.11-.16a.49.49,0,0,1-.08-.18,1.06,1.06,0,0,1,0-.19.61.61,0,0,1,0-.19,1.16,1.16,0,0,1,0-.18,1.26,1.26,0,0,1,.08-.18,1,1,0,0,1,.11-.15.87.87,0,0,1,1.26,0l3.69,3.7L19.94,9A.72.72,0,0,1,20.14,8.81Z'/%3E%3C/svg%3E");
      width: 28px;
      height: 28px; }
  .dropzone .dz-preview.dz-image-preview .dz-progress,
  .dropzone .dz-preview.dz-file-preview .dz-progress {
    width: 84%;
    margin-left: 0;
    margin-top: 0;
    right: 0;
    height: 5px !important;
    left: 15px; }
    .dropzone .dz-preview.dz-image-preview .dz-progress .dz-upload,
    .dropzone .dz-preview.dz-file-preview .dz-progress .dz-upload {
      width: 100%;
      background: #00365a !important; }
  .dropzone .dz-preview.dz-image-preview .dz-error-message,
  .dropzone .dz-preview.dz-file-preview .dz-error-message {
    border-radius: 15px;
    background: #c43d4b !important;
    top: 60px; }
    .dropzone .dz-preview.dz-image-preview .dz-error-message:after,
    .dropzone .dz-preview.dz-file-preview .dz-error-message:after {
      border-bottom: 6px solid #c43d4b !important; }
  .dropzone .dz-preview.dz-image-preview [data-dz-name],
  .dropzone .dz-preview.dz-file-preview [data-dz-name] {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 90%;
    display: inline-block;
    overflow: hidden; }

.dropzone .dz-preview.dz-file-preview .img-thumbnail {
  display: none; }

.dropzone .dz-error.dz-preview.dz-file-preview .preview-icon {
  display: none; }

.dropzone .dz-error.dz-preview.dz-file-preview .dz-error-mark,
.dropzone .dz-error.dz-preview.dz-file-preview .dz-success-mark {
  color: #00365a !important; }

.dropzone .dz-preview.dz-image-preview .preview-icon {
  display: none; }

@-webkit-keyframes pulse-inner {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); }
  10% {
    -webkit-transform: scale(0.8, 1);
    -moz-transform: scale(0.8, 1);
    -ms-transform: scale(0.8, 1);
    -o-transform: scale(0.8, 1);
    transform: scale(0.8, 1); }
  20% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); } }

@-moz-keyframes pulse-inner {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); }
  10% {
    -webkit-transform: scale(0.8, 1);
    -moz-transform: scale(0.8, 1);
    -ms-transform: scale(0.8, 1);
    -o-transform: scale(0.8, 1);
    transform: scale(0.8, 1); }
  20% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); } }

@keyframes pulse-inner {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); }
  10% {
    -webkit-transform: scale(0.8, 1);
    -moz-transform: scale(0.8, 1);
    -ms-transform: scale(0.8, 1);
    -o-transform: scale(0.8, 1);
    transform: scale(0.8, 1); }
  20% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); } }

.dropzone .dz-preview:not(.dz-processing) .dz-progress {
  -webkit-animation: pulse-inner 3s ease infinite;
  -moz-animation: pulse-inner 3s ease infinite;
  -ms-animation: pulse-inner 3s ease infinite;
  -o-animation: pulse-inner 3s ease infinite;
  animation: pulse-inner 3s ease infinite; }

select.form-control {
  padding: 0 0.75rem; }

.custom-switch {
  padding-left: initial; }

.custom-switch .custom-switch-input + .custom-switch-btn {
  background: #d7d7d7; }

.custom-switch.custom-switch-primary
.custom-switch-input:checked
+ .custom-switch-btn {
  background: #00365a;
  border: 1px solid #00365a; }

.custom-switch.custom-switch-secondary
.custom-switch-input:checked
+ .custom-switch-btn {
  background: #184f90;
  border: 1px solid #184f90; }

.custom-switch.custom-switch-primary-inverse
.custom-switch-input
+ .custom-switch-btn {
  border: 1px solid #d7d7d7; }

.custom-switch.custom-switch-primary-inverse
.custom-switch-input:checked
+ .custom-switch-btn {
  background: white;
  border: 1px solid #00365a; }

.custom-switch.custom-switch-primary-inverse
.custom-switch-input:checked
+ .custom-switch-btn:after {
  background: #00365a; }

.custom-switch.custom-switch-secondary-inverse
.custom-switch-input
+ .custom-switch-btn {
  border: 1px solid #d7d7d7; }

.custom-switch.custom-switch-secondary-inverse
.custom-switch-input:checked
+ .custom-switch-btn {
  background: white;
  border: 1px solid #184f90; }

.custom-switch.custom-switch-secondary-inverse
.custom-switch-input:checked
+ .custom-switch-btn:after {
  background: #184f90; }

.input-group-text {
  border-radius: 0.1rem; }

.custom-switch .custom-switch-input + .custom-switch-btn:after {
  background: white; }

.custom-switch .custom-switch-input + .custom-switch-btn {
  border-color: #d7d7d7; }

.noUi-target {
  background: white;
  border-color: #d7d7d7;
  box-shadow: initial; }

.noUi-tooltip {
  background: white;
  color: #3a3a3a;
  border-color: #d7d7d7; }

.form-control-sm,
.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  border-radius: 0.1rem; }

.form-control-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  border-radius: 0.1rem; }

.custom-select {
  border-radius: 0.1rem;
  padding: 0.75rem;
  font-size: 0.8rem;
  line-height: 1;
  height: calc(2em + 0.8rem); }

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  outline: initial !important;
  box-shadow: initial !important; }

.custom-select:focus {
  border-color: #00365a; }

.custom-file-input {
  box-shadow: initial !important; }

.custom-file-label {
  box-shadow: initial !important;
  border-radius: 0.1rem;
  height: calc(2em + 0.8rem);
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  background: white;
  border-color: #d7d7d7; }

.custom-file {
  height: calc(2em + 0.8rem); }

.custom-file-label:focus,
.custom-file-input:focus {
  border-color: rgba(0, 54, 90, 0.6); }

.custom-file-input:focus ~ .custom-file-label {
  border-color: rgba(0, 54, 90, 0.6);
  box-shadow: initial; }

.custom-file-input:focus ~ .custom-file-label::after {
  border-color: rgba(0, 54, 90, 0.6); }

.custom-file-label:after,
.input-group > .custom-select:not(:first-child),
.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:first-child),
.input-group > .form-control:not(:last-child) {
  outline: initial !important;
  box-shadow: initial !important; }

.custom-file-label::after {
  height: calc(2em + 0.8rem);
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  color: #3a3a3a;
  border-color: #d7d7d7;
  background: transparent; }

.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
  background: white;
  color: #3a3a3a;
  border-color: #d7d7d7; }

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  background: white;
  color: #3a3a3a;
  border-color: #d7d7d7; }

.jumbotron {
  background: initial; }

.has-top-label label,
.has-top-label > span,
.has-float-label label,
.has-float-label > span {
  color: rgba(58, 58, 58, 0.7); }

/* 23.Form Wizard */
.sw-main {
  box-shadow: initial; }
  .sw-main > ul.step-anchor {
    padding: 0 0.75rem;
    background: initial; }
  .sw-main .step-content {
    background: initial; }
  .sw-main.sw-theme-default > ul.step-anchor > li > a {
    font-weight: 700; }
  .sw-main.sw-theme-default > ul.step-anchor > li > a::after {
    content: initial !important; }
  .sw-main.sw-theme-default > ul.step-anchor > li.active > a::before {
    content: " ";
    position: absolute;
    background: #00365a;
    width: calc(100% - 1rem);
    border-radius: 10px;
    height: 5px;
    top: 0;
    left: 50%;
    transform: translateX(-50%); }
  .sw-main > .btn-toolbar.sw-toolbar.sw-toolbar-bottom {
    background: initial;
    padding: 1.75rem; }
    @media (max-width: 575px) {
      .sw-main > .btn-toolbar.sw-toolbar.sw-toolbar-bottom {
        padding: 1.5rem; } }
  .sw-main > ul.step-anchor > li.active > a {
    color: #00365a !important; }
  .sw-main > ul.step-anchor > li > a,
  .sw-main > ul.step-anchor > li > a:hover,
  .sw-main > ul.step-anchor > li > a:focus {
    color: #d7d7d7; }
  .sw-main .step-content {
    padding: initial; }
  .sw-main > ul.step-anchor > li.done > a {
    color: #3a3a3a !important; }
  .sw-main.sw-theme-dots > ul.step-anchor > li > a:before,
  .sw-main.sw-theme-check > ul.step-anchor > li > a:before {
    left: 50%;
    transform: translateX(-50%);
    background: #f3f3f3;
    bottom: 5px;
    width: 24px;
    height: 24px; }
  .sw-main.sw-theme-dots > ul.step-anchor > li > a:after,
  .sw-main.sw-theme-check > ul.step-anchor > li > a:after {
    left: 50%;
    transform: translateX(-50%);
    width: 12px;
    height: 12px;
    bottom: 3px;
    background: white; }
  .sw-main.sw-theme-dots > ul.step-anchor > li.done > a:after,
  .sw-main.sw-theme-check > ul.step-anchor > li.done > a:after {
    background: #00365a; }
  .sw-main.sw-theme-dots > ul.step-anchor > li.active > a:after {
    background: initial;
    border: 2px solid #00365a; }
  .sw-main.sw-theme-dots > ul.step-anchor:before,
  .sw-main.sw-theme-check > ul.step-anchor:before {
    background: #f3f3f3;
    height: 1px;
    width: calc(100% - 3rem);
    left: 50%;
    transform: translateX(-50%);
    top: 67px; }
  .sw-main.sw-theme-check > ul.step-anchor > li.active > a:after {
    background: #00365a; }
  .sw-main.sw-main.sw-theme-check > ul.step-anchor > li.done > a:after {
    background: initial;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%2300365A' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }
  .sw-main > ul.step-anchor > li.clickable > a:hover {
    color: #184f90 !important; }
  .sw-main > .btn-toolbar.sw-toolbar.sw-toolbar-bottom .btn-group {
    margin-right: 0 !important; }
  .sw-main .custom-toolbar {
    height: auto !important;
    min-height: auto !important; }

.sw-theme-check > ul.step-anchor > li > a,
.sw-theme-check > ul.step-anchor:before,
.sw-theme-dots > ul.step-anchor > li > a,
.sw-theme-dots > ul.step-anchor:before {
  z-index: 1; }

#smartWizardValidation .card-body > div {
  height: 180px; }

/* 24.Slider(Range) */
.noUi-connect {
  background: #00365a; }

.noUi-connects {
  border-radius: 0.1rem; }

.noUi-handle:after,
.noUi-handle:before {
  content: "";
  display: none; }

.noUi-handle {
  outline: initial;
  box-shadow: initial !important; }

.noUi-horizontal {
  height: 8px; }

.noUi-horizontal .noUi-handle {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  top: -7px;
  border-color: #d7d7d7;
  background: white; }

.noUi-horizontal .noUi-tooltip {
  top: 120%;
  bottom: unset;
  padding-left: 10px;
  padding-right: 10px; }

/* 25.Navs */
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: white;
  background-color: #00365a; }

.nav-pills .nav-link {
  border-radius: 50px; }

@media (max-width: 767px) {
  .nav-link {
    padding: 0.5rem 0.5rem; } }

.nav-link.active {
  color: #00365a; }

.page-item {
  padding: 10px; }
  @media (max-width: 767px) {
    .page-item {
      padding: 3px; } }
  .page-item .page-link {
    outline: initial !important;
    box-shadow: initial !important;
    line-height: 1.7;
    border-radius: 40px !important;
    min-width: 38px;
    text-align: center;
    height: 38px;
    padding: 0.5rem 0; }
    @media (max-width: 575px) {
      .page-item .page-link {
        min-width: 30px;
        height: 30px;
        line-height: 0.9;
        font-size: 0.76rem; } }
    .page-item .page-link.next {
      background: #00365a;
      color: #fff;
      border: 1px solid #00365a; }
    .page-item .page-link.prev, .page-item .page-link.prev {
      background: #00365a;
      border: 1px solid #00365a;
      color: #fff; }
    .page-item .page-link.first, .page-item .page-link.last {
      background: transparent;
      color: #00365a;
      border: 1px solid #00365a;
      border-radius: 30px; }
      .page-item .page-link.first:hover, .page-item .page-link.last:hover {
        background: #00365a;
        color: white;
        border: 1px solid #00365a; }
    .page-item .page-link:hover {
      background-color: transparent;
      border-color: #00558d;
      color: #00365a; }
  .page-item.active .page-link {
    background: transparent;
    border: 1px solid #00365a;
    color: #00365a; }
  .page-item.disabled .page-link {
    border-color: #d7d7d7;
    color: #d7d7d7;
    background: transparent; }

.page-link {
  background-color: transparent;
  border-color: transparent;
  color: #3a3a3a; }

.btn-sm.page-link {
  padding: 0.5rem 0.5rem; }

.pagination-lg .page-item {
  padding: 15px; }
  @media (max-width: 767px) {
    .pagination-lg .page-item {
      padding: 3px; } }
  .pagination-lg .page-item .page-link {
    min-width: 50px;
    height: 50px; }
    @media (max-width: 767px) {
      .pagination-lg .page-item .page-link {
        min-width: 38px;
        height: 38px;
        padding: 0.55rem 0;
        line-height: 1.5;
        font-size: 0.8rem; } }
    @media (max-width: 575px) {
      .pagination-lg .page-item .page-link {
        min-width: 30px;
        height: 30px;
        line-height: 0.9;
        font-size: 0.76rem; } }

.pagination-sm .page-item {
  padding: 6px; }
  @media (max-width: 767px) {
    .pagination-sm .page-item {
      padding: 3px; } }
  .pagination-sm .page-item .page-link {
    min-width: 30px;
    height: 30px;
    line-height: 1;
    font-size: 0.76rem; }

/* 26.Tooltip and popover */
.popover {
  border-radius: 0.1rem;
  background-color: white;
  border-color: #d7d7d7; }
  .popover .popover-body {
    color: #3a3a3a; }

.popover,
.tooltip {
  top: -145px !important; }
  @media (max-width: 1439px) {
    .popover,
    .tooltip {
      top: -130px !important; } }
  @media (max-width: 1199px) {
    .popover,
    .tooltip {
      top: -110px !important; } }
  @media (max-width: 767px) {
    .popover,
    .tooltip {
      top: -80px !important; } }

.popover-header {
  background-color: transparent;
  border-bottom: initial; }

.tooltip-inner {
  padding: 0.5rem 0.75rem;
  color: #3a3a3a;
  background-color: white;
  border-radius: 0.1rem;
  border: 1px solid #d7d7d7; }

.tooltip.show {
  opacity: 1; }

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before {
  border-right-color: #d7d7d7; }

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-right-color: white; }

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before {
  border-left-color: #d7d7d7; }

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-left-color: white; }

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #d7d7d7; }

.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: white; }

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before {
  border-top-color: #d7d7d7; }

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-top-color: white; }

.tooltip .arrow::before,
.tooltip .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid; }

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^="right"] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0; }

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: #d7d7d7; }

.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: white; }

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^="right"] .arrow::after {
  border-width: 0.4rem 0.4rem 0.4rem 0; }

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: #d7d7d7; }

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^="top"] .arrow::after {
  border-width: 0.5rem 0.5rem 0; }

.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^="top"] .arrow::after {
  bottom: 1px;
  border-top-color: white; }

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^="top"] .arrow::after {
  border-width: 0.4rem 0.4rem 0; }

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem; }

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: #d7d7d7; }

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem; }

.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::after {
  top: 1px;
  border-bottom-color: white; }

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem; }

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: #d7d7d7; }

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem; }

.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^="left"] .arrow::after {
  right: 0;
  border-left-color: white; }

.search-sm {
  position: relative; }
  .search-sm input {
    background: none;
    outline: initial !important;
    border-radius: 15px;
    padding: 0.25rem 0.75rem 0.25rem 0.75rem;
    font-size: 0.76rem;
    line-height: 1;
    border: 1px solid #8f8f8f;
    color: #3a3a3a;
    height: initial; }
  .search-sm:after {
    font-family: "simple-line-icons";
    content: "\E090";
    font-size: 14px;
    color: #8f8f8f;
    position: absolute;
    right: 10px;
    text-align: center;
    cursor: pointer;
    top: 2px; }

div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0; }

.calendar-sm::after {
  content: "\e075"; }

/* 27.Progress */
.progress {
  height: 3px;
  background-color: #f3f3f3; }

.progress-bar {
  background-color: #00365a; }

.progressbar-text {
  position: absolute;
  left: 0;
  width: 50px;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  color: #3a3a3a !important; }

.progress-bar-circle {
  width: 54px;
  height: 54px; }
  .progress-bar-circle svg path:first-of-type {
    stroke: #d7d7d7; }
  .progress-bar-circle svg path:last-of-type {
    stroke: #00365a; }
  .progress-bar-circle.progress-bar-banner svg path:first-of-type {
    stroke: #4d728c; }
  .progress-bar-circle.progress-bar-banner svg path:last-of-type {
    stroke: #fff; }

.progress-banner {
  height: 200px;
  background-image: linear-gradient(to right top, #00365a, #00538a, #015994);
  transition: 0.5s;
  background-size: 200% auto;
  cursor: pointer; }
  .progress-banner .lead {
    font-size: 1.5rem;
    margin-bottom: 0.5rem; }
    @media (max-width: 1199px) {
      .progress-banner .lead {
        font-size: 1.2rem;
        margin-bottom: 0.2rem; } }
  .progress-banner i {
    font-size: 2.5rem;
    margin-bottom: 1rem; }
    @media (max-width: 1199px) {
      .progress-banner i {
        font-size: 2rem;
        margin-bottom: 0.2rem; } }
  .progress-banner .progress-bar-circle.progress-bar-banner {
    width: 120px;
    height: 120px; }
    @media (max-width: 1199px) {
      .progress-banner .progress-bar-circle.progress-bar-banner {
        width: 80px;
        height: 80px; } }
  .progress-banner .progress-bar-banner .progressbar-text {
    color: #fff !important;
    font-size: 1.7rem;
    width: 110px;
    font-weight: 300; }
    @media (max-width: 1199px) {
      .progress-banner .progress-bar-banner .progressbar-text {
        font-size: 1.2rem;
        margin-bottom: 0.2rem; } }
  .progress-banner:hover {
    background-position: right top; }

/* 28.Rating */
.br-theme-bootstrap-stars .br-widget a:after {
  color: #d7d7d7; }

.br-theme-bootstrap-stars .br-widget a.br-active:after {
  color: #00365a;
  font-weight: 900; }

.br-theme-bootstrap-stars .br-widget a.br-selected:after {
  color: #00365a;
  font-weight: 900; }

/* 29.Sortable */
.sortable {
  cursor: default; }

.sortable span {
  vertical-align: middle; }

.sortable-ghost {
  opacity: 0.5; }

/* 30.Spinner */
.btn-multiple-state .spinner {
  width: 36px;
  text-align: center; }

.btn-multiple-state .spinner > div {
  width: 6px;
  height: 6px;
  background-color: #fff;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.2s infinite ease-in-out both;
  animation: sk-bouncedelay 1.2s infinite ease-in-out both; }

.btn-multiple-state .spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s; }

.btn-multiple-state .spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s; }

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0.3);
    opacity: 0; }
  40% {
    -webkit-transform: scale(1);
    opacity: 1; } }

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0; }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

/* 31.Croppper */
.cropper-line {
  background-color: #00365a; }

.cropper-point {
  background-color: #00365a; }

.cropper-view-box {
  outline-color: #00365a;
  outline: #00365a; }

.cropper-preview {
  overflow: hidden; }

#cropperContainer {
  height: 300px;
  display: none; }

/* 32.Modal */
.modal .modal-header,
.modal .modal-body,
.modal .modal-footer {
  padding: 1.75rem; }

.modal .modal-header {
  border-bottom: 1px solid #d7d7d7; }

.modal .modal-footer {
  border-top: 1px solid #d7d7d7; }

.modal .close {
  color: #3a3a3a;
  text-shadow: initial; }

.modal .modal-content {
  border: initial;
  border-radius: 0.1rem;
  background: white; }

.modal-right {
  padding-right: 0 !important; }
  .modal-right .modal-dialog {
    margin: 0 auto;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;
    max-width: 380px; }
  .modal-right .modal-content {
    min-height: 100%; }
  .modal-right .modal-header {
    flex: 0 0 100px; }
    @media (max-width: 1439px) {
      .modal-right .modal-header {
        flex: 0 0 90px; } }
    @media (max-width: 1199px) {
      .modal-right .modal-header {
        flex: 0 0 80px; } }
    @media (max-width: 767px) {
      .modal-right .modal-header {
        flex: 0 0 70px; } }
  .modal-right .modal-footer {
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0; }
  .modal-right.modal.fade .modal-dialog {
    transform: translate(25%, 0); }
  .modal-right.modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-left {
  padding-left: 0 !important; }
  .modal-left .modal-dialog {
    margin: 0 auto;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;
    max-width: 380px; }
  .modal-left .modal-content {
    min-height: 100%; }
  .modal-left .modal-header {
    flex: 0 0 100px; }
    @media (max-width: 1439px) {
      .modal-left .modal-header {
        flex: 0 0 90px; } }
    @media (max-width: 1199px) {
      .modal-left .modal-header {
        flex: 0 0 80px; } }
    @media (max-width: 767px) {
      .modal-left .modal-header {
        flex: 0 0 70px; } }
  .modal-left .modal-footer {
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0; }
  .modal-left.modal.fade .modal-dialog {
    transform: translate(-25%, 0); }
  .modal-left.modal.show .modal-dialog {
    transform: translate(0, 0); }

/* 33.Authorization */
.auth-card {
  display: flex;
  flex-direction: row;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04); }
  .auth-card .image-side {
    width: 40%;
    /* background: url("../img/login/balloon.jpg") no-repeat center top; */
    background-size: cover;
    padding: 80px 40px; }
    .auth-card .image-side .h3 {
      line-height: 0.8rem; }
  .auth-card .form-side {
    width: 60%;
    padding: 80px; }
  @media (max-width: 991px) {
    .auth-card {
      flex-direction: column; }
      .auth-card .image-side {
        width: 100%;
        padding: 60px; }
      .auth-card .form-side {
        width: 100%;
        padding: 60px; } }
  @media (max-width: 767px) {
    .auth-card p.h2 {
      font-size: 1.6rem; } }
  @media (max-width: 575px) {
    .auth-card {
      flex-direction: column; }
      .auth-card .image-side {
        padding: 35px 30px; }
      .auth-card .form-side {
        padding: 35px 30px; }
      .auth-card .logo-single {
        margin-bottom: 20px; }
      .auth-card p.h2 {
        font-size: 1.4rem; } }

.index-card .card-body {
  padding: 80px; }
  @media (max-width: 991px) {
    .index-card .card-body {
      padding: 60px; } }
  @media (max-width: 575px) {
    .index-card .card-body {
      padding: 35px 30px; } }

/* 34.Html Editors */
.html-editor {
  height: 350px; }

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #00365a; }

.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #00365a; }

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-mitter,
.ql-snow .ql-toolbar button:hover .ql-stroke-mitter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-mitter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-mitter {
  stroke: #00365a; }

.ql-toolbar.ql-snow {
  border-color: #d7d7d7; }

.ql-container.ql-snow {
  border-color: #d7d7d7; }

.html-editor-bubble {
  height: 200px; }

.html-editor-bubble .ql-editor {
  border: 1px solid #d7d7d7; }

.ql-tooltip {
  z-index: 10; }

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  height: 350px;
  background: white; }

.ck.ck-button,
a.ck.ck-button {
  color: #d7d7d7; }

.ck.ck-button:not(.ck-disabled):focus,
.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):focus,
a.ck.ck-button:not(.ck-disabled):hover,
.ck.ck-button.ck-on,
a.ck.ck-button.ck-on,
.ck.ck-button.ck-on:not(.ck-disabled):focus,
.ck.ck-button.ck-on:not(.ck-disabled):hover,
a.ck.ck-button.ck-on:not(.ck-disabled):focus,
a.ck.ck-button.ck-on:not(.ck-disabled):hover {
  background: initial;
  color: #00365a;
  box-shadow: initial;
  outline: initial; }

.ck.ck-toolbar {
  border-color: #d7d7d7 !important; }

.ck.ck-toolbar__separator {
  background: #d7d7d7 !important; }

.ck.ck-toolbar {
  border-color: #d7d7d7; }

.ck.ck-editor__main > .ck-editor__editable {
  border-color: #d7d7d7 !important; }

.ck.ck-editor__main > .ck-editor__editable {
  background: white; }

.ck.ck-content.ck-editor__editable.ck-rounded-corners {
  box-shadow: initial !important; }

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-focused {
  border-color: #d7d7d7; }

.ck.ck-toolbar {
  background: white !important; }

/* 35.Icons */
.simple-line-icons .glyph,
.mind-icons .glyph {
  width: 14.28%;
  text-align: center !important;
  float: left;
  height: 100px; }
  .simple-line-icons .glyph .glyph-icon,
  .simple-line-icons .glyph .fa,
  .mind-icons .glyph .glyph-icon,
  .mind-icons .glyph .fa {
    font-size: 32px;
    text-align: center !important; }
  .simple-line-icons .glyph .author-name,
  .mind-icons .glyph .author-name {
    display: none; }
  .simple-line-icons .glyph .class-name,
  .mind-icons .glyph .class-name {
    font-size: 0.76rem;
    color: #909090 !important;
    text-align: center !important; }
  @media (max-width: 1199px) {
    .simple-line-icons .glyph,
    .mind-icons .glyph {
      width: 16.66%; } }
  @media (max-width: 991px) {
    .simple-line-icons .glyph,
    .mind-icons .glyph {
      width: 20%; } }
  @media (max-width: 767px) {
    .simple-line-icons .glyph,
    .mind-icons .glyph {
      width: 25%; } }
  @media (max-width: 575px) {
    .simple-line-icons .glyph,
    .mind-icons .glyph {
      width: 50%; } }

.chart-container {
  height: 300px; }

.theme-colors {
  width: 280px !important;
  position: fixed;
  z-index: 1030;
  top: 50%;
  right: 0;
  background: white;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  transform: translate(280px, -50%);
  padding-top: 10px;
  padding-bottom: 10px; }
  .theme-colors .theme-button {
    position: absolute;
    left: -32px;
    background: white;
    padding: 13px 7px 13px 7px;
    border-radius: 0.2rem;
    color: #3a3a3a;
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.04);
    font-size: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: #00365a; }
  .theme-colors .theme-color {
    width: 24px;
    height: 24px;
    display: inline-block;
    border-radius: 20px;
    transition: background 0.25s; }
    .theme-colors .theme-color.active, .theme-colors .theme-color:hover {
      background: white; }
  .theme-colors .theme-color-purplemonster {
    border: 3px solid #922c88;
    background: #922c88; }
  .theme-colors .theme-color-blueyale {
    border: 3px solid #145388;
    background: #145388; }
  .theme-colors .theme-color-blueolympic {
    border: 3px solid #008ecc;
    background: #008ecc; }
  .theme-colors .theme-color-bluenavy {
    border: 3px solid #00365a;
    background: #00365a; }
  .theme-colors .theme-color-orangecarrot {
    border: 3px solid #ed7117;
    background: #ed7117; }
  .theme-colors .theme-color-greenmoss {
    border: 3px solid #576a3d;
    background: #576a3d; }
  .theme-colors .theme-color-greenlime {
    border: 3px solid #6fb327;
    background: #6fb327; }
  .theme-colors .theme-color-redruby {
    border: 3px solid #900604;
    background: #900604; }
  .theme-colors .theme-color-greysteel {
    border: 3px solid #48494b;
    background: #48494b; }
  .theme-colors .theme-color-yellowgranola {
    border: 3px solid #c0a145;
    background: #c0a145; }
  .theme-colors.shown {
    transform: translate(0, calc(-50% + 0.5px));
    transition: transform 0.4s ease-out; }
  .theme-colors.hidden {
    transform: translate(280px, -50%);
    transition: transform 0.4s ease-out; }

/* 36.Loading */
.loading {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid rgba(0, 54, 90, 0.2);
  border-radius: 50%;
  border-top-color: #00365a;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  position: fixed;
  z-index: 1; }

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg); } }

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg); } }

/* 37.Media Library */
.library-app .sfl-selected-item,
.select-from-library-container .sfl-selected-item {
  display: none; }

.library-app .list,
.select-from-library-container .list {
  margin-bottom: -1.5rem; }

@media (max-width: 1199px) {
  .library-app .drop-area-container,
  .select-from-library-container .drop-area-container {
    margin-bottom: 1.5rem; } }

.library-app .dropzone.dz-clickable .dz-message span,
.select-from-library-container .dropzone.dz-clickable .dz-message span {
  top: 0 !important; }

.library-app .drop-area,
.select-from-library-container .drop-area {
  min-height: 750px;
  height: 100%; }
  .library-app .drop-area .card-body,
  .select-from-library-container .drop-area .card-body {
    height: 100%; }
  .library-app .drop-area form,
  .select-from-library-container .drop-area form {
    height: 100%; }
  .library-app .drop-area .dropzone,
  .select-from-library-container .drop-area .dropzone {
    height: 100%; }
  @media (max-width: 1199px) {
    .library-app .drop-area,
    .select-from-library-container .drop-area {
      min-height: 220px;
      height: 100%; } }
  .library-app .drop-area .dropzone .dz-preview.dz-image-preview,
  .select-from-library-container .drop-area .dropzone .dz-preview.dz-image-preview {
    width: calc(100% - 32px); }
    @media (max-width: 1199px) {
      .library-app .drop-area .dropzone .dz-preview.dz-image-preview,
      .select-from-library-container .drop-area .dropzone .dz-preview.dz-image-preview {
        width: calc(50% - 32px); } }
    @media (max-width: 991px) {
      .library-app .drop-area .dropzone .dz-preview.dz-image-preview,
      .select-from-library-container .drop-area .dropzone .dz-preview.dz-image-preview {
        width: calc(100% - 32px); } }

.library-app .preview-area,
.select-from-library-container .preview-area {
  min-height: 750px;
  height: 100%; }
  .library-app .preview-area .card-body,
  .select-from-library-container .preview-area .card-body {
    height: 100%; }
  @media (max-width: 1199px) {
    .library-app .preview-area,
    .select-from-library-container .preview-area {
      min-height: 220px;
      height: auto;
      margin-bottom: 1.5rem; } }
  @media (max-width: 575px) {
    .library-app .preview-area,
    .select-from-library-container .preview-area {
      height: auto; } }
  @media (max-width: 1199px) {
    .library-app .preview-area.preview-area-audio,
    .select-from-library-container .preview-area.preview-area-audio {
      height: auto; } }
  @media (max-width: 1199px) {
    .library-app .preview-area .card-img-top,
    .select-from-library-container .preview-area .card-img-top {
      height: 220px;
      width: 220px;
      object-fit: cover; } }
  @media (max-width: 575px) {
    .library-app .preview-area .card-img-top,
    .select-from-library-container .preview-area .card-img-top {
      height: 180px;
      width: 100%;
      object-fit: cover; } }
  .library-app .preview-area .video-view,
  .select-from-library-container .preview-area .video-view {
    height: 301px; }
    @media (max-width: 575px) {
      .library-app .preview-area .video-view,
      .select-from-library-container .preview-area .video-view {
        height: 180px;
        width: 100%; } }
    .library-app .preview-area .video-view .video-js,
    .select-from-library-container .preview-area .video-view .video-js {
      width: 100%;
      height: 100%;
      background-color: #f8f8f8; }
      .library-app .preview-area .video-view .video-js .vjs-poster,
      .select-from-library-container .preview-area .video-view .video-js .vjs-poster {
        background-size: cover; }
  .library-app .preview-area .audio-view,
  .select-from-library-container .preview-area .audio-view {
    height: 60px; }
    .library-app .preview-area .audio-view .video-js.audio,
    .select-from-library-container .preview-area .audio-view .video-js.audio {
      width: 100%;
      height: 100%; }

.library-app .media-thumb-container,
.select-from-library-container .media-thumb-container {
  height: 105px;
  padding: 20px 30px; }
  .library-app .media-thumb-container.active-default,
  .select-from-library-container .media-thumb-container.active-default {
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1); }
  .library-app .media-thumb-container .card-body,
  .select-from-library-container .media-thumb-container .card-body {
    padding-left: 1rem; }
  @media (max-width: 575px) {
    .library-app .media-thumb-container,
    .select-from-library-container .media-thumb-container {
      padding: 1.5rem; } }

.library-app .list-media-thumbnail,
.select-from-library-container .list-media-thumbnail {
  border-radius: 0.1rem;
  padding: 0;
  border: initial;
  height: auto;
  max-width: unset;
  width: 55px;
  height: 45px;
  object-fit: cover; }

.library-app .media-thumbnail-icon,
.select-from-library-container .media-thumbnail-icon {
  width: 60px;
  height: 45px; }
  .library-app .media-thumbnail-icon i,
  .select-from-library-container .media-thumbnail-icon i {
    font-size: 35px;
    margin: 0 auto;
    line-height: 48px;
    color: #00365a; }

.library-app .dropzone.disabled,
.select-from-library-container .dropzone.disabled {
  opacity: 0.4; }
  .library-app .dropzone.disabled .dropzone-message,
  .select-from-library-container .dropzone.disabled .dropzone-message {
    position: relative;
    transform: translateY(-50%);
    top: 50%;
    display: block;
    text-align: center; }

.sfl-item-container {
  padding-right: 0.5rem;
  padding-left: 0.5rem; }

.select-from-library-container {
  margin-bottom: -2rem; }

.select-from-library-button,
.selected-library-item {
  border: 1px solid #d7d7d7;
  height: 110px; }
  .select-from-library-button .card,
  .selected-library-item .card {
    box-shadow: initial; }

.select-from-library-button {
  cursor: pointer;
  border-style: dashed; }

.delete-library-item {
  font-size: 18px; }

.select-from-library .modal-content {
  background: #f8f8f8 !important;
  height: 100%; }
  .select-from-library .modal-content .ps__thumb-y {
    right: 14px; }

.select-from-library .list {
  margin: 0; }
  .select-from-library .list .row {
    margin-left: -0.5rem;
    margin-right: -0.5rem; }

.select-from-library .media-thumb-container.card {
  height: 175px; }

.select-from-library img.list-media-thumbnail {
  width: 100%;
  height: 105px;
  object-fit: cover; }

.select-from-library .modal-body.scroll {
  margin-right: initial; }

.select-from-library .dropzone-container {
  flex: 0 0 auto; }

.select-from-library .folder-button-collapse {
  display: inline-block;
  border-radius: initial;
  width: 100%;
  text-align: left;
  margin-bottom: 1rem; }
  .select-from-library .folder-button-collapse .icon-container {
    margin-right: 0.5rem;
    display: inline-block; }
    .select-from-library .folder-button-collapse .icon-container i {
      display: inline-block;
      font-size: 14px;
      margin: 0 auto;
      transform: rotate(0deg);
      transition: transform 0.3s; }
  .select-from-library .folder-button-collapse .folder-name {
    line-height: 22px;
    display: inline-block;
    font-size: 1rem; }
  .select-from-library .folder-button-collapse.collapsed .icon-container i {
    transform: rotate(-90deg); }

.select-from-library .dropzone {
  min-height: 160px;
  height: 100%;
  border: initial; }

.select-from-library .dz-default.dz-message {
  top: 75px; }

.select-from-library .dropzone .dz-preview.dz-image-preview {
  width: calc(100% - 32px); }

/* 38.Context Menu */
.context-menu-list {
  box-shadow: initial;
  border-radius: 0.1rem;
  background: white;
  border-color: rgba(58, 58, 58, 0.15);
  padding: 0.5rem 0; }

.context-menu-item {
  padding: 0.75rem 1.5rem;
  background: white;
  color: #3a3a3a; }
  .context-menu-item.context-menu-hover {
    color: #3a3a3a;
    text-decoration: none;
    background-color: #f8f8f8; }

.context-menu-item span {
  font-family: "Nunito", sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
  margin-left: 0.5rem;
  display: inline-block; }

.list .card.context-menu-active,
.list .card.active {
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1); }

/* 39.Videojs */
.video-js .vjs-big-play-button {
  background: white;
  height: 1.2em;
  border-radius: 0.75em;
  line-height: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 3.5em;
  width: 2.5em;
  border: 0.06666em solid white; }
  .video-js .vjs-big-play-button .vjs-icon-placeholder {
    color: #00365a; }

.vjs-poster,
.vjs-tech {
  border-radius: 0.1rem; }

.vjs-tech {
  background: initial;
  object-fit: cover; }

.video-js:hover .vjs-big-play-button,
.video-js .vjs-big-play-button:focus {
  background-color: #f2f2f2;
  border-color: #f2f2f2; }

.vjs-control {
  text-shadow: initial !important;
  outline: initial !important; }

.video-js .vjs-control-bar {
  background: initial;
  margin: 1.75rem;
  width: calc(100% - 3.5rem); }
  .video-js .vjs-control-bar .vjs-control.vjs-button,
  .video-js .vjs-control-bar .vjs-remaining-time,
  .video-js .vjs-control-bar .vjs-volume-panel {
    margin-right: 0.5em;
    background: white;
    color: #00365a;
    border-radius: 15px;
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1); }
  .video-js .vjs-control-bar .vjs-progress-control.vjs-control {
    background: white;
    border-radius: 15px;
    margin-right: 0.5em;
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1); }
  .video-js .vjs-control-bar .vjs-mute-control.vjs-control {
    box-shadow: initial; }
  .video-js .vjs-control-bar .vjs-progress-holder {
    height: 3px;
    font-size: 1.6em !important; }
  .video-js .vjs-control-bar .vjs-load-progress,
  .video-js .vjs-control-bar .vjs-load-progress div {
    background-color: rgba(0, 54, 90, 0.2); }
  .video-js .vjs-control-bar .vjs-play-progress:before {
    font-size: 0.55em;
    top: -0.2em; }
  .video-js .vjs-control-bar .vjs-progress-holder {
    margin: 0 17px; }
  .video-js .vjs-control-bar .vjs-slider {
    text-shadow: initial !important;
    outline: initial !important;
    background-color: #dadada; }
  .video-js .vjs-control-bar .vjs-play-progress {
    background: #00365a; }
  .video-js .vjs-control-bar .vjs-play-progress:before {
    color: #00365a; }
  .video-js .vjs-control-bar .vjs-volume-horizontal {
    margin-left: -1.5em;
    width: 4em; }
  .video-js .vjs-control-bar .vjs-volume-panel .vjs-volume-level {
    background: #00365a; }

.video-js.audio {
  background: initial; }
  .video-js.audio .vjs-big-play-button {
    display: none; }
  .video-js.audio .vjs-control-bar {
    display: flex; }
  .video-js.audio .vjs-fullscreen-control {
    display: none; }
  .video-js.audio .vjs-control-bar {
    margin-bottom: 0; }
  .video-js.audio .vjs-control.vjs-button,
  .video-js.audio .vjs-remaining-time,
  .video-js.audio .vjs-volume-panel {
    box-shadow: 0 0px 2px rgba(0, 0, 0, 0.15), 0 0px 1px rgba(0, 0, 0, 0.2); }
  .video-js.audio .vjs-progress-control.vjs-control {
    box-shadow: 0 0px 2px rgba(0, 0, 0, 0.15), 0 0px 1px rgba(0, 0, 0, 0.2); }
  .video-js.audio .vjs-mute-control {
    box-shadow: initial !important; }
  .video-js.audio .vjs-loading-spinner {
    display: none !important; }

.video-js.side-bar-video {
  width: 100%;
  height: 280px;
  background-color: #f8f8f8;
  overflow: hidden; }
  .video-js.side-bar-video video {
    object-fit: cover; }
  .video-js.side-bar-video .vjs-poster {
    background-size: cover; }

.video-js.video-content {
  width: 100%;
  height: 400px;
  background-color: #f8f8f8;
  overflow: hidden;
  max-height: unset; }
  .video-js.video-content video {
    object-fit: cover; }
  .video-js.video-content .vjs-poster {
    background-size: cover; }

/* 40.Prices */
.equal-height-container .col-item .card {
  height: 100%; }

@media (max-width: 991px) {
  .equal-height-container .col-item .card-body {
    text-align: left; } }

@media (max-width: 575px) {
  .equal-height-container .col-item .card-body {
    text-align: center; } }

.equal-height-container .col-item .price-top-part {
  text-align: center; }
  .equal-height-container .col-item .price-top-part * {
    text-align: center !important; }
  @media (max-width: 991px) {
    .equal-height-container .col-item .price-top-part {
      padding-left: 0;
      padding-right: 0;
      width: 40%; } }
  @media (max-width: 575px) {
    .equal-height-container .col-item .price-top-part {
      width: initial; } }

.equal-height-container .col-item .price-feature-list {
  justify-content: space-between; }
  .equal-height-container .col-item .price-feature-list ul {
    margin: 0 auto;
    align-self: flex-start;
    margin-bottom: 1rem; }
    .equal-height-container .col-item .price-feature-list ul li p {
      text-align: center !important; }

.table-heading {
  box-shadow: initial;
  background: initial; }

i.large-icon {
  font-size: 38px;
  line-height: 90px;
  color: #00365a; }
  @media (max-width: 767px) {
    i.large-icon {
      line-height: 70px; } }

.large-icon.initial-height {
  line-height: initial; }
  @media (max-width: 767px) {
    .large-icon.initial-height {
      line-height: initial; } }

/* 41.Tables */
.table {
  color: #3a3a3a; }

.table th,
.table td {
  border-color: #f3f3f3 !important; }

.table .thead-light th {
  background-color: #f3f3f3 !important; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f3f3f3 !important; }

.feature-row {
  margin-top: 80px; }
  @media (max-width: 767px) {
    .feature-row {
      margin-top: 40px; } }

/* 42.Invoice Print */
@media print {
  body {
    background: white;
    height: 100%; }
  main {
    margin: 0 !important; }
  .navbar,
  .menu,
  .theme-colors {
    display: none; }
  footer {
    display: none; }
  .invoice {
    width: 100%;
    max-width: 100%; }
    .invoice.row {
      margin: 0; }
    .invoice [class*="col"] {
      padding: 0; }
    .invoice .invoice-contents {
      width: 100%;
      max-width: 100% !important;
      height: 1370px !important; } }

/* 43.Profile Page */
.social-header {
  width: 100%;
  height: 380px;
  object-fit: cover;
  max-height: 380px; }

.social-profile-img {
  height: 120px;
  border: 3px solid white;
  position: absolute;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
  top: -75px; }

.social-icons ul {
  margin-bottom: 0; }

.social-icons li {
  margin-left: 0.5rem;
  margin-right: 0.5rem; }
  .social-icons li a {
    color: #8f8f8f;
    font-size: 1.2rem; }
    .social-icons li a:hover, .social-icons li a:active {
      color: rgba(143, 143, 143, 0.7); }

.post-icon i {
  font-size: 1.1rem;
  vertical-align: middle; }

.post-icon span {
  display: inline-block;
  padding-top: 3px;
  vertical-align: middle; }

.comment-likes {
  text-align: right;
  flex-basis: 90px;
  flex-shrink: 0; }

.comment-contaiener input {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding-left: 20px; }

.social-image-row [class*="col-"] {
  margin-bottom: 1rem; }

.social-image-row [class*="col-"]:nth-last-child(-n + 2) {
  margin-bottom: 0; }

.social-image-row [class*="col-"]:nth-child(odd) {
  padding-right: 0.5rem; }

.social-image-row [class*="col-"]:nth-child(even) {
  padding-left: 0.5rem; }

.gallery-page.row {
  margin-left: -5px;
  margin-right: -5px; }
  .gallery-page.row [class*="col-"] {
    margin-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px; }

.col-left {
  padding-right: 20px; }
  @media (max-width: 991px) {
    .col-left {
      padding-right: 15px; } }

.col-right {
  padding-left: 20px; }
  @media (max-width: 991px) {
    .col-right {
      padding-left: 15px; } }

/* 44.Glide */
.glide__slides {
  white-space: initial; }
  .glide__slides .glide__slide {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px; }

.glide__slide {
  display: flex;
  height: unset; }
  .glide__slide .card {
    display: flex;
    flex-grow: 1; }
    .glide__slide .card .w-50 {
      display: flex; }
    .glide__slide .card .card-body {
      flex-direction: column;
      display: flex;
      justify-content: space-between; }

.slider-nav {
  text-align: center; }
  .slider-nav .left-arrow,
  .slider-nav .right-arrow {
    padding-top: 7px;
    font-size: 20px;
    color: #00365a;
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px;
    border: initial;
    position: initial;
    transform: initial;
    top: initial;
    left: initial;
    right: initial;
    padding-left: 5px;
    padding-right: 5px; }
  .slider-nav .glide__arrow {
    padding-top: 10px; }
  .slider-nav .slider-dot-container {
    display: inline-block;
    position: initial;
    text-align: center;
    transform: initial;
    left: initial; }
  .slider-nav .btn:hover, .slider-nav .btn:focus, .slider-nav .btn:active {
    text-decoration: initial; }

.slider-dot {
  width: 6px;
  height: 6px;
  border-radius: 10px;
  background: #d7d7d7;
  outline: initial !important;
  border: initial;
  margin: 0 3px;
  padding: 0;
  cursor: pointer;
  box-shadow: initial; }
  .slider-dot.glide__bullet:hover, .slider-dot.glide__bullet:focus {
    background: #d7d7d7;
    border: initial; }
  .slider-dot.glide__bullet--active {
    background: #00365a !important; }

.glide .card-img {
  width: 100%;
  height: auto; }

.glide.details img {
  margin-bottom: 0;
  object-fit: cover; }

.glide.details .glide__slides {
  margin-bottom: 0; }

.glide.details .glide__slide {
  padding: initial; }

.glide.thumbs {
  cursor: pointer;
  width: 340px;
  margin: 0 auto; }
  @media (max-width: 767px) {
    .glide.thumbs {
      width: 300px; } }
  @media (max-width: 575px) {
    .glide.thumbs {
      width: calc(100% - 80px); } }
  .glide.thumbs .glide__slide {
    padding: initial; }
  .glide.thumbs li {
    text-align: center;
    opacity: 0.4;
    transition: opacity 200ms;
    height: 45px;
    object-fit: cover; }
    .glide.thumbs li.active, .glide.thumbs li:hover {
      opacity: 1; }
    .glide.thumbs li img {
      width: 60px;
      margin-bottom: 0; }
  .glide.thumbs .glide__slides {
    margin-bottom: 0; }
  .glide.thumbs .glide__arrows {
    width: 100%; }
    .glide.thumbs .glide__arrows .glide__arrow--left {
      border: initial;
      background: initial;
      box-shadow: initial;
      top: 20px;
      left: -50px;
      font-size: 20px;
      color: #00365a; }
    .glide.thumbs .glide__arrows .glide__arrow--right {
      border: initial;
      background: initial;
      box-shadow: initial;
      top: 20px;
      right: -50px;
      font-size: 20px;
      color: #00365a; }

.glide.details img {
  width: 100%; }
  @media (max-width: 991px) {
    .glide.details img {
      max-height: 500px; } }
  @media (max-width: 767px) {
    .glide.details img {
      max-height: 400px; } }
  @media (max-width: 575px) {
    .glide.details img {
      max-height: 300px; } }
  @media (max-width: 419px) {
    .glide.details img {
      max-height: 200px; } }

/* 46.Blog */
.listing-card-container .listing-heading {
  height: 45px;
  overflow: hidden; }

.listing-card-container .listing-desc {
  height: 60px;
  overflow: hidden;
  line-height: 1.4; }
  @media (max-width: 1199px) {
    .listing-card-container .listing-desc {
      height: 60px; } }

/* 47.Rounded */
.rounded .alert-primary.animated {
  border-radius: 0.75rem; }

.rounded .popover,
.rounded .tooltip-inner {
  border-radius: 0.75rem; }

.rounded .border-radius {
  border-radius: 0.75rem; }

.rounded .vjs-poster,
.rounded .vjs-tech {
  border-radius: 0.75rem; }

.rounded .dashboard-top-rated .glide img {
  border-radius: 0.75rem; }

.rounded .app-menu {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem; }

.rounded .sub-menu {
  border-radius: 0 0.75rem 0.75rem 0; }

.rounded .auth-card .image-side {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem; }
  @media (max-width: 991px) {
    .rounded .auth-card .image-side {
      border-bottom-right-radius: initial;
      border-bottom-left-radius: initial;
      border-top-left-radius: 0.75rem;
      border-top-right-radius: 0.75rem; } }

.rounded .card-img,
.rounded .card-img-fluid,
.rounded .card-img-overlay {
  border-radius: 0.75rem; }

.rounded.sub-hidden .main-menu,
.rounded.menu-sub-hidden .main-menu {
  border-radius: 0 0.75rem 0.75rem 0; }

.rounded.sub-show-temporary .main-menu {
  border-radius: initial; }

.rounded .dropdown-menu {
  border-radius: 0.75rem; }

.rounded .btn.default {
  border-radius: 0.1rem; }

.rounded .list-thumbnail {
  border-radius: 0.75rem; }

.rounded .card-img-bottom,
.rounded .card-img-bottom .vjs-poster,
.rounded .card-img-bottom .vjs-tech {
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  border-top-left-radius: initial;
  border-top-right-radius: initial; }

.rounded .card-img-top,
.rounded .card-img-top .vjs-poster,
.rounded .card-img-top .vjs-tech {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  border-bottom-left-radius: initial;
  border-bottom-right-radius: initial; }

.rounded .card-img-left {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
  border-top-right-radius: initial !important;
  border-bottom-right-radius: initial !important; }

.rounded .card-img-right {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  border-top-left-radius: initial !important;
  border-bottom-left-radius: initial !important; }

.rounded .card {
  border-radius: 0.75rem; }

.rounded .card.auth-card {
  border-top-left-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem; }
  @media (max-width: 991px) {
    .rounded .card.auth-card {
      border-top-left-radius: 1.25rem;
      border-top-right-radius: 1.25rem;
      border-bottom-right-radius: 0.75rem;
      border-bottom-left-radius: 0.75rem; } }

.rounded .invalid-tooltip,
.rounded .valid-tooltip,
.rounded div.error {
  border-radius: 10px; }

.rounded .data-table-rows table tbody tr {
  border-radius: 0.75rem; }

.rounded .data-table-rows table td {
  background: white; }
  .rounded .data-table-rows table td:first-child {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem; }
  .rounded .data-table-rows table td:last-child {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem; }

/* 48.Rtl */
.rtl {
  direction: rtl; }
  .rtl .text-center p,
  .rtl .text-center div,
  .rtl .text-center span,
  .rtl .text-center i,
  .rtl .text-center a,
  .rtl .text-center h1,
  .rtl .text-center h2,
  .rtl .text-center h3,
  .rtl .text-center h4,
  .rtl .text-center h5,
  .rtl .text-center h6 {
    text-align: center; }
  .rtl .list-unstyled {
    padding-right: 0; }
  .rtl .breadcrumb-item + .breadcrumb-item {
    padding-left: initial;
    padding-right: 0.5rem; }
    .rtl .breadcrumb-item + .breadcrumb-item::before {
      padding-left: 0.5rem;
      padding-right: initial; }
  .rtl .glide__arrow.glide__arrow--left .simple-icon-arrow-left:before {
    content: "\e605"; }
  .rtl .glide__arrow.glide__arrow--right .simple-icon-arrow-right:before {
    content: "\e606"; }
  .rtl .dropdown-menu:not(.datepicker-dropdown) {
    right: initial !important; }
  .rtl .dropdown-menu-right {
    right: initial !important;
    left: 0 !important; }
  .rtl .dropdown-menu-left {
    right: 0 !important;
    left: initial !important; }
  .rtl .btn-group > .btn:first-child {
    margin-left: -1px; }
  .rtl .top-right-button-container {
    float: left; }
    @media (max-width: 575px) {
      .rtl .top-right-button-container {
        float: initial; } }
  .rtl .col-left {
    padding-left: 20px;
    padding-right: 15px; }
    @media (max-width: 991px) {
      .rtl .col-left {
        padding-left: 15px; } }
  .rtl .col-right {
    padding-right: 20px;
    padding-left: 15px; }
    @media (max-width: 991px) {
      .rtl .col-right {
        padding-right: 15px; } }
  .rtl .search-sm::after {
    left: 7px;
    right: initial;
    text-align: initial;
    top: 3px; }
  .rtl .newsletter-input-container input {
    border-top-left-radius: 0.1rem;
    border-bottom-left-radius: 0.1rem;
    border-top-right-radius: 50px !important;
    border-bottom-right-radius: 50px !important; }
  .rtl .custom-control-label::after,
  .rtl .custom-control-label::before {
    left: initial;
    right: -1.5rem; }
  .rtl .context-menu-item span {
    margin-left: initial;
    margin-right: 0.5rem; }
  .rtl .typeahead.dropdown-menu {
    right: 0 !important; }
  .rtl .typeahead-container .btn {
    border-radius: 0.1rem !important; }
  .rtl .typeahead-container .input-group-append {
    margin-left: 0;
    margin-right: -1px; }
  .rtl .dropdown-toggle-split:after,
  .rtl .dropright .dropdown-toggle-split:after,
  .rtl .dropup .dropdown-toggle-split:after {
    margin-left: 2px;
    margin-right: 2px; }
  .rtl .input-group
> .input-group-append:last-child
> .btn:not(:last-child):not(.dropdown-toggle),
  .rtl .input-group
> .input-group-append:last-child
> .input-group-text:not(:last-child),
  .rtl .input-group > .input-group-append:not(:last-child) > .btn,
  .rtl .input-group > .input-group-append:not(:last-child) > .input-group-text,
  .rtl .input-group > .input-group-prepend > .btn,
  .rtl .input-group > .input-group-prepend > .input-group-text {
    border-radius: 0.1rem; }
  .rtl .btn-group.dropleft .dropdown-toggle-split {
    border-radius: 0.1rem; }
  .rtl .fc-toolbar.fc-header-toolbar .btn-group .fc-next-button.btn,
  .rtl .fc-toolbar.fc-header-toolbar .btn-group .fc-prev-button.btn {
    border-radius: 40px !important; }
  .rtl .input-group-append .btn + .btn,
  .rtl .input-group-append .btn + .input-group-text,
  .rtl .input-group-append .input-group-text + .btn,
  .rtl .input-group-append .input-group-text + .input-group-text,
  .rtl .input-group-prepend .btn + .btn,
  .rtl .input-group-prepend .btn + .input-group-text,
  .rtl .input-group-prepend .input-group-text + .btn,
  .rtl .input-group-prepend .input-group-text + .input-group-text {
    margin-left: 0px;
    margin-right: -1px; }
  .rtl .input-group > .input-group-append > .btn,
  .rtl .input-group > .input-group-append > .input-group-text,
  .rtl .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  .rtl .input-group
> .input-group-prepend:first-child
> .input-group-text:not(:first-child),
  .rtl .input-group > .input-group-prepend:not(:first-child) > .btn,
  .rtl .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
    border-radius: 0.1rem; }
  .rtl .input-group-text {
    border-radius: 0.1rem !important; }
  .rtl .input-group-prepend {
    margin-right: 0;
    margin-left: -1px; }
  .rtl .input-group
> .input-group-append:last-child
> .btn:not(:last-child):not(.dropdown-toggle),
  .rtl .input-group
> .input-group-append:last-child
> .input-group-text:not(:last-child),
  .rtl .input-group > .input-group-append:not(:last-child) > .btn,
  .rtl .input-group > .input-group-append:not(:last-child) > .input-group-text,
  .rtl .input-group > .input-group-prepend > .btn,
  .rtl .input-group > .input-group-prepend > .input-group-text {
    border-radius: 50px; }
  .rtl .input-group > .input-group-append > .btn,
  .rtl .input-group > .input-group-append > .input-group-text,
  .rtl .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  .rtl .input-group
> .input-group-prepend:first-child
> .input-group-text:not(:first-child),
  .rtl .input-group > .input-group-prepend:not(:first-child) > .btn,
  .rtl .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
    border-radius: 50px; }
  .rtl .input-group
> .input-group-append:last-child
> .btn:not(:last-child):not(.dropdown-toggle),
  .rtl .input-group
> .input-group-append:last-child
> .input-group-text:not(:last-child),
  .rtl .input-group > .input-group-append:not(:last-child) > .btn,
  .rtl .input-group > .input-group-append:not(:last-child) > .input-group-text,
  .rtl .input-group > .input-group-prepend > .btn,
  .rtl .input-group > .input-group-prepend > .input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .rtl .input-group > .input-group-prepend:first-child > .btn:not(:first-child) {
    border-radius: 0 !important; }
  .rtl .input-group > .input-group-append > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .rtl .input-group
> .input-group-append:last-child
> .btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .rtl .sw-main.sw-theme-check > ul.step-anchor > li > a:after,
  .rtl .sw-main.sw-theme-dots > ul.step-anchor > li > a:after {
    left: 0;
    right: 50%;
    transform: translateX(50%); }
  .rtl .tooltip-label-right .invalid-tooltip::before,
  .rtl .tooltip-label-right .valid-tooltip::before {
    left: initial;
    right: -5px;
    border-right: initial;
    border-left: solid 5px #00365a; }
  .rtl .tooltip-label-right .invalid-tooltip::after,
  .rtl .tooltip-label-right .valid-tooltip::after {
    right: -4px;
    left: initial;
    border-left: solid 5px white;
    border-right: initial; }
  .rtl .tooltip-label-right .invalid-tooltip,
  .rtl .tooltip-label-right .valid-tooltip {
    left: initial;
    right: 50px; }
  .rtl .tooltip-label-right .invalid-tooltip:after,
  .rtl .tooltip-label-right .valid-tooltip:after,
  .rtl .tooltip-label-right div.error:after {
    left: auto;
    right: -9px;
    border-right: 5px solid transparent;
    border-left: 5px solid white; }
  .rtl .tooltip-label-right .invalid-tooltip:before,
  .rtl .tooltip-label-right .valid-tooltip:before,
  .rtl .tooltip-label-right div.error:before {
    left: auto;
    right: -10px;
    border-right: 5px solid transparent;
    border-left: 5px solid #00365a; }
  .rtl .tooltip-label-right .invalid-tooltip,
  .rtl .tooltip-label-right .valid-tooltip,
  .rtl .tooltip-label-right div.error {
    left: initial;
    right: 50px; }
  .rtl .tooltip-label-right .error-l-0 .invalid-tooltip,
  .rtl .tooltip-label-right .error-l-0 .valid-tooltip,
  .rtl .tooltip-label-right .error-l-0 div.error {
    right: 0px;
    left: initial; }
  .rtl .tooltip-label-right .error-l-25 .invalid-tooltip,
  .rtl .tooltip-label-right .error-l-25 .valid-tooltip,
  .rtl .tooltip-label-right .error-l-25 div.error {
    left: initial;
    right: 25px; }
  .rtl .tooltip-label-right .error-l-50 .invalid-tooltip,
  .rtl .tooltip-label-right .error-l-50 .valid-tooltip,
  .rtl .tooltip-label-right .error-l-50 div.error {
    left: initial;
    right: 50px; }
  .rtl .tooltip-label-right .error-l-75 .invalid-tooltip,
  .rtl .tooltip-label-right .error-l-75 .valid-tooltip,
  .rtl .tooltip-label-right .error-l-75 div.error {
    left: initial;
    right: 75px; }
  .rtl .tooltip-label-right .error-l-100 .invalid-tooltip,
  .rtl .tooltip-label-right .error-l-100 .valid-tooltip,
  .rtl .tooltip-label-right .error-l-100 div.error {
    left: initial;
    right: 100px; }
  .rtl .tooltip-label-right .error-l-125 .invalid-tooltip,
  .rtl .tooltip-label-right .error-l-125 .valid-tooltip,
  .rtl .tooltip-label-right .error-l-125 div.error {
    left: initial;
    right: 125px; }
  .rtl .tooltip-label-right .error-l-150 .invalid-tooltip,
  .rtl .tooltip-label-right .error-l-150 .valid-tooltip,
  .rtl .tooltip-label-right .error-l-150 div.error {
    left: initial;
    right: 150px; }
  .rtl .tooltip-label-right .error-l-175 .invalid-tooltip,
  .rtl .tooltip-label-right .error-l-175 .valid-tooltip,
  .rtl .tooltip-label-right .error-l-175 div.error {
    left: initial;
    right: 175px; }
  .rtl .tooltip-label-right .error-l-200 .invalid-tooltip,
  .rtl .tooltip-label-right .error-l-200 .valid-tooltip,
  .rtl .tooltip-label-right .error-l-200 div.error {
    left: initial;
    right: 200px; }
  .rtl .tooltip-label-right .error-l-225 .invalid-tooltip,
  .rtl .tooltip-label-right .error-l-225 .valid-tooltip,
  .rtl .tooltip-label-right .error-l-225 div.error {
    left: initial;
    right: 225px; }
  .rtl .tooltip-label-right .error-l-250 .invalid-tooltip,
  .rtl .tooltip-label-right .error-l-250 .valid-tooltip,
  .rtl .tooltip-label-right .error-l-250 div.error {
    left: initial;
    right: 250px; }
  .rtl .tooltip-label-right .error-l-275 .invalid-tooltip,
  .rtl .tooltip-label-right .error-l-275 .valid-tooltip,
  .rtl .tooltip-label-right .error-l-275 div.error {
    left: initial;
    right: 275px; }
  .rtl .invalid-icon,
  .rtl .valid-icon {
    right: initial;
    left: 4px; }
  .rtl table.dataTable thead > tr > th.sorting_asc,
  .rtl table.dataTable thead > tr > th.sorting_desc,
  .rtl table.dataTable thead > tr > th.sorting,
  .rtl table.dataTable thead > tr > td.sorting_asc,
  .rtl table.dataTable thead > tr > td.sorting_desc,
  .rtl table.dataTable thead > tr > td.sorting {
    padding-right: 0;
    padding-left: 30px; }
  .rtl table.dataTable thead .sorting:before,
  .rtl table.dataTable thead .sorting_asc:before,
  .rtl table.dataTable thead .sorting_asc_disabled:before,
  .rtl table.dataTable thead .sorting_desc:before,
  .rtl table.dataTable thead .sorting_desc_disabled:before {
    right: initial;
    left: 2em; }
  .rtl table.dataTable thead .sorting:after,
  .rtl table.dataTable thead .sorting_asc:after,
  .rtl table.dataTable thead .sorting_asc_disabled:after,
  .rtl table.dataTable thead .sorting_desc:after,
  .rtl table.dataTable thead .sorting_desc_disabled:after {
    right: initial;
    left: 1.5em; }
  .rtl .ck-editor {
    direction: rtl; }
  .rtl .btn-group > .btn:not(:first-child),
  .rtl .btn-group > .btn-group:not(:first-child) > .btn {
    border-radius: initial; }
  .rtl .btn-group > .btn:first-child {
    border-top-right-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important; }
  .rtl .btn-group > .btn:last-of-type {
    border-top-left-radius: 50px !important;
    border-bottom-left-radius: 50px !important; }
  .rtl .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    right: initial;
    left: 0; }
  .rtl .datepicker-dropdown {
    right: initial; }
  .rtl .card .card-header .card-icon {
    left: 10px;
    right: initial; }
  .rtl .custom-control {
    padding-right: 1.5rem;
    padding-left: inherit;
    margin-right: inherit;
    margin-left: initial; }
  .rtl .pagination .page-link.prev i::before {
    content: "\e606"; }
  .rtl .pagination .page-link.next i::before {
    content: "\e605"; }
  .rtl .pagination .page-link.first i::before {
    content: "\e074"; }
  .rtl .pagination .page-link.last i::before {
    content: "\e06f"; }
  .rtl .nav,
  .rtl .pagination {
    padding-right: 0; }
  .rtl .nav-tabs.separator-tabs .nav-link {
    margin-left: 1.5rem;
    margin-right: 0; }
  .rtl .heading-number {
    margin-right: 0;
    margin-left: 10px; }
  .rtl .card-top-buttons {
    right: initial;
    left: 0; }
  .rtl .comment-likes {
    text-align: left; }
  .rtl .comment-contaiener input {
    border-top-left-radius: initial;
    border-bottom-left-radius: initial;
    border-top-right-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
    padding-left: inherit;
    padding-right: 20px; }
  .rtl .social-image-row [class*="col-"]:nth-child(odd) {
    padding-left: 0.5rem;
    padding-right: 15px; }
  .rtl .social-image-row [class*="col-"]:nth-child(even) {
    padding-left: 15px;
    padding-right: 0.5rem; }
  .rtl .app-row {
    padding-right: initial;
    padding-left: 280px; }
    @media (max-width: 1199px) {
      .rtl .app-row {
        padding-left: 0; } }
  .rtl .app-menu {
    left: 0;
    right: initial; }
    @media (max-width: 1199px) {
      .rtl .app-menu {
        transform: translateX(-280px); } }
    .rtl .app-menu.shown {
      transform: translateX(0); }
    .rtl .app-menu .app-menu-button {
      left: calc(280px - 2px);
      box-shadow: 4px 0 5px rgba(0, 0, 0, 0.04); }
  .rtl .fc-toolbar.fc-header-toolbar .fc-prev-button {
    margin-right: 0; }
  .rtl .fc-toolbar.fc-header-toolbar .fc-next-button {
    margin-right: 5px; }
  .rtl .float-right {
    float: left !important; }
  .rtl .float-left {
    float: right !important; }
  @media (min-width: 1439px) {
    .rtl .float-xxl-left {
      float: right !important; }
    .rtl .float-xxl-right {
      float: left !important; } }
  @media (min-width: 1199px) {
    .rtl .float-xl-left {
      float: right !important; }
    .rtl .float-xl-right {
      float: left !important; } }
  @media (min-width: 991px) {
    .rtl .float-lg-left {
      float: right !important; }
    .rtl .float-lg-right {
      float: left !important; } }
  @media (min-width: 767px) {
    .rtl .float-md-left {
      float: right !important; }
    .rtl .float-md-right {
      float: left !important; } }
  @media (min-width: 575px) {
    .rtl .float-sm-left {
      float: right !important; }
    .rtl .float-sm-right {
      float: left !important; } }
  @media (min-width: 419px) {
    .rtl .float-xs-left {
      float: right !important; }
    .rtl .float-xs-right {
      float: left !important; } }
  .rtl .badge.badge-top-left {
    left: initial;
    right: -7px; }
  .rtl .badge.badge-top-left-2 {
    left: initial;
    right: -7px; }
  .rtl .badge.badge-top-right {
    left: -7px;
    right: initial; }
  .rtl .badge.badge-top-right-2 {
    left: -7px;
    right: initial; }
  .rtl .r-0 {
    left: 0;
    right: initial; }
  .rtl .form-check {
    padding-left: 0;
    padding-right: 1.25rem; }
  .rtl .has-float-label label,
  .rtl .has-float-label > span:last-of-type {
    right: 0.75rem;
    left: initial; }
  .rtl .has-top-label label,
  .rtl .has-top-label > span:last-of-type {
    right: 0.75rem;
    left: initial; }
  .rtl .select2-selection__arrow {
    right: initial;
    left: 12px; }
  .rtl .nav-pills .nav-link {
    text-align: center; }
  .rtl .custom-switch .custom-switch-input + .custom-switch-btn:after {
    left: 0; }
  .rtl .custom-switch .custom-switch-input:checked + .custom-switch-btn:after {
    left: -28px; }
  .rtl .custom-switch-small
.custom-switch-input:checked
+ .custom-switch-btn:after {
    left: -18px; }
  .rtl .bootstrap-tagsinput .tag {
    padding-right: 10px;
    padding-left: 20px; }
    .rtl .bootstrap-tagsinput .tag span {
      margin-left: 0px;
      position: relative; }
      .rtl .bootstrap-tagsinput .tag span:after {
        position: absolute;
        top: -2px;
        left: -15px; }
  .rtl .ps__rail-y {
    right: initial !important;
    left: 0 !important; }
  .rtl .scroll {
    margin-left: -15px;
    padding-left: 15px;
    margin-right: 0;
    padding-right: 0; }
  .rtl .modal .modal-header,
  .rtl .modal .modal-body,
  .rtl .modal .modal-footer {
    padding: 1.75rem; }
    @media (max-width: 575px) {
      .rtl .modal .modal-header,
      .rtl .modal .modal-body,
      .rtl .modal .modal-footer {
        padding: 1.5rem; } }
  .rtl .select-from-library .modal-body.scroll {
    margin-left: 0; }
    .rtl .select-from-library .modal-body.scroll .ps__rail-y {
      left: 10px !important; }
  .rtl .select-from-library .sfl-item-container .card-body {
    padding-right: 1.75rem !important; }
    @media (max-width: 575px) {
      .rtl .select-from-library .sfl-item-container .card-body {
        padding: 1.5rem !important; } }
  .rtl .select-from-library .sfl-item-container .custom-control {
    padding-left: 0.25rem !important; }
  .rtl .menu .sub-menu .scroll .ps__thumb-y,
  .rtl .menu .main-menu .scroll .ps__thumb-y {
    right: -4px; }
  .rtl .dropzone .dz-preview.dz-image-preview .dz-progress {
    right: 50%;
    left: initial;
    transform: translateX(50%); }
  .rtl .dropzone .dz-preview.dz-file-preview .remove,
  .rtl .dropzone .dz-preview.dz-image-preview .remove {
    right: initial;
    left: 5px; }
  .rtl .chart {
    direction: ltr; }
    .rtl .chart canvas {
      direction: rtl; }
  .rtl .noUi-horizontal .noUi-origin {
    left: initial !important;
    right: initial !important; }
  .rtl .icon-button {
    text-align: center; }
  .rtl .menu {
    right: 0;
    left: initial; }
    .rtl .menu .main-menu {
      left: initial;
      right: 0; }
      .rtl .menu .main-menu ul li {
        position: relative; }
        .rtl .menu .main-menu ul li.active:after {
          content: " ";
          left: initial;
          right: 0; }
    .rtl .menu .sub-menu {
      left: initial;
      right: 120px;
      border-right: 1px solid #f3f3f3;
      box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1); }
      .rtl .menu .sub-menu ul li {
        margin-left: initial;
        margin-right: 30px; }
        .rtl .menu .sub-menu ul li i {
          margin-right: initial;
          margin-left: 10px; }
        .rtl .menu .sub-menu ul li.active:after {
          left: initial;
          right: -16px; }
      .rtl .menu .sub-menu ul.inner-level-menu li.active:after {
        left: initial;
        right: -22px; }
      @media (max-width: 1439px) {
        .rtl .menu .sub-menu {
          left: initial;
          right: 110px; } }
      @media (max-width: 1199px) {
        .rtl .menu .sub-menu {
          left: initial;
          right: 100px; } }
      @media (max-width: 767px) {
        .rtl .menu .sub-menu {
          left: initial;
          right: 90px; } }
  .rtl main {
    margin-right: 410px;
    margin-left: 60px !important;
    overflow: initial !important; }
    .rtl main.default-transition {
      transition: margin-right 300ms; }
    @media (max-width: 1439px) {
      .rtl main {
        margin-right: 390px;
        margin-left: 50px !important; } }
    @media (max-width: 1199px) {
      .rtl main {
        margin-right: 370px;
        margin-left: 40px !important; } }
    @media (max-width: 767px) {
      .rtl main {
        margin-right: 15px !important;
        margin-left: 15px !important; } }
    @media (max-width: 575px) {
      .rtl main {
        margin-bottom: 0; } }
  .rtl .navbar .navbar-right {
    text-align: left; }
  .rtl .navbar .menu-button-mobile {
    margin-right: 15px;
    margin-left: initial; }
  .rtl .navbar .menu-button {
    text-align: center; }
  .rtl .navbar .icon-menu-item {
    text-align: center; }
    .rtl .navbar .icon-menu-item i {
      text-align: center; }
    .rtl .navbar .icon-menu-item span {
      text-align: center; }
  .rtl .navbar .search .search-icon {
    right: initial;
    left: 5px; }
  @media (max-width: 767px) {
    .rtl .navbar .search {
      margin-right: 0.6rem;
      margin-left: initial; }
      .rtl .navbar .search input {
        display: none; }
      .rtl .navbar .search.mobile-view {
        margin-left: initial;
        margin-right: 15px; }
        .rtl .navbar .search.mobile-view input {
          display: block;
          width: 100%;
          height: 70px;
          padding-left: 0; }
        .rtl .navbar .search.mobile-view span {
          left: 15px;
          right: initial; } }
  .rtl .navbar .navbar-right {
    text-align: left; }
    .rtl .navbar .navbar-right .user {
      margin-left: 60px;
      margin-right: 0; }
      @media (max-width: 1439px) {
        .rtl .navbar .navbar-right .user {
          margin-left: 50px;
          margin-right: 0; } }
      @media (max-width: 1199px) {
        .rtl .navbar .navbar-right .user {
          margin-left: 40px;
          margin-right: 0; } }
      @media (max-width: 767px) {
        .rtl .navbar .navbar-right .user {
          margin-left: 15px;
          margin-right: 0; } }
      .rtl .navbar .navbar-right .user img {
        margin-right: 10px;
        margin-left: initial; }
    .rtl .navbar .navbar-right .header-icons {
      margin-right: initial;
      margin-left: 0.5rem; }
      @media (max-width: 575px) {
        .rtl .navbar .navbar-right .header-icons {
          margin-left: 0; } }
    .rtl .navbar .navbar-right #notificationButton .count {
      text-align: center; }
      @media (max-width: 575px) {
        .rtl .navbar .navbar-right #notificationButton .count {
          left: -1px; } }
    .rtl .navbar .navbar-right #userDropdown,
    .rtl .navbar .navbar-right #userDropdown * {
      text-align: left; }
  .rtl .heading-icon {
    margin-right: initial;
    margin-left: 5px; }
  .rtl .survey-app .answers input {
    padding-right: 0.75rem;
    padding-left: 70px; }
  .rtl .input-icons {
    left: 0;
    right: initial; }
  .rtl .simple-icon-arrow-left:before {
    content: "\e606"; }
  .rtl .simple-icon-arrow-right:before {
    content: "\e605"; }
  .rtl#app-container.sub-hidden .sub-menu,
  .rtl#app-container.menu-sub-hidden .sub-menu,
  .rtl#app-container.menu-hidden .sub-menu {
    transform: translateX(230px); }
    @media (max-width: 1439px) {
      .rtl#app-container.sub-hidden .sub-menu,
      .rtl#app-container.menu-sub-hidden .sub-menu,
      .rtl#app-container.menu-hidden .sub-menu {
        transform: translateX(230px); } }
    @media (max-width: 1199px) {
      .rtl#app-container.sub-hidden .sub-menu,
      .rtl#app-container.menu-sub-hidden .sub-menu,
      .rtl#app-container.menu-hidden .sub-menu {
        transform: translateX(230px); } }
    @media (max-width: 767px) {
      .rtl#app-container.sub-hidden .sub-menu,
      .rtl#app-container.menu-sub-hidden .sub-menu,
      .rtl#app-container.menu-hidden .sub-menu {
        transform: translateX(230px); } }
  .rtl#app-container.main-hidden .main-menu,
  .rtl#app-container.menu-hidden .main-menu {
    transform: translateX(120px); }
    @media (max-width: 1439px) {
      .rtl#app-container.main-hidden .main-menu,
      .rtl#app-container.menu-hidden .main-menu {
        transform: translateX(110px); } }
    @media (max-width: 1199px) {
      .rtl#app-container.main-hidden .main-menu,
      .rtl#app-container.menu-hidden .main-menu {
        transform: translateX(100px); } }
    @media (max-width: 767px) {
      .rtl#app-container.main-hidden .main-menu,
      .rtl#app-container.menu-hidden .main-menu {
        transform: translateX(90px); } }
  .rtl#app-container.main-hidden.sub-hidden .sub-menu,
  .rtl#app-container.menu-hidden .sub-menu {
    transform: translateX(350px); }
    @media (max-width: 1439px) {
      .rtl#app-container.main-hidden.sub-hidden .sub-menu,
      .rtl#app-container.menu-hidden .sub-menu {
        transform: translateX(340px); } }
    @media (max-width: 1199px) {
      .rtl#app-container.main-hidden.sub-hidden .sub-menu,
      .rtl#app-container.menu-hidden .sub-menu {
        transform: translateX(330px); } }
    @media (max-width: 767px) {
      .rtl#app-container.main-hidden.sub-hidden .sub-menu,
      .rtl#app-container.menu-hidden .sub-menu {
        transform: translateX(320px); } }
  .rtl#app-container.menu-main-hidden .main-menu {
    width: 0; }
  .rtl#app-container.menu-main-hidden .sub-menu {
    right: 0; }
  .rtl#app-container.menu-mobile .main-menu {
    transform: translateX(90px); }
  .rtl#app-container.menu-mobile .sub-menu {
    transform: translateX(370px); }
  .rtl#app-container.main-show-temporary .main-menu {
    transform: translateX(0); }
  .rtl#app-container.main-show-temporary .sub-menu {
    transform: translateX(230px); }
  @media (max-width: 1439px) {
    .rtl#app-container.main-show-temporary .main-menu {
      transform: translateX(0); }
    .rtl#app-container.main-show-temporary .sub-menu {
      transform: translateX(230px); } }
  @media (max-width: 1199px) {
    .rtl#app-container.main-show-temporary .main-menu {
      transform: translateX(0); }
    .rtl#app-container.main-show-temporary .sub-menu {
      transform: translateX(230px); } }
  @media (max-width: 767px) {
    .rtl#app-container.main-show-temporary .sub-menu {
      transform: translateX(230px); } }
  .rtl#app-container.sub-show-temporary .sub-menu, .rtl#app-container.menu-mobile.sub-show-temporary .sub-menu, .rtl#app-container.menu-main-hidden.menu-mobile.main-show-temporary .sub-menu {
    transform: translateX(0); }
  .rtl .chat-input-container {
    padding-right: 410px;
    padding-left: 340px; }
    @media (max-width: 1439px) {
      .rtl .chat-input-container {
        padding-right: 390px;
        padding-left: 330px; } }
    @media (max-width: 1199px) {
      .rtl .chat-input-container {
        padding-right: 370px;
        padding-left: 40px; } }
    @media (max-width: 767px) {
      .rtl .chat-input-container {
        padding-right: 15px;
        padding-left: 15px; } }
  .rtl.menu-sub-hidden .chat-input-container, .rtl.sub-hidden .chat-input-container {
    padding-right: 180px; }
    @media (max-width: 1439px) {
      .rtl.menu-sub-hidden .chat-input-container, .rtl.sub-hidden .chat-input-container {
        padding-right: 160px; } }
    @media (max-width: 1199px) {
      .rtl.menu-sub-hidden .chat-input-container, .rtl.sub-hidden .chat-input-container {
        padding-right: 140px; } }
    @media (max-width: 767px) {
      .rtl.menu-sub-hidden .chat-input-container, .rtl.sub-hidden .chat-input-container {
        padding-right: 15px;
        padding-left: 15px; } }
  .rtl.menu-main-hidden .chat-input-container, .rtl.menu-hidden .chat-input-container, .rtl.main-hidden .chat-input-container {
    padding-right: 60px; }
    @media (max-width: 1439px) {
      .rtl.menu-main-hidden .chat-input-container, .rtl.menu-hidden .chat-input-container, .rtl.main-hidden .chat-input-container {
        padding-right: 50px; } }
    @media (max-width: 1199px) {
      .rtl.menu-main-hidden .chat-input-container, .rtl.menu-hidden .chat-input-container, .rtl.main-hidden .chat-input-container {
        padding-right: 40px; } }
    @media (max-width: 767px) {
      .rtl.menu-main-hidden .chat-input-container, .rtl.menu-hidden .chat-input-container, .rtl.main-hidden .chat-input-container {
        padding-right: 15px;
        padding-left: 15px; } }
  .rtl#app-container.sub-hidden main,
  .rtl#app-container.menu-sub-hidden main,
  .rtl#app-container.menu-hidden main {
    margin-right: 180px; }
  .rtl#app-container.main-hidden main,
  .rtl#app-container.menu-hidden main {
    margin-right: 60px; }
  .rtl#app-container.menu-main-hidden main {
    margin-right: 290px; }
  .rtl#app-container.menu-main-hidden.menu-hidden main {
    margin-right: 60px; }
  @media (max-width: 1439px) {
    .rtl#app-container.sub-hidden main,
    .rtl#app-container.menu-sub-hidden main,
    .rtl#app-container.menu-hidden main {
      margin-right: 160px; }
    .rtl#app-container.main-hidden main,
    .rtl#app-container.menu-hidden main {
      margin-right: 50px; }
    .rtl#app-container.menu-main-hidden main {
      margin-right: 280px; }
    .rtl#app-container.menu-main-hidden.menu-hidden main {
      margin-right: 50px; } }
  @media (max-width: 1199px) {
    .rtl#app-container.sub-hidden main,
    .rtl#app-container.menu-sub-hidden main,
    .rtl#app-container.menu-hidden main {
      margin-right: 140px; }
    .rtl#app-container.main-hidden main,
    .rtl#app-container.menu-hidden main {
      margin-right: 40px; }
    .rtl#app-container.menu-main-hidden main {
      margin-right: 270px; }
    .rtl#app-container.menu-main-hidden.menu-hidden main {
      margin-right: 40px; } }
  .rtl .theme-colors {
    transform: translate(-280px, -50%);
    left: 0;
    right: initial; }
    .rtl .theme-colors .theme-button {
      left: 278px;
      box-shadow: 3px 0 5px rgba(0, 0, 0, 0.04); }
    .rtl .theme-colors.shown {
      transform: translate(0, -50%); }
  .rtl.rounded .app-menu {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem; }
  .rtl.rounded .sub-menu {
    border-radius: 0.75rem 0 0 0.75rem; }
  .rtl.rounded .auth-card .image-side {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem; }
  .rtl.rounded .card-img,
  .rtl.rounded .card-img-fluid,
  .rtl.rounded .card-img-overlay {
    border-radius: 0.75rem; }
  .rtl.rounded.sub-hidden .main-menu,
  .rtl.rounded.menu-sub-hidden .main-menu {
    border-radius: 0.75rem 0 0 0.75rem; }
  .rtl.rounded.sub-show-temporary .main-menu {
    border-radius: initial; }
  .rtl.rounded .card-img-left {
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
    border-top-left-radius: initial !important;
    border-bottom-left-radius: initial !important; }
  .rtl.rounded .card-img-right {
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
    border-top-right-radius: initial !important;
    border-bottom-right-radius: initial !important; }
  .rtl.rounded .card.auth-card {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
    border-top-right-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem; }
  .rtl.rounded .auth-card .image-side {
    border-top-left-radius: initial;
    border-bottom-left-radius: initial;
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem; }
  .rtl.rounded .data-table-rows table td {
    border-radius: initial; }
    .rtl.rounded .data-table-rows table td:first-child {
      border-top-right-radius: 0.75rem;
      border-bottom-right-radius: 0.75rem; }
    .rtl.rounded .data-table-rows table td:last-child {
      border-top-left-radius: 0.75rem;
      border-bottom-left-radius: 0.75rem; }
  .rtl.rounded .data-table-rows .data-table-rows-check {
    text-align: left;
    padding-left: initial; }

body {
  min-height: calc(100% - 150px);
  position: relative;
  padding-bottom: 90px; }
  @media (max-width: 1439px) {
    body {
      min-height: calc(100% - 130px); } }
  @media (max-width: 1199px) {
    body {
      min-height: calc(100% - 110px); } }
  @media (max-width: 767px) {
    body {
      min-height: calc(100% - 85px); } }
  @media (max-width: 575px) {
    body {
      padding-bottom: 60px; } }
  body.no-footer {
    padding-bottom: initial; }

footer.page-footer {
  padding-top: 2.2rem;
  padding-bottom: 2.2rem;
  border-top: 1px solid #d7d7d7;
  width: 100%;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 90px; }
  @media (max-width: 575px) {
    footer.page-footer {
      height: 60px;
      padding-top: 1.2rem;
      padding-bottom: 1.2rem; } }
  footer.page-footer .breadcrumb-item + .breadcrumb-item::before {
    color: #00365a; }
  footer.page-footer .container-fluid {
    padding-left: 0;
    padding-right: 0; }
  footer.page-footer .footer-content {
    margin-left: 410px;
    margin-right: 60px; }
    @media (max-width: 1439px) {
      footer.page-footer .footer-content {
        margin-left: 390px;
        margin-right: 50px; } }
    @media (max-width: 1199px) {
      footer.page-footer .footer-content {
        margin-left: 370px;
        margin-right: 40px; } }
    @media (max-width: 767px) {
      footer.page-footer .footer-content {
        margin-left: 15px !important;
        margin-right: 15px !important; } }

.right-menu footer.page-footer {
  padding-right: 280px; }
  @media (max-width: 1199px) {
    .right-menu footer.page-footer {
      padding-right: 0; } }

#app-container.sub-hidden .footer-content,
#app-container.menu-sub-hidden .footer-content,
#app-container.menu-hidden .footer-content {
  margin-left: 180px; }

#app-container.main-hidden .footer-content,
#app-container.menu-hidden .footer-content {
  margin-left: 60px; }

#app-container.menu-main-hidden .footer-content {
  margin-left: 290px; }

#app-container.menu-main-hidden.menu-hidden .footer-content {
  margin-left: 60px; }

@media (max-width: 1439px) {
  #app-container.sub-hidden .footer-content,
  #app-container.menu-sub-hidden .footer-content,
  #app-container.menu-hidden .footer-content {
    margin-left: 160px; }
  #app-container.main-hidden .footer-content,
  #app-container.menu-hidden .footer-content {
    margin-left: 50px; }
  #app-container.menu-main-hidden .footer-content {
    margin-left: 280px; }
  #app-container.menu-main-hidden.menu-hidden .footer-content {
    margin-left: 50px; } }

@media (max-width: 1199px) {
  #app-container.sub-hidden .footer-content,
  #app-container.menu-sub-hidden .footer-content,
  #app-container.menu-hidden .footer-content {
    margin-left: 140px; }
  #app-container.main-hidden .footer-content,
  #app-container.menu-hidden .footer-content {
    margin-left: 40px; }
  #app-container.menu-main-hidden .footer-content {
    margin-left: 270px; }
  #app-container.menu-main-hidden.menu-hidden .footer-content {
    margin-left: 40px; } }

.right-menu-always-hidden .app-menu {
  transform: translateX(280px); }
  .right-menu-always-hidden .app-menu.shown {
    transform: translateX(0); }

.right-menu-always-hidden .app-row,
.right-menu-always-hidden .page-footer {
  padding-right: 0; }

.right-menu-always-hidden footer.page-footer {
  padding-right: initial; }

.right-menu-always-hidden .chat-input-container {
  padding-right: 60px; }
  @media (max-width: 1439px) {
    .right-menu-always-hidden .chat-input-container {
      padding-left: 160px;
      padding-right: 50px; } }
  @media (max-width: 1199px) {
    .right-menu-always-hidden .chat-input-container {
      padding-left: 140px;
      padding-right: 40px; } }
  @media (max-width: 767px) {
    .right-menu-always-hidden .chat-input-container {
      padding-right: 15px;
      padding-left: 15px; } }

.rtl footer.page-footer .footer-content {
  margin-right: 410px;
  margin-left: 60px; }
  @media (max-width: 1439px) {
    .rtl footer.page-footer .footer-content {
      margin-right: 390px;
      margin-left: 50px; } }
  @media (max-width: 1199px) {
    .rtl footer.page-footer .footer-content {
      margin-right: 370px;
      margin-left: 40px; } }
  @media (max-width: 767px) {
    .rtl footer.page-footer .footer-content {
      margin-right: 15px !important;
      margin-left: 15px !important; } }

.rtl#app-container.sub-hidden .footer-content,
.rtl#app-container.menu-sub-hidden .footer-content,
.rtl#app-container.menu-hidden .footer-content {
  margin-right: 180px;
  margin-left: 60px; }

.rtl#app-container.main-hidden .footer-content,
.rtl#app-container.menu-hidden .footer-content {
  margin-right: 60px;
  margin-left: 60px; }

.rtl#app-container.menu-main-hidden .footer-content {
  margin-right: 290px;
  margin-left: 60px; }

.rtl#app-container.menu-main-hidden.menu-hidden .footer-content {
  margin-right: 60px;
  margin-left: 60px; }

@media (max-width: 1439px) {
  .rtl#app-container.sub-hidden .footer-content,
  .rtl#app-container.menu-sub-hidden .footer-content,
  .rtl#app-container.menu-hidden .footer-content {
    margin-right: 160px;
    margin-left: 50px; }
  .rtl#app-container.main-hidden .footer-content,
  .rtl#app-container.menu-hidden .footer-content {
    margin-right: 50px;
    margin-left: 50px; }
  .rtl#app-container.menu-main-hidden .footer-content {
    margin-right: 280px;
    margin-left: 50px; }
  .rtl#app-container.menu-main-hidden.menu-hidden .footer-content {
    margin-right: 50px;
    margin-left: 50px; } }

@media (max-width: 1199px) {
  .rtl#app-container.sub-hidden .footer-content,
  .rtl#app-container.menu-sub-hidden .footer-content,
  .rtl#app-container.menu-hidden .footer-content {
    margin-right: 140px;
    margin-left: 40px; }
  .rtl#app-container.main-hidden .footer-content,
  .rtl#app-container.menu-hidden .footer-content {
    margin-right: 40px;
    margin-left: 40px; }
  .rtl#app-container.menu-main-hidden .footer-content {
    margin-right: 270px;
    margin-left: 40px; }
  .rtl#app-container.menu-main-hidden.menu-hidden .footer-content {
    margin-right: 40px;
    margin-left: 40px; } }

.rtl.right-menu footer.page-footer {
  padding-left: 280px;
  padding-right: initial; }
  @media (max-width: 1199px) {
    .rtl.right-menu footer.page-footer {
      padding-left: 0; } }

/* 49.Landingpage */
.landing-page {
  font-size: 1rem;
  line-height: 1.6rem;
  color: #8f8f8f; }
  @media (max-width: 767px) {
    .landing-page {
      font-size: 0.9rem;
      line-height: 1.4rem; } }
  .landing-page .mobile-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    transform: translateX(300px);
    z-index: 25;
    width: 300px;
    background: #f8f8f8;
    box-shadow: initial;
    transition: transform 0.5s, box-shadow 0.5s; }
  .landing-page .logo-mobile {
    display: block;
    text-align: center;
    padding: 3rem 2rem;
    background: none;
    width: unset;
    height: unset; }
    .landing-page .logo-mobile span {
      display: inline-block;
      width: 100px;
      height: 30px;
      /* background: url("../logos/black.svg"); */
      background-repeat: no-repeat; }
  .landing-page .main-container {
    overflow: hidden; }
  .landing-page.show-mobile-menu .mobile-menu {
    transform: translateX(0);
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1); }
  .landing-page.show-mobile-menu .main-container .content-container,
  .landing-page.show-mobile-menu .main-container nav {
    transform: translateX(-300px); }
  .landing-page .content-container {
    transition: transform 0.5s; }
  .landing-page p {
    font-size: 1rem;
    line-height: 1.6rem;
    color: #8f8f8f; }
    @media (max-width: 767px) {
      .landing-page p {
        font-size: 0.9rem;
        line-height: 1.4rem; } }
  .landing-page h1 {
    font-size: 3rem;
    color: #00365a;
    margin-bottom: 2rem; }
    @media (max-width: 767px) {
      .landing-page h1 {
        font-size: 2rem; } }
  .landing-page h2 {
    font-size: 1.8rem;
    color: #00365a;
    margin-bottom: 2rem; }
    @media (max-width: 991px) {
      .landing-page h2 {
        font-size: 1.6rem; } }
    @media (max-width: 767px) {
      .landing-page h2 {
        font-size: 1.2rem; } }
  .landing-page h3 {
    font-size: 1.4rem;
    line-height: 1.4;
    color: #00365a; }
    @media (max-width: 767px) {
      .landing-page h3 {
        font-size: 1.1rem; } }
  .landing-page .semi-rounded {
    border-radius: 1rem !important; }
  .landing-page .dropdown-toggle::after {
    opacity: 0.7; }
  .landing-page .btn-outline-semi-light {
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: #fff;
    background: transparent; }
    .landing-page .btn-outline-semi-light:hover, .landing-page .btn-outline-semi-light:active {
      border: 1px solid #001727;
      background: #001727; }
  .landing-page .landing-page-nav {
    z-index: 5 !important;
    top: 0;
    position: fixed;
    width: 100%;
    background: transparent;
    box-shadow: initial; }
    .landing-page .landing-page-nav nav {
      transition: transform 0.5s; }
    .landing-page .landing-page-nav.headroom {
      transition: top 0.5s, background 0.5s, box-shadow 0.2s, transform 0.5s !important; }
    .landing-page .landing-page-nav .container {
      height: 100px; }
      @media (max-width: 1439px) {
        .landing-page .landing-page-nav .container {
          height: 90px; } }
      @media (max-width: 1199px) {
        .landing-page .landing-page-nav .container {
          height: 80px; }
          .landing-page .landing-page-nav .container .navbar-logo {
            width: 100px;
            height: 30px; }
            .landing-page .landing-page-nav .container .navbar-logo .white,
            .landing-page .landing-page-nav .container .navbar-logo .dark {
              width: 100px;
              height: 30px; } }
      @media (max-width: 767px) {
        .landing-page .landing-page-nav .container {
          height: 70px; } }
    .landing-page .landing-page-nav .btn {
      padding-right: 1.75rem !important;
      padding-left: 1.75rem !important; }
    .landing-page .landing-page-nav .navbar-logo {
      width: 120px;
      height: 40px; }
      .landing-page .landing-page-nav .navbar-logo .white {
        display: inline-block;
        width: 120px;
        height: 40px;
        /* background: url("../logos/white-full.svg"); */
        background-repeat: no-repeat; }
      .landing-page .landing-page-nav .navbar-logo .dark {
        width: 120px;
        height: 40px;
        /* background: url("../logos/black.svg"); */
        background-repeat: no-repeat;
        display: none; }
    .landing-page .landing-page-nav .mobile-menu-button {
      font-size: 20px;
      display: none;
      cursor: pointer; }
      @media (max-width: 991px) {
        .landing-page .landing-page-nav .mobile-menu-button {
          display: block; } }
    .landing-page .landing-page-nav .navbar-nav {
      height: 100%;
      position: relative;
      display: flex;
      align-items: center; }
      .landing-page .landing-page-nav .navbar-nav li {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        position: relative;
        margin-bottom: initial; }
        .landing-page .landing-page-nav .navbar-nav li:last-of-type {
          padding-right: 0; }
        .landing-page .landing-page-nav .navbar-nav li.active::before {
          content: " ";
          background: #fff;
          border-radius: 10px;
          position: absolute;
          width: calc(100% - 1.5rem);
          height: 4px;
          left: 0;
          right: 0;
          margin: 0 auto;
          top: 0; }
        @media (max-width: 1199px) {
          .landing-page .landing-page-nav .navbar-nav li {
            padding-left: 0.85rem;
            padding-right: 0.85rem; }
            .landing-page .landing-page-nav .navbar-nav li:last-of-type {
              padding-right: 0; } }
      .landing-page .landing-page-nav .navbar-nav .nav-item > a,
      .landing-page .landing-page-nav .navbar-nav .nav-item > .dropdown > a {
        color: #fff;
        font-family: Nunito, sans-serif;
        font-size: 1rem;
        display: inline-block;
        margin-top: 0; }
      .landing-page .landing-page-nav .navbar-nav .nav-item:not(.active) > a:hover:not(.btn), .landing-page .landing-page-nav .navbar-nav .nav-item:not(.active) > a:active:not(.btn),
      .landing-page .landing-page-nav .navbar-nav .nav-item:not(.active) .dropdown > a:hover:not(.btn),
      .landing-page .landing-page-nav .navbar-nav .nav-item:not(.active) .dropdown > a:active:not(.btn) {
        opacity: 0.8; }
      .landing-page .landing-page-nav .navbar-nav .dropdown {
        height: 80px;
        position: relative; }
        .landing-page .landing-page-nav .navbar-nav .dropdown:hover > .dropdown-menu {
          display: block; }
        .landing-page .landing-page-nav .navbar-nav .dropdown > .dropdown-toggle:active {
          pointer-events: none; }
        .landing-page .landing-page-nav .navbar-nav .dropdown .dropdown-menu {
          position: absolute;
          margin-top: 0; }
        .landing-page .landing-page-nav .navbar-nav .dropdown a.dropdown-item {
          font-size: 0.8rem;
          color: #3a3a3a;
          padding-bottom: 0.5rem; }
          .landing-page .landing-page-nav .navbar-nav .dropdown a.dropdown-item.active, .landing-page .landing-page-nav .navbar-nav .dropdown a.dropdown-item:active {
            color: #fff; }
  .landing-page .landing-page-nav.headroom--pinned {
    background: white;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04); }
    .landing-page .landing-page-nav.headroom--pinned .navbar-logo .white {
      display: none; }
    .landing-page .landing-page-nav.headroom--pinned .navbar-logo .dark {
      display: inline-block; }
  .landing-page .landing-page-nav.headroom--not-top .navbar-nav a {
    color: #3a3a3a; }
    .landing-page .landing-page-nav.headroom--not-top .navbar-nav a:hover, .landing-page .landing-page-nav.headroom--not-top .navbar-nav a:active {
      color: #00365a; }
  .landing-page .landing-page-nav.headroom--not-top .navbar-nav a.dropdown-item:focus {
    color: #fff; }
  .landing-page .landing-page-nav.headroom--not-top .navbar-nav li.active::before {
    content: " ";
    background-color: #00365a; }
  .landing-page .landing-page-nav.headroom--not-top .navbar-nav .btn-outline-semi-light {
    border: 1px solid rgba(0, 54, 90, 0.3);
    color: #00365a; }
    .landing-page .landing-page-nav.headroom--not-top .navbar-nav .btn-outline-semi-light:hover, .landing-page .landing-page-nav.headroom--not-top .navbar-nav .btn-outline-semi-light:active {
      border: 1px solid #00365a;
      background: #00365a;
      color: #fff; }
  .landing-page .landing-page-nav.headroom--unpinned {
    display: none; }
  .landing-page .landing-page-nav.headroom--top {
    box-shadow: none;
    background: transparent; }
    .landing-page .landing-page-nav.headroom--top .navbar-logo .white {
      display: inline-block; }
    .landing-page .landing-page-nav.headroom--top .navbar-logo .dark {
      display: none; }
  .landing-page .landing-page-nav.headroom--unfixed .mobile-menu-button {
    color: #fff; }
  .landing-page .section {
    margin-bottom: 150px;
    padding-top: 100px;
    padding-bottom: 100px; }
    @media (max-width: 991px) {
      .landing-page .section {
        margin-bottom: 100px;
        padding-top: 60px; } }
    .landing-page .section.home {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 2600 1440'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='862.49' y1='-420.67' x2='1546.24' y2='981.21' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.1' stop-color='%23001627'/%3E%3Cstop offset='0.27' stop-color='%2301253D'/%3E%3Cstop offset='0.63' stop-color='%23014366'/%3E%3Cstop offset='0.88' stop-color='%23006DA3'/%3E%3C/linearGradient%3E%3C/defs%3E%3Ctitle%3Ebackground-home%3C/title%3E%3Cg%3E%3Cpath style='fill:url(%23linear-gradient);' d='M0,0V952.71l497.08,428.77c44.64,38.51,122.25,50,172.46,25.57L2600,467.26V0Z'/%3E%3Cpath style='fill:%23006DA3;opacity:0.3;' d='M624.43,1418.11c-44.33,4.25-94.67-9.44-127.35-37.63L0,952.48v50.82l452.08,389.18C496.69,1431,574.21,1442.48,624.43,1418.11Z'/%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position-y: 0;
      background-color: transparent;
      height: 1440px;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      position: relative;
      background-size: cover; }
      @media (max-width: 575px) {
        .landing-page .section.home {
          height: 1340px; } }
    .landing-page .section.subpage {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 2600 1040'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='929.23' y1='-453.21' x2='1676' y2='1077.89' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.1' stop-color='%23001627'/%3E%3Cstop offset='0.27' stop-color='%2301253D'/%3E%3Cstop offset='0.63' stop-color='%23014366'/%3E%3Cstop offset='0.88' stop-color='%23006DA3'/%3E%3C/linearGradient%3E%3C/defs%3E%3Ctitle%3Ebackground-sub%3C/title%3E%3Cg%3E%3Cpath style='fill:url(%23linear-gradient);' d='M170.66,739.24,522.89,996.51c31.63,23.1,89.75,35.48,129.15,27.5L2166.74,717.1,2600,627.22V0H0V614Z'/%3E%3Cpath style='fill:%23006DA3;opacity:0.3;' d='M442.89,993.51c31.63,23.1,89.75,35.48,129.15,27.5l4.62-.94c-20.5-5.19-39.74-13.32-53.77-23.56L170.66,739.24,0,614v55.73l90.66,66.55Z'/%3E%3C/g%3E%3C/svg%3E");
      background-position-y: -370px;
      background-repeat: no-repeat;
      background-size: auto 1040px;
      height: 670px; }
      @media (max-width: 1199px) {
        .landing-page .section.subpage {
          background-position-y: -450px;
          height: 590px; } }
      @media (max-width: 991px) {
        .landing-page .section.subpage {
          background-position-y: -470px;
          height: 570px; } }
      @media (max-width: 767px) {
        .landing-page .section.subpage {
          background-position-y: -530px;
          height: 510px; } }
      .landing-page .section.subpage .btn-circle.hero-circle-button {
        bottom: initial; }
    .landing-page .section.subpage-long {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 2600 1040'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='929.23' y1='-453.21' x2='1676' y2='1077.89' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.1' stop-color='%23001627'/%3E%3Cstop offset='0.27' stop-color='%2301253D'/%3E%3Cstop offset='0.63' stop-color='%23014366'/%3E%3Cstop offset='0.88' stop-color='%23006DA3'/%3E%3C/linearGradient%3E%3C/defs%3E%3Ctitle%3Ebackground-sub%3C/title%3E%3Cg%3E%3Cpath style='fill:url(%23linear-gradient);' d='M170.66,739.24,522.89,996.51c31.63,23.1,89.75,35.48,129.15,27.5L2166.74,717.1,2600,627.22V0H0V614Z'/%3E%3Cpath style='fill:%23006DA3;opacity:0.3;' d='M442.89,993.51c31.63,23.1,89.75,35.48,129.15,27.5l4.62-.94c-20.5-5.19-39.74-13.32-53.77-23.56L170.66,739.24,0,614v55.73l90.66,66.55Z'/%3E%3C/g%3E%3C/svg%3E");
      height: 1040px;
      background-repeat: no-repeat;
      background-position-y: -200px; }
    .landing-page .section.footer {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 2600 1100'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='-153' y1='642.88' x2='3129.11' y2='642.88' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.1' stop-color='%23001627'/%3E%3Cstop offset='0.27' stop-color='%2301253D'/%3E%3Cstop offset='0.63' stop-color='%23014366'/%3E%3Cstop offset='0.88' stop-color='%23006DA3'/%3E%3C/linearGradient%3E%3ClinearGradient id='linear-gradient-2' y1='264.94' x2='3129.11' y2='264.94' xlink:href='%23linear-gradient'/%3E%3C/defs%3E%3Cg%3E%3Cpath style='fill:%23006DA3;opacity:0.3;' d='M2019.51,188.57c17.85,4.08,35.64,13,52.49,29.43l528,635.74V787.56L2127,218C2092.59,184.43,2054.24,182.23,2019.51,188.57Z'/%3E%3Cpath style='fill:url(%23linear-gradient);' d='M1945,193,0,714v386H2600V853.33L2072,218C2031,178,1984.41,182.53,1945,193Z'/%3E%3Cpath style='fill:url(%23linear-gradient-2);' d='M2308.31,91c-60.24,17.09-77.23,69.94-37.76,117.43l210.94,253.81c26.74,32.19,72.83,54,118.51,59.37V8.25Z'/%3E%3C/g%3E%3C/svg%3E");
      background-color: white;
      background-repeat: no-repeat;
      padding-bottom: 0;
      background-size: cover;
      background-size: auto 1100px;
      padding-top: 340px;
      background-position-y: 150px;
      margin-top: -150px; }
      @media (max-width: 991px) {
        .landing-page .section.footer {
          padding-top: 380px; } }
      @media (max-width: 575px) {
        .landing-page .section.footer {
          padding-top: 380px; } }
  .landing-page .section.background {
    background: white;
    position: relative;
    padding-top: 100px;
    padding-bottom: 100px; }
    @media (max-width: 991px) {
      .landing-page .section.background {
        padding-top: 60px;
        padding-bottom: 60px; } }
    .landing-page .section.background::before {
      content: " ";
      width: 100%;
      height: 70px;
      position: absolute;
      top: -70px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2600 70'%3E%3Cg%3E%3Cpath style='fill:%23FFFFFF' d='M2600,70v-.71C2254.44,26.19,1799.27,0,1300.5,0,801.25,0,345.68,26.24,0,69.42V70Z'/%3E%3C/g%3E%3C/svg%3E");
      background-size: cover;
      background-position: center; }
    .landing-page .section.background::after {
      content: " ";
      width: 100%;
      height: 70px;
      position: absolute;
      bottom: -70px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2600 70'%3E%3Cg%3E%3Cpath style='fill:%23FFFFFF' d='M0,0V.71C345.56,43.81,800.73,70,1299.5,70,1798.75,70,2254.32,43.76,2600,.58V0Z'/%3E%3C/g%3E%3C/svg%3E");
      background-size: cover;
      background-position: center; }
    .landing-page .section.background.background-no-bottom::after {
      content: "";
      background: initial;
      width: initial;
      height: initial; }
  .landing-page .background-white {
    background: #fff; }
  .landing-page .mobile-hero {
    margin-left: 50%;
    transform: translateX(-50%);
    max-width: 135%;
    margin-bottom: 3rem; }
  .landing-page .home-row {
    padding-top: 180px;
    margin-bottom: 70px; }
    @media (max-width: 1439px) {
      .landing-page .home-row {
        padding-top: 120px; } }
    @media (max-width: 991px) {
      .landing-page .home-row {
        padding-top: 110px; } }
  .landing-page .home-text {
    margin-top: 40px; }
    .landing-page .home-text p {
      color: #fff;
      font-size: 1.1rem; }
      @media (max-width: 767px) {
        .landing-page .home-text p {
          font-size: 0.9rem;
          line-height: 1.4rem; } }
    .landing-page .home-text .display-1 {
      font-size: 3rem;
      line-height: 3.2rem;
      margin-bottom: 1.1em;
      color: #fff; }
      @media (max-width: 991px) {
        .landing-page .home-text .display-1 {
          font-size: 1.8rem;
          line-height: 2.5rem; } }
      @media (max-width: 767px) {
        .landing-page .home-text .display-1 {
          font-size: 1.7rem;
          line-height: 2.3rem; } }
      @media (max-width: 575px) {
        .landing-page .home-text .display-1 {
          font-size: 1.6rem;
          line-height: 2.2rem; } }
    @media (max-width: 767px) {
      .landing-page .home-text {
        margin-top: 0; } }
  .landing-page .review-carousel {
    margin-top: 5rem; }
    .landing-page .review-carousel .owl-stage-outer {
      padding-top: 5px; }
  .landing-page .home-carousel {
    cursor: grab; }
    .landing-page .home-carousel ::-moz-selection {
      /* Code for Firefox */
      color: inherit;
      background: inherit; }
    .landing-page .home-carousel ::selection {
      color: inherit;
      background: inherit; }
    .landing-page .home-carousel .card .detail-text {
      padding: 1rem 0.25rem;
      margin-bottom: 0; }
      @media (max-width: 767px) {
        .landing-page .home-carousel .card .detail-text {
          padding: 0.25rem; } }
    @media (max-width: 767px) {
      .landing-page .home-carousel .card .btn-link {
        padding: 0.25rem; } }
  .landing-page i.large-icon {
    font-size: 60px;
    line-height: 110px;
    color: #00365a;
    display: initial; }
    @media (max-width: 767px) {
      .landing-page i.large-icon {
        line-height: 90px; } }
  .landing-page .btn-circle {
    padding: 0;
    width: 50px;
    height: 50px;
    border-radius: 30px;
    text-align: center;
    font-size: 14px; }
  .landing-page .btn-circle.hero-circle-button {
    position: absolute;
    bottom: 80px;
    margin-left: 10px; }
    .landing-page .btn-circle.hero-circle-button i {
      padding-top: 18px;
      display: inline-block; }
  .landing-page .newsletter-input-container {
    margin-top: 5rem; }
    .landing-page .newsletter-input-container .input-group {
      border-radius: 50px;
      box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1); }
    .landing-page .newsletter-input-container input {
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      border-color: transparent !important;
      padding: 0.75rem 1.75rem 0.75rem;
      font-size: 0.9rem; }
    @media (max-width: 767px) {
      .landing-page .newsletter-input-container button {
        padding: 1rem 1.5rem 0.9rem; } }
  .landing-page .footer .footer-content {
    padding-top: 210px; }
    @media (max-width: 767px) {
      .landing-page .footer .footer-content {
        padding-top: 140px; } }
    @media (max-width: 575px) {
      .landing-page .footer .footer-content {
        padding-top: 80px; } }
  .landing-page .footer .footer-circle-button {
    margin-top: 80px;
    margin-right: 20px; }
    @media (max-width: 991px) {
      .landing-page .footer .footer-circle-button {
        margin-top: 110px; } }
    .landing-page .footer .footer-circle-button i {
      padding-top: 15px;
      display: inline-block; }
  .landing-page .footer .footer-logo {
    width: 140px;
    margin-bottom: 70px; }
  .landing-page .footer .footer-menu p {
    color: #fff;
    font-size: 1rem; }
  .landing-page .footer .footer-menu a {
    font-size: 1rem;
    color: #fff; }
    .landing-page .footer .footer-menu a:hover, .landing-page .footer .footer-menu a:focus {
      color: black; }
  .landing-page .footer .footer-menu .collapse-button i {
    font-size: 0.75rem;
    margin-left: 5px; }
  .landing-page .footer .footer-menu .collapse-button:hover, .landing-page .footer .footer-menu .collapse-button:focus {
    color: #fff; }
  .landing-page .footer .separator {
    opacity: 0.3; }
  .landing-page .footer .copyright p {
    color: #fff; }
  .landing-page .footer .social-icons ul {
    margin-bottom: 0; }
  .landing-page .footer .social-icons li {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
    .landing-page .footer .social-icons li a {
      color: #fff;
      font-size: 1.2rem; }
      .landing-page .footer .social-icons li a:hover, .landing-page .footer .social-icons li a:active {
        color: rgba(255, 255, 255, 0.7); }
  .landing-page .feature-image-left {
    float: right; }
  @media (max-width: 767px) {
    .landing-page .feature-image-left,
    .landing-page .feature-image-right {
      margin-left: 50%;
      transform: translateX(-50%);
      max-width: 115%;
      float: initial; } }
  @media (max-width: 767px) {
    .landing-page .heading-team {
      text-align: center; } }
  .landing-page .mobile-menu {
    text-align: center; }
    .landing-page .mobile-menu img {
      width: 100px;
      margin-top: 3rem;
      margin-bottom: 3rem; }
    .landing-page .mobile-menu ul {
      text-align: left; }
      .landing-page .mobile-menu ul li {
        padding: 0.5rem 2.5rem;
        margin-bottom: unset; }
        .landing-page .mobile-menu ul li a {
          font-size: 0.9rem; }
    .landing-page .mobile-menu .dropdown .dropdown-menu {
      position: static !important;
      background: initial;
      border: initial;
      padding: initial;
      margin: initial;
      transform: initial !important;
      margin-top: 0.5rem; }
    .landing-page .mobile-menu .dropdown a.dropdown-item {
      color: #3a3a3a;
      padding-bottom: 0.5rem; }
      .landing-page .mobile-menu .dropdown a.dropdown-item.active, .landing-page .mobile-menu .dropdown a.dropdown-item:active {
        color: #fff; }
  @media (max-width: 991px) {
    .landing-page .side-bar {
      margin-top: 5rem; } }
  .landing-page .side-bar .side-bar-content {
    margin-bottom: 5rem; }
  @media (max-width: 767px) {
    .landing-page .side-bar h2 {
      margin-bottom: 1rem; } }
  .landing-page .listing-card-container {
    background: initial;
    box-shadow: initial; }
    .landing-page .listing-card-container .listing-heading {
      height: 65px;
      overflow: hidden; }
    .landing-page .listing-card-container .listing-desc {
      height: 68px;
      overflow: hidden;
      line-height: 1.4;
      font-size: 1rem; }
      @media (max-width: 1199px) {
        .landing-page .listing-card-container .listing-desc {
          height: 46px; } }
  .landing-page .video-heading {
    height: 38px;
    overflow: hidden; }
  .landing-page .table-heading {
    box-shadow: initial;
    background: initial; }
  .landing-page form.dark-background input:-webkit-autofill {
    color: white !important;
    -webkit-text-fill-color: white !important; }
  .landing-page form.dark-background .has-float-label > span,
  .landing-page form.dark-background .has-float-label label,
  .landing-page form.dark-background .has-top-label > span,
  .landing-page form.dark-background .has-top-label label {
    color: rgba(255, 255, 255, 0.6); }
  .landing-page form.dark-background .bootstrap-tagsinput,
  .landing-page form.dark-background .form-control {
    background: transparent;
    border-color: rgba(255, 255, 255, 0.3);
    color: #fff; }
    .landing-page form.dark-background .bootstrap-tagsinput:focus, .landing-page form.dark-background .bootstrap-tagsinput:active,
    .landing-page form.dark-background .form-control:focus,
    .landing-page form.dark-background .form-control:active {
      border-color: rgba(255, 255, 255, 0.6); }
  .landing-page .has-float-label > span,
  .landing-page .has-float-label label,
  .landing-page .has-top-label > span,
  .landing-page .has-top-label label {
    font-size: 64%; }
  .landing-page .video-js.blog-video {
    width: 100%;
    height: 442px;
    background-color: #f8f8f8; }
    @media (max-width: 991px) {
      .landing-page .video-js.blog-video {
        height: 280px; } }
    .landing-page .video-js.blog-video .vjs-poster {
      background-size: cover; }
  .landing-page .video-js.side-bar-video {
    width: 100%;
    height: 300px;
    background-color: #f8f8f8; }
    .landing-page .video-js.side-bar-video .vjs-poster {
      background-size: cover; }
  .landing-page .feature-icon-container .detail-text {
    min-height: 100px; }
    @media (max-width: 991px) {
      .landing-page .feature-icon-container .detail-text {
        min-height: 70px; } }
  .landing-page .screenshots .nav-tabs {
    margin-bottom: 0 !important;
    border: initial; }
    .landing-page .screenshots .nav-tabs .nav-link {
      border: initial;
      background: initial !important;
      padding-right: 40px;
      padding-left: 40px; }
    .landing-page .screenshots .nav-tabs .nav-item.show .nav-link:before,
    .landing-page .screenshots .nav-tabs .nav-link.active:before {
      top: initial;
      bottom: 0; }
  .landing-page .app-image {
    width: 100%;
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1);
    border-radius: 1.2rem; }
  .landing-page .doc-search {
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: transparent;
    border-radius: 40px;
    padding: 0.85rem 0.75rem 0.8rem;
    max-width: 360px; }
    .landing-page .doc-search input {
      color: #fff;
      background: transparent;
      width: 93%;
      padding: 0 0.75rem;
      outline: initial !important;
      border: initial; }
      .landing-page .doc-search input::placeholder {
        color: #fff;
        opacity: 0.7; }
    .landing-page .doc-search i {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.7); }
  .landing-page .feedback-container a {
    font-size: 1.3em;
    color: #8f8f8f;
    margin: 0.5rem; }
    .landing-page .feedback-container a:hover, .landing-page .feedback-container a:active {
      color: #00365a; }
  .landing-page .video-play-icon {
    width: 100%;
    height: 100%;
    position: absolute; }
    .landing-page .video-play-icon span {
      position: absolute;
      font-family: VideoJS;
      font-weight: 400;
      font-style: normal;
      background: rgba(255, 255, 255, 0.7);
      height: 1.25em;
      border-radius: 0.75em;
      line-height: 1.25em;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.75em;
      width: 2em;
      text-align: center; }
      .landing-page .video-play-icon span:before {
        color: #00365a;
        content: "\f101"; }
    .landing-page .video-play-icon:hover span, .landing-page .video-play-icon:active span {
      background: rgba(255, 255, 255, 0.85); }
  .landing-page .page-item .page-link {
    line-height: 1.2; }

.components-image {
  position: relative;
  left: 50%;
  transform: translateX(-50%); }
  @media (max-width: 991px) {
    .components-image {
      width: 1600px; } }
  @media (max-width: 767px) {
    .components-image {
      width: 1200px; } }

.color-left {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  width: 33.33%;
  height: 20px; }

.color-center {
  width: 33.33%;
  height: 20px; }

.color-right {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  width: 33.33%;
  height: 20px; }

.color-container {
  height: 20px;
  display: flex;
  justify-content: stretch;
  border-radius: 1rem;
  background-color: initial; }

.bluenavy-light-1 {
  background-color: #00365a; }

.bluenavy-light-2 {
  background-color: #fff; }

.bluenavy-light-3 {
  background-color: #184f90; }

.bluenavy-dark-1 {
  background-color: #236591; }

.bluenavy-dark-2 {
  background-color: #1e2022; }

.bluenavy-dark-3 {
  background-color: #1d477a; }

.blueolympic-light-1 {
  background-color: #008ecc; }

.blueolympic-light-2 {
  background-color: #fff; }

.blueolympic-light-3 {
  background-color: #73c2fb; }

.blueolympic-dark-1 {
  background-color: #3e83a1; }

.blueolympic-dark-2 {
  background-color: #1e2022; }

.blueolympic-dark-3 {
  background-color: #304d72; }

.blueyale-light-1 {
  background-color: #145388; }

.blueyale-light-2 {
  background-color: #fff; }

.blueyale-light-3 {
  background-color: #2a93d5; }

.blueyale-dark-1 {
  background-color: #38688b; }

.blueyale-dark-2 {
  background-color: #1e2022; }

.blueyale-dark-3 {
  background-color: #3e8ab9; }

.greenmoss-light-1 {
  background-color: #576a3d; }

.greenmoss-light-2 {
  background-color: #fff; }

.greenmoss-light-3 {
  background-color: #dd9c02; }

.greenmoss-dark-1 {
  background-color: #627745; }

.greenmoss-dark-2 {
  background-color: #1e2022; }

.greenmoss-dark-3 {
  background-color: #8f7b39; }

.greenlime-light-1 {
  background-color: #6fb327; }

.greenlime-light-2 {
  background-color: #fff; }

.greenlime-light-3 {
  background-color: #51c878; }

.greenlime-dark-1 {
  background-color: #63883b; }

.greenlime-dark-2 {
  background-color: #1e2022; }

.greenlime-dark-3 {
  background-color: #4d7058; }

.carrotorange-light-1 {
  background-color: #ed7117; }

.carrotorange-light-2 {
  background-color: #fff; }

.carrotorange-light-3 {
  background-color: #e7523e; }

.carrotorange-dark-1 {
  background-color: #ad7140; }

.carrotorange-dark-2 {
  background-color: #1e2022; }

.carrotorange-dark-3 {
  background-color: #aa4f43; }

.rubyred-light-1 {
  background-color: #900604; }

.rubyred-light-2 {
  background-color: #fff; }

.rubyred-light-3 {
  background-color: #e7284a; }

.rubyred-dark-1 {
  background-color: #913a47; }

.rubyred-dark-2 {
  background-color: #1e2022; }

.rubyred-dark-3 {
  background-color: #aa5e6c; }

.monsterpurple-light-1 {
  background-color: #922c88; }

.monsterpurple-light-2 {
  background-color: #fff; }

.monsterpurple-light-3 {
  background-color: #4556ac; }

.monsterpurple-dark-1 {
  background-color: #7e4877; }

.monsterpurple-dark-2 {
  background-color: #1e2022; }

.monsterpurple-dark-3 {
  background-color: #3c4b9a; }

.steelgrey-light-1 {
  background-color: #48494b; }

.steelgrey-light-2 {
  background-color: #fff; }

.steelgrey-light-3 {
  background-color: #999da0; }

.steelgrey-dark-1 {
  background-color: #767e8d; }

.steelgrey-dark-2 {
  background-color: #1e2022; }

.steelgrey-dark-3 {
  background-color: #4d5a5f; }

.granolayellow-light-1 {
  background-color: #c0a145; }

.granolayellow-light-2 {
  background-color: #fff; }

.granolayellow-light-3 {
  background-color: #e3b778; }

.granolayellow-dark-1 {
  background-color: #8a722c; }

.granolayellow-dark-2 {
  background-color: #1e2022; }

.granolayellow-dark-3 {
  background-color: #a88048; }

.rtl .landing-page .color-left {
  border-radius: initial;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem; }

.rtl .landing-page .color-right {
  border-radius: initial;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem; }

.rtl .landing-page .footer-circle-button {
  float: right; }

.rtl .landing-page .components-image {
  left: initial;
  right: 50%;
  transform: translateX(50%); }

.rtl .landing-page .feature-image-left {
  float: left; }

@media (max-width: 767px) {
  .rtl .landing-page .feature-image-right {
    float: initial;
    margin-left: initial;
    margin-right: 50%;
    transform: translateX(50%); } }

.rtl .landing-page .mobile-hero {
  margin-left: initial;
  transform: translateX(50%);
  margin-right: 50%; }

.rtl .landing-page .mobile-menu ul {
  padding: initial; }
  .rtl .landing-page .mobile-menu ul .nav-item + .nav-item {
    margin-right: initial;
    margin-left: initial; }

.rtl .landing-page .mobile-menu-cta {
  padding-left: 1rem;
  padding-right: 1rem; }
